import {
    Button,
    Card,
    CardImg,
    CardTitle,
    CardBody,
    CardText,
    Modal, 
    ModalHeader,
    ModalBody, 
    UncontrolledCollapse,
    Table
} from "reactstrap";
import DefaultArableImage from "../../assets/images/default-arable-image.jpg";

type Props = {
    onToggle: () => void,
    isOpen: boolean,
    arable: Arable | null
}

export type Arable = {
    created_at: string,
    created_by: string,
    crop_id: string,
    crop_image: string,
    crop_tag: string,
    crop_type: string,
    crop_type_id: number,
    crop_variety: string,
    cultivation_stages: {
        cultivation_id: number, 
        cultivation: string, 
        created_at: string
    }[],
    ended: string,
    site_id: string,
    site_name: string,
    sold: boolean,
    started: string,
    updated_at: string,
    updated_by: string,
}
const ArableModal = ({ onToggle, isOpen, arable }: Props) => {
    return (
        <Modal isOpen={isOpen} toggle={onToggle} >
            <ModalHeader toggle={onToggle}>Arable</ModalHeader>
            <ModalBody className="avatarCropModal">
                <Card>
                    <CardImg top width="100%" src={arable ? arable.crop_image ? arable.crop_image : DefaultArableImage : DefaultArableImage} alt="Arable Image" />
                    <CardBody>
                        <CardTitle tag="h5">Type: {arable ? arable.crop_type: ""}</CardTitle>
                        <CardText>Variety: {arable ? arable.crop_variety: ""}</CardText>
                        <CardText>Tag: {arable ? arable.crop_tag: ""}</CardText>
                        <CardText>Started: {arable ? arable.started: ""}</CardText>
                        <CardText>Ended: {arable ? arable.ended: ""}</CardText>
                        <CardText>Created by: {arable ? arable.created_by: ""}</CardText>
                        <CardText>Created at: {arable ? arable.created_at: ""}</CardText>
                        <CardText>Updated at: {arable ? arable.updated_by: ""}</CardText>
                        <CardText>Updated at: {arable ? arable.updated_at: ""}</CardText>
                        <div>
                            <Button color="primary" id="dimensionToggler" style={{ marginBottom: '1rem' }}>
                                Cultivation Stages
                            </Button>
                            <UncontrolledCollapse toggler="#dimensionToggler">
                                <Table className="no-wrap v-middle" responsive hover size="sm">
                                    <thead>
                                        <tr className="border-0">
                                            <th className="border-0">
                                                Cultivation
                                            </th>
                                            <th className="border-0">
                                                Created At
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody style={{maxHeight: '100px'}}>
                                        {
                                            (arable && arable.cultivation_stages.map((stage, itemIndex: number) => {
                                                return (<tr key={"vac"+itemIndex}>
                                                    <td className="pt-2">
                                                        {stage.cultivation}
                                                    </td>    
                                                    <td>
                                                        {stage.created_at}
                                                    </td>      
                                                </tr>)
                                            }))                             
                                        }                            
                                    </tbody>
                                </Table>

                            </UncontrolledCollapse>
                        </div>   
                    </CardBody>
                </Card>
            </ModalBody>
        </Modal> 
    )
}

export default ArableModal;