import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk'
import {composeWithDevTools} from "redux-devtools-extension";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Fulllayout from './layouts/fulllayout';
import Login from './views/login/login';
import rootReducer from './modules';
import ReactNotification from 'react-notifications-component'
import './style.scss';
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))

ReactDOM.render(
  <Provider store={store}>
    <ReactNotification />
    <Router>
      <Switch>
          <Route 
            path="/"  
            exact
            render={props => (
              // pass the sub-routes down to keep nesting
              <Login />
            )}
          />          
          <Fulllayout />
      </Switch>
    </Router>
	</Provider>,
  document.getElementById('root')
); 