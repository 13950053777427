import {Dispatch} from "redux";
import { History } from "history";
import { BaseUrl } from "../constant/constant";

// FETCH PROJECTS ACTION TYPES
export const FETCH_PROJECTS_PENDING = "projectManagements/FETCH_PROJECTS_PENDING";
export const FETCH_PROJECTS_FAIL = "projectManagements/FETCH_PROJECTS_FAIL";
export const FETCH_PROJECTS_SUCCESS = "projectManagements/FETCH_PROJECTS_SUCCESS";

// CHANGE PROJECT STATUS ACTION TYPES
export const CHANGE_PROJECT_STATUS_PENDING = "projectManagements/CHANGE_PROJECT_STATUS_PENDING";
export const CHANGE_PROJECT_STATUS_FAIL = "projectManagements/CHANGE_PROJECT_STATUS_FAIL";
export const CHANGE_PROJECT_STATUS_SUCCESS = "projectManagements/CHANGE_PROJECT_STATUS_SUCCESS";

// FETCH SITES ACTION TYPES
export const FETCH_SITES_PENDING = "projectManagements/FETCH_SITES_PENDING";
export const FETCH_SITES_FAIL = "projectManagements/FETCH_SITES_FAIL";
export const FETCH_SITES_SUCCESS = "projectManagements/FETCH_SITES_SUCCESS";

// FETCH SITE TYPES ACTION TYPES
export const FETCH_SITE_TYPES_PENDING = "projectManagements/FETCH_SITE_TYPES_PENDING";
export const FETCH_SITE_TYPES_FAIL = "projectManagements/FETCH_SITE_TYPES_FAIL";
export const FETCH_SITE_TYPES_SUCCESS = "projectManagements/FETCH_SITE_TYPES_SUCCESS";

// CHANGE SITE STATUS ACTION TYPES
export const CHANGE_SITE_STATUS_PENDING = "projectManagements/CHANGE_SITE_STATUS_PENDING";
export const CHANGE_SITE_STATUS_FAIL = "projectManagements/CHANGE_SITE_STATUS_FAIL";
export const CHANGE_SITE_STATUS_SUCCESS = "projectManagements/CHANGE_SITE_STATUS_SUCCESS";

// FETCH NODES ACTION TYPES
export const FETCH_SITE_NODES_PENDING = "projectManagements/FETCH_SITE_NODES_PENDING";
export const FETCH_SITE_NODES_FAIL = "projectManagements/FETCH_SITE_NODES_FAIL";
export const FETCH_SITE_NODES_SUCCESS = "projectManagements/FETCH_SITE_NODES_SUCCESS";

// CHANGE NODE STATUS ACTION TYPES
export const CHANGE_NODE_STATUS_PENDING = "projectManagements/CHANGE_NODE_STATUS_PENDING";
export const CHANGE_NODE_STATUS_FAIL = "projectManagements/CHANGE_NODE_STATUS_FAIL";
export const CHANGE_NODE_STATUS_SUCCESS = "projectManagements/CHANGE_NODE_STATUS_SUCCESS";

// MOVE NODE TO OTHER SITE ACTION TYPES
export const MOVE_NODE_PENDING = "projectManagements/MOVE_NODE_PENDING";
export const MOVE_NODE_FAIL = "projectManagements/MOVE_NODE_FAIL";
export const MOVE_NODE_SUCCESS = "projectManagements/MOVE_NODE_SUCCESS";

// ADD NODE
export const ADD_NODE_PENDING = "projectManagements/ADD_NODE_PENDING";
export const ADD_NODE_FAIL = "projectManagements/ADD_NODE_FAIL";
export const ADD_NODE_SUCCESS = "projectManagements/ADD_NODE_SUCCESS";

// FETCH UNUSED NODES ACTION TYPES
export const FETCH_UNUSED_NODES_PENDING = "projectManagements/FETCH_UNUSED_NODES_PENDING";
export const FETCH_UNUSED_NODES_FAIL = "projectManagements/FETCH_UNUSED_NODES_FAIL";
export const FETCH_UNUSED_NODES_SUCCESS = "projectManagements/FETCH_UNUSED_NODES_SUCCESS";

// REMOVE NODE FROM SITE ACTION TYPES
export const REMOVE_NODE_PENDING = "projectManagements/REMOVE_NODE_PENDING";
export const REMOVE_NODE_FAIL = "projectManagements/REMOVE_NODE_FAIL";
export const REMOVE_NODE_SUCCESS = "projectManagements/REMOVE_NODE_SUCCESS";

// FETCH ANIMAL TYPES ACTION TYPES
export const FETCH_ANIMAL_TYPES_PENDING = "projectManagements/FETCH_ANIMAL_TYPES_PENDING";
export const FETCH_ANIMAL_TYPES_FAIL = "projectManagements/FETCH_ANIMAL_TYPES_FAIL";
export const FETCH_ANIMAL_TYPES_SUCCESS = "projectManagements/FETCH_ANIMAL_TYPES_SUCCESS";

// FETCH ANIMAL LIST
export const FETCH_ANIMAL_LIST_PENDING = "projectManagements/FETCH_ANIMAL_LIST_PENDING";
export const FETCH_ANIMAL_LIST_FAIL = "projectManagements/FETCH_ANIMAL_LIST_FAIL";
export const FETCH_ANIMAL_LIST_SUCCESS = "projectManagements/FETCH_ANIMAL_LIST_SUCCESS";




// INTERFACES FOR THE FETCH PROFILE 
export interface FetchProjectsResultI {
    message: string,
    projects: ProjectI[]
}

export interface ProjectI {
    address: string,
    client_id: string,
    created_at: string,
    created_by: string,
    description: string,
    project_id: string,
    project_name: string,
    updated_at: string,
    updated_by: string | null
    disabled: boolean,
}

// FETCH PROFIEL DISPATCH TYPE
export interface FetchProjectsPending {
    type: typeof FETCH_PROJECTS_PENDING
}

export interface FetchProjectsFail {
    type: typeof FETCH_PROJECTS_FAIL
    payload: FetchProjectsResultI
}

export interface FetchProjectsSuccess {
    type: typeof FETCH_PROJECTS_SUCCESS,
    payload: FetchProjectsResultI
}

// INTERFACES FOR THE CHANGE PROJECT STATUS RESULT
export interface ChangeProjectStatusResultI {
    reason: string,
    project_id: string
}
// INTERFACES FOR THE CHANGE PROJECT STATUS RESULT
export interface ChangeProjectStatusRequestI {
    project_id: string,
    disabled: boolean
}


// CHANGE PROJECT STATUS DISPATCH TYPE
export interface ChangeProjectStatusPending {
    type: typeof CHANGE_PROJECT_STATUS_PENDING,
    payload: ChangeProjectStatusRequestI
}

export interface ChangeProjectStatusFail {
    type: typeof CHANGE_PROJECT_STATUS_FAIL
    payload: ChangeProjectStatusResultI
}

export interface ChangeProjectStatusSuccess {
    type: typeof CHANGE_PROJECT_STATUS_SUCCESS,
    payload: ChangeProjectStatusResultI
}

// INTERFACES FOR THE FETCH SITES
export interface FetchSitesResultI {
    message: string,
    sites: SiteI[]
}

export interface PolygonI {
    lats: string[],
    longs: string[],
}
export interface SiteI {
    address: string,
    disabled: boolean,
    nodes_count: number,
    polygon: PolygonI,
    site_id: string,
    site_name: string,
    updated_at: string,
    updated_by: boolean,
    site_type_id: string,
    project_id: string,
}

// FETCH SITES DISPATCH TYPE
export interface FetchSitesPending {
    type: typeof FETCH_SITES_PENDING
}

export interface FetchSitesFail {
    type: typeof FETCH_SITES_FAIL
    payload: FetchSitesResultI
}

export interface FetchSitesSuccess {
    type: typeof FETCH_SITES_SUCCESS,
    payload: FetchSitesResultI
}

// INTERFACES FOR THE SITE TYPES
export interface FetchSiteTypesResultI {
    message: string,
    site_types: SiteTypeI[]
}

export interface SiteTypeI {
    site_type_id: string,
    site_type_name: string,
}

// FETCH SITE TYPES DISPATCH TYPE
export interface FetchSiteTypesPending {
    type: typeof FETCH_SITE_TYPES_PENDING
}

export interface FetchSiteTypesFail {
    type: typeof FETCH_SITE_TYPES_FAIL
    payload: FetchSiteTypesResultI
}

export interface FetchSiteTypesSuccess {
    type: typeof FETCH_SITE_TYPES_SUCCESS,
    payload: FetchSiteTypesResultI
}

// INTERFACES FOR THE CHANGE SITE STATUS RESULT
export interface ChangeSiteStatusResultI {
    reason: string,
    site_id: string
}
// INTERFACES FOR THE CHANGE SITE STATUS RESULT
export interface ChangeSiteStatusRequestI {
    site_id: string,
    disabled: boolean
}


// CHANGE SITE STATUS DISPATCH TYPE
export interface ChangeSiteStatusPending {
    type: typeof CHANGE_SITE_STATUS_PENDING,
    payload: ChangeSiteStatusRequestI
}

export interface ChangeSiteStatusFail {
    type: typeof CHANGE_SITE_STATUS_FAIL
    payload: ChangeSiteStatusResultI
}

export interface ChangeSiteStatusSuccess {
    type: typeof CHANGE_SITE_STATUS_SUCCESS,
    payload: ChangeSiteStatusResultI
}

// INTERFACES FOR THE FETCH NODES RESULT
export interface FetchSiteNodesResultI {
    message: string,
    nodes: NodeI[]
}

// INTERFACES FOR THE FETCH NODES REQUEST
export interface FetchSiteNodesRequestI {
    site_id: string,
}

export interface NodeI {
    disabled: boolean,
    lat: string | null
    long: string | null
    node_id: string,
    node_type_id: number,
    node_type_name: string,
    project_id: string,
    project_name: string,
    site_id: string,
    site_name: string,
}

// FETCH SITES DISPATCH TYPE
export interface FetchSiteNodesPending {
    type: typeof FETCH_SITE_NODES_PENDING
}

export interface FetchSiteNodesFail {
    type: typeof FETCH_SITE_NODES_FAIL
    payload: FetchSiteNodesResultI
}

export interface FetchSiteNodesSuccess {
    type: typeof FETCH_SITE_NODES_SUCCESS,
    payload: FetchSiteNodesResultI
}

// INTERFACES FOR THE CHANGE NODE STATUS RESULT
export interface ChangeNodeStatusResultI {
    reason: string,
    node_id: string
}
// INTERFACES FOR THE CHANGE NODE STATUS RESULT
export interface ChangeNodeStatusRequestI {
    node_id: string,
    disabled: boolean
}


// CHANGE NODE STATUS DISPATCH TYPE
export interface ChangeNodeStatusPending {
    type: typeof CHANGE_NODE_STATUS_PENDING,
    payload: ChangeNodeStatusRequestI,
}

export interface ChangeNodeStatusFail {
    type: typeof CHANGE_NODE_STATUS_FAIL,
    payload: ChangeNodeStatusResultI
}

export interface ChangeNodeStatusSuccess {
    type: typeof CHANGE_NODE_STATUS_SUCCESS,
    payload: ChangeNodeStatusResultI
}

// INTERFACES FOR THE MOVE NODE RESULT
export interface MoveNodeResultI {
    reason: string,
    node_id: string
}

// INTERFACES FOR THE MOVE NODE REQUEST
export interface MoveNodeRequestI {
    node_id: string,
    site_id: string,
    new_site_id: string,
}

export interface ExtentedMoveNodeRequestI extends MoveNodeRequestI {
    new_site_name: string,
}

// MOVE NODE DISPATCH TYPE
export interface MoveNodePending {
    type: typeof MOVE_NODE_PENDING,
    payload: ExtentedMoveNodeRequestI,
}

export interface MoveNodeFail {
    type: typeof MOVE_NODE_FAIL,
    payload: MoveNodeResultI
}

export interface MoveNodeSuccess {
    type: typeof MOVE_NODE_SUCCESS,
    payload: MoveNodeResultI
}

// INTERFACES FOR THE ADD NODE RESULT
export interface AddNodeResultI {
    node_id: string,
    reason: string,
}

// INTERFACES FOR THE ADD NODE RESULT RESULT
export interface AddNodeRequestI {
    site_id: string,
    node_id: string,
}

// CHANGE SITE STATUS DISPATCH TYPE
export interface AddNodePending {
    type: typeof ADD_NODE_PENDING,
}

export interface AddNodeFail {
    type: typeof ADD_NODE_FAIL,
    payload: AddNodeResultI
}

export interface AddNodeSuccess {
    type: typeof ADD_NODE_SUCCESS,
    payload: AddNodeResultI
}

// INTERFACES FOR THE FETCH UNUSED NODES
export interface FetchUnusedNodesResultI {
    message: string,
    nodes: NodeI[]
}

// FETCH SITES DISPATCH TYPE
export interface FetchUnusedNodesPending {
    type: typeof FETCH_UNUSED_NODES_PENDING
}

export interface FetchUnusedNodesFail {
    type: typeof FETCH_UNUSED_NODES_FAIL
    payload: FetchUnusedNodesResultI
}

export interface FetchUnusedNodesSuccess {
    type: typeof FETCH_UNUSED_NODES_SUCCESS,
    payload: FetchUnusedNodesResultI
}

// INTERFACES FOR THE FETCH UNUSED NODES
export interface RemoveNodeResultI {
    reason: string,
    node_id: string
}

// INTERFACES FOR THE FETCH UNUSED NODES
export interface RemoveNodeRequestI {
    site_id: string,
    node_id: string
}

// FETCH SITES DISPATCH TYPE
export interface RemoveNodePending {
    type: typeof REMOVE_NODE_PENDING
    payload: RemoveNodeRequestI
}

export interface RemoveNodeFail {
    type: typeof REMOVE_NODE_FAIL
    payload: RemoveNodeResultI
}

export interface RemoveNodeSuccess {
    type: typeof REMOVE_NODE_SUCCESS,
    payload: RemoveNodeResultI
}

export interface AnimalTypeI {
    animal_type_id: number,
    animal_type: string,
    animal_breed: string,
}

// INTERFACE FOR THE FETCH ANIMAL TYPES 
export interface FetchAnimalTypesResultI {
    reason: string,
    animal_types: AnimalTypeI[]
}

// FETCH SITE TYPES DISPATCH TYPE
export interface FetchAnimalTypesPending {
    type: typeof FETCH_ANIMAL_TYPES_PENDING
}

export interface FetchAnimalTypesFail {
    type: typeof FETCH_ANIMAL_TYPES_FAIL
    payload: FetchAnimalTypesResultI
}

export interface FetchAnimalTypesSuccess {
    type: typeof FETCH_ANIMAL_TYPES_SUCCESS,
    payload: FetchAnimalTypesResultI
}

export interface AnimalI {
    animal_id: number,
    animal_tag: string,
    animal_type: string,
    animal_type_id: number,
    dob: string,
    image_name: string | null
    site_id: string,
    site_name: string,    
}

// INTERFACE FOR THE FETCH ANIMAL TYPES 
export interface FetchAnimalListResultI {
    reason: string,
    animals: AnimalI[]
}

// FETCH SITE TYPES DISPATCH TYPE
export interface FetchAnimalListPending {
    type: typeof FETCH_ANIMAL_LIST_PENDING
}

export interface FetchAnimalListFail {
    type: typeof FETCH_ANIMAL_LIST_FAIL
    payload: FetchAnimalListResultI
}

export interface FetchAnimalListSuccess {
    type: typeof FETCH_ANIMAL_LIST_SUCCESS,
    payload: FetchAnimalListResultI
}




export type ProjectsManagementDispatchTypes = 
    FetchProjectsPending | 
    FetchProjectsFail | 
    FetchProjectsSuccess |
    ChangeProjectStatusPending |
    ChangeProjectStatusFail |
    ChangeProjectStatusSuccess |
    FetchSitesPending | 
    FetchSitesFail | 
    FetchSitesSuccess |
    FetchSiteTypesPending | 
    FetchSiteTypesFail | 
    FetchSiteTypesSuccess |
    ChangeSiteStatusPending |
    ChangeSiteStatusFail |
    ChangeSiteStatusSuccess |
    FetchSiteNodesPending | 
    FetchSiteNodesFail | 
    FetchSiteNodesSuccess |
    ChangeNodeStatusPending |
    ChangeNodeStatusFail |
    ChangeNodeStatusSuccess |
    MoveNodePending |
    MoveNodeFail |
    MoveNodeSuccess |
    AddNodePending |
    AddNodeFail |
    AddNodeSuccess |
    FetchUnusedNodesPending |
    FetchUnusedNodesSuccess |
    FetchUnusedNodesFail |
    RemoveNodePending |
    RemoveNodeSuccess |
    RemoveNodeFail |
    FetchAnimalTypesPending | 
    FetchAnimalTypesFail | 
    FetchAnimalTypesSuccess |
    FetchAnimalListPending | 
    FetchAnimalListFail | 
    FetchAnimalListSuccess    




export const fetchProjects = (history: History) => async (dispatch: Dispatch<ProjectsManagementDispatchTypes>) => {
    try {
        dispatch({
            type: FETCH_PROJECTS_PENDING
        })
        let token: string | null = localStorage.getItem('token');

        if (token) {
            return fetch(`${BaseUrl}/projects`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token
                },
                method: 'GET',
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.message === "token is invalid!") {
                    history.push('/');
                    localStorage.setItem('token', '');
                }                
                dispatch({
                    type: FETCH_PROJECTS_SUCCESS,
                    payload: json
                })
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_PROJECTS_FAIL,
                    payload: error
                })
            });    
        }

    } catch(e) {
        dispatch({
            type: FETCH_PROJECTS_FAIL,
            payload: e,
        })
    }
};

export const ChangeProjectStatus = (requestDetail: ChangeProjectStatusRequestI, history: History) => async (dispatch: Dispatch<ProjectsManagementDispatchTypes>) => {
    try {
        dispatch({
            type: CHANGE_PROJECT_STATUS_PENDING,
            payload: requestDetail
        })
        let token: string | null = localStorage.getItem('token');
        if (token) {
            return fetch(`${BaseUrl}/project`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token
                },
                method: 'PUT',
                body: JSON.stringify(requestDetail)
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.message === "token is invalid!") {
                    history.push('/');
                    localStorage.setItem('token', '');
                }                
                dispatch({
                    type: CHANGE_PROJECT_STATUS_SUCCESS,
                    payload: json
                })
            })
            .catch((e) => {
                dispatch({
                    type: CHANGE_PROJECT_STATUS_FAIL,
                    payload: {
                        reason: JSON.stringify(e),
                        project_id: "",
                    }
                })    
            });    
        }

    } catch(e) {
        dispatch({
            type: CHANGE_PROJECT_STATUS_FAIL,
            payload: e,
        })
    }
};

export const fetchSites = (history: History) => async (dispatch: Dispatch<ProjectsManagementDispatchTypes>) => {
    try {
        dispatch({
            type: FETCH_SITES_PENDING
        })
        let token: string | null = localStorage.getItem('token');

        if (token) {
            return fetch(`${BaseUrl}/sites`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token
                },
                method: 'GET',
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.message === "token is invalid!") {
                    history.push('/');
                    localStorage.setItem('token', '');
                }                
                dispatch({
                    type: FETCH_SITES_SUCCESS,
                    payload: json
                })
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_SITES_FAIL,
                    payload: error
                })
            });    
        }

    } catch(e) {
        dispatch({
            type: FETCH_SITES_FAIL,
            payload: e,
        })
    }
};

export const fetchSiteTypes = (history: History) => async (dispatch: Dispatch<ProjectsManagementDispatchTypes>) => {
    try {
        dispatch({
            type: FETCH_SITE_TYPES_PENDING
        })
        let token: string | null = localStorage.getItem('token');

        if (token) {
            return fetch(`${BaseUrl}/site_types`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token
                },
                method: 'GET',
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.message === "token is invalid!") {
                    history.push('/');
                    localStorage.setItem('token', '');
                }                
                dispatch({
                    type: FETCH_SITE_TYPES_SUCCESS,
                    payload: json
                })
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_SITE_TYPES_FAIL,
                    payload: error
                })
            });    
        }

    } catch(e) {
        dispatch({
            type: FETCH_SITE_TYPES_FAIL,
            payload: e,
        })
    }
};


export const ChangeSiteStatus = (requestDetail: ChangeSiteStatusRequestI, history: History) => async (dispatch: Dispatch<ProjectsManagementDispatchTypes>) => {
    try {
        dispatch({
            type: CHANGE_SITE_STATUS_PENDING,
            payload: requestDetail
        })
        let token: string | null = localStorage.getItem('token');
        if (token) {
            return fetch(`${BaseUrl}/site`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token
                },
                method: 'PUT',
                body: JSON.stringify(requestDetail)
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.message === "token is invalid!") {
                    history.push('/');
                    localStorage.setItem('token', '');
                }                
                dispatch({
                    type: CHANGE_SITE_STATUS_SUCCESS,
                    payload: json
                })
            })
            .catch((e) => {
                dispatch({
                    type: CHANGE_SITE_STATUS_FAIL,
                    payload: {
                        reason: JSON.stringify(e),
                        site_id: "",
                    }
                })    
            });    
        }

    } catch(e) {
        dispatch({
            type: CHANGE_SITE_STATUS_FAIL,
            payload: e,
        })
    }
};

export const fetchSiteNodes = (requestDetail: FetchSiteNodesRequestI, history: History) => async (dispatch: Dispatch<ProjectsManagementDispatchTypes>) => {
    try {
        dispatch({
            type: FETCH_SITE_NODES_PENDING
        })
        let token: string | null = localStorage.getItem('token');

        if (token) {
            return fetch(`${BaseUrl}/site_nodes/${requestDetail.site_id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token
                },
                method: 'GET',
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.message === "token is invalid!") {
                    history.push('/');
                    localStorage.setItem('token', '');
                }                
                dispatch({
                    type: FETCH_SITE_NODES_SUCCESS,
                    payload: json
                })
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_SITE_NODES_FAIL,
                    payload: error
                })
            });    
        }

    } catch(e) {
        dispatch({
            type: FETCH_SITE_NODES_FAIL,
            payload: e,
        })
    }
};

export const changeNodeStatus = (requestDetail: ChangeNodeStatusRequestI, history: History) => async (dispatch: Dispatch<ProjectsManagementDispatchTypes>) => {
    try {
        dispatch({
            type: CHANGE_NODE_STATUS_PENDING,
            payload: requestDetail,
        })
        let token: string | null = localStorage.getItem('token');
        if (token) {
            return fetch(`${BaseUrl}/node`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token
                },
                method: 'PUT',
                body: JSON.stringify(requestDetail)
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.message === "token is invalid!") {
                    history.push('/');
                    localStorage.setItem('token', '');
                }
                
                dispatch({
                    type: CHANGE_NODE_STATUS_SUCCESS,
                    payload: json
                })
            })
            .catch((error) => {
                dispatch({
                    type: CHANGE_NODE_STATUS_FAIL,
                    payload: error
                })
            });    
        }

    } catch(e) {
        dispatch({
            type: CHANGE_NODE_STATUS_FAIL,
            payload: e,
        })
    }
};

export const moveNode = (requestDetail: MoveNodeRequestI, newSiteName: string, history: History) => async (dispatch: Dispatch<ProjectsManagementDispatchTypes>) => {
    try {
        dispatch({
            type: MOVE_NODE_PENDING,
            payload: {
                ...requestDetail,
                new_site_name: newSiteName,
            },
        })
        let token: string | null = localStorage.getItem('token');
        if (token) {
            return fetch(`${BaseUrl}/site_nodes`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token
                },
                method: 'PUT',
                body: JSON.stringify(requestDetail)
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.message === "token is invalid!") {
                    history.push('/');
                    localStorage.setItem('token', '');
                }                
                dispatch({
                    type: MOVE_NODE_SUCCESS,
                    payload: json
                })
            })
            .catch((error) => {
                dispatch({
                    type: MOVE_NODE_FAIL,
                    payload: {
                        reason: JSON.stringify(error),
                        node_id: "",
                    }
                })    
            });    
        }

    } catch(e) {
        dispatch({
            type: MOVE_NODE_FAIL,
            payload: e,
        })
    }
};

export const addNode = (requestDetail: AddNodeRequestI, history: History) => async (dispatch: Dispatch<ProjectsManagementDispatchTypes>) => {
    try {
        dispatch({
            type: ADD_NODE_PENDING,
        })
        let token: string | null = localStorage.getItem('token');
        if (token) {
            return fetch(`${BaseUrl}/site_nodes`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token
                },
                method: 'POST',
                body: JSON.stringify(requestDetail)
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.message === "token is invalid!") {
                    history.push('/');
                    localStorage.setItem('token', '');
                }                
                dispatch({
                    type: ADD_NODE_SUCCESS,
                    payload: json
                })
            })
            .catch((error) => {
                dispatch({
                    type: ADD_NODE_FAIL,
                    payload: {
                        reason: JSON.stringify(error),
                        node_id: ""
                    }
                })
            });    
        }

    } catch(e) {
        dispatch({
            type: ADD_NODE_FAIL,
            payload: e,
        })
    }
};

export const fetchUnusedNodes = (history: History) => async (dispatch: Dispatch<ProjectsManagementDispatchTypes>) => {
    try {
        dispatch({
            type: FETCH_UNUSED_NODES_PENDING
        })
        let token: string | null = localStorage.getItem('token');
        if (token) {
            return fetch(`${BaseUrl}/nodes`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token
                },
                method: 'GET',
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.message === "token is invalid!") {
                    history.push('/');
                    localStorage.setItem('token', '');
                }                
                dispatch({
                    type: FETCH_UNUSED_NODES_SUCCESS,
                    payload: json
                })
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_UNUSED_NODES_FAIL,
                    payload: error
                })
            });    
        }

    } catch(e) {
        dispatch({
            type: FETCH_PROJECTS_FAIL,
            payload: e,
        })
    }
};

export const removeNode = (requestDetail: RemoveNodeRequestI, history: History) => async (dispatch: Dispatch<ProjectsManagementDispatchTypes>) => {
    try {
        dispatch({
            type: REMOVE_NODE_PENDING,
            payload: requestDetail
        })
        let token: string | null = localStorage.getItem('token');
        if (token) {
            return fetch(`${BaseUrl}/site_nodes`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token
                },
                method: 'DELETE',
                body: JSON.stringify(requestDetail)
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.message === "token is invalid!") {
                    history.push('/');
                    localStorage.setItem('token', '');
                }                
                dispatch({
                    type: REMOVE_NODE_SUCCESS,
                    payload: json
                })
            })
            .catch((error) => {
                dispatch({
                    type: REMOVE_NODE_FAIL,
                    payload: {
                        reason: JSON.stringify(error),
                        node_id: ""
                    }
                })
            });    
        }

    } catch(e) {
        dispatch({
            type: REMOVE_NODE_FAIL,
            payload: e,
        })
    }
};

export const fetchAnimalTypes = (history: History) => async (dispatch: Dispatch<ProjectsManagementDispatchTypes>) => {
    try {
        dispatch({
            type: FETCH_ANIMAL_TYPES_PENDING
        })
        let token: string | null = localStorage.getItem('token');

        if (token) {
            return fetch(`${BaseUrl}/animal_types`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token
                },
                method: 'GET',
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.message === "token is invalid!") {
                    history.push('/');
                    localStorage.setItem('token', '');
                }              
                dispatch({
                    type: FETCH_ANIMAL_TYPES_SUCCESS,
                    payload: json
                })
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_ANIMAL_TYPES_FAIL,
                    payload: error
                })
            });    
        }

    } catch(e) {
        dispatch({
            type: FETCH_ANIMAL_TYPES_FAIL,
            payload: e,
        })
    }
};

export const fetchAnimalList = (history: History) => async (dispatch: Dispatch<ProjectsManagementDispatchTypes>) => {
    try {
        dispatch({
            type: FETCH_ANIMAL_LIST_PENDING
        })
        let token: string | null = localStorage.getItem('token');

        if (token) {
            return fetch(`${BaseUrl}/animal_list`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token
                },
                method: 'GET',
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.message === "token is invalid!") {
                    history.push('/');
                    localStorage.setItem('token', '');
                }              
                dispatch({
                    type: FETCH_ANIMAL_LIST_SUCCESS,
                    payload: json
                })
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_ANIMAL_LIST_FAIL,
                    payload: error
                })
            });    
        }

    } catch(e) {
        dispatch({
            type: FETCH_ANIMAL_LIST_FAIL,
            payload: e,
        })
    }
};




export interface ProjectsManagementStateI {
    pending: boolean,
    failure: boolean,
    success: boolean,
    message: string,
    mode: string,

    projects: ProjectI[] | null, 
    sites: SiteI[] | null,
    siteTypes: SiteTypeI[] | null,
    nodes: NodeI[] | null,
    unused_nodes: NodeI [] | null,
    animalTypes: AnimalTypeI[] | null,
    animalList: AnimalI[] | null,
}


const defaultState: ProjectsManagementStateI = {
    pending: false,
    failure: false,
    success: true,
    message: "",
    mode: "",

    projects: null,
    sites: null,
    siteTypes: null,
    nodes: null,
    unused_nodes: null,
    animalTypes: null,
    animalList: null,
};



  
const projectsManagementReducer = (state: ProjectsManagementStateI = defaultState, action: ProjectsManagementDispatchTypes) : ProjectsManagementStateI => {
    switch (action.type) {
        case FETCH_PROJECTS_PENDING:
            return {
                ...state,
                pending: true,
                failure: false,
                success: false,
                message: "",
                mode: "",
            }
        case FETCH_PROJECTS_FAIL:
            return {
                ...state,
                pending: false,
                failure: true,
                success: false,
                message: action.payload.message,
            }
        case FETCH_PROJECTS_SUCCESS:
            if (action.payload.projects) { 
                return {
                    ...state,
                    pending: false,
                    failure: false,
                    success: true,
                    projects: action.payload.projects,
                    message: "",
                }    
            } else {
                return {
                    ...state,
                    pending: false,
                    failure: true,
                    success: false,
                    message: action.payload.message,
                }                    
            }

        case CHANGE_PROJECT_STATUS_PENDING:
            return {
                ...state,
                pending: true,
                failure: false,
                success: false,
                message: "",
                mode: "CHANGE_PROJECT_STATUS",
                projects: state.projects?state.projects.map((project) => {
                    if (project.project_id === action.payload.project_id) {
                        return {
                            ...project,
                            disabled: action.payload.disabled
                        }
                    }
                    return project
                }): null,
            }
        case CHANGE_PROJECT_STATUS_FAIL:
            return {
                ...state,
                pending: false,
                failure: true,
                message: "",
            }
        case CHANGE_PROJECT_STATUS_SUCCESS:
            if (!action.payload.reason) {
                return {
                    ...state,
                    pending: false,
                    success: true,
                    message: "",
                }        
            } else {
                return {
                    ...state,
                    pending: false,
                    failure: true,
                    message: "",
                }            
            }
        case FETCH_SITES_PENDING:
            return {
                ...state,
                pending: true,
                failure: false,
                success: false,
                message: "",
                mode: "",
            }
        case FETCH_SITES_FAIL:
            return {
                ...state,
                pending: false,
                failure: true,
                success: false,
                message: action.payload.message,
            }
        case FETCH_SITES_SUCCESS:
            if (action.payload.sites) { 
                return {
                    ...state,
                    pending: false,
                    failure: false,
                    success: true,
                    sites: action.payload.sites,
                    message: "",
                }    
            } else {
                return {
                    ...state,
                    pending: false,
                    failure: true,
                    success: false,
                    message: action.payload.message,
                }                    
            }     
        case FETCH_SITE_TYPES_PENDING:
            return {
                ...state,
                pending: true,
                failure: false,
                success: false,
                message: "",
                mode: "",
            }
        case FETCH_SITE_TYPES_FAIL:
            return {
                ...state,
                pending: false,
                failure: true,
                success: false,
                message: action.payload.message,
            }
        case FETCH_SITE_TYPES_SUCCESS:
            if (action.payload.site_types) { 
                return {
                    ...state,
                    pending: false,
                    failure: false,
                    success: true,
                    siteTypes: action.payload.site_types,
                    message: "",
                }    
            } else {
                return {
                    ...state,
                    pending: false,
                    failure: true,
                    success: false,
                    message: action.payload.message,
                }                    
            }     

        case CHANGE_SITE_STATUS_PENDING:
            return {
                ...state,
                pending: true,
                failure: false,
                success: false,
                message: "",
                mode: "CHANGE_SITE_STATUS",
                sites: state.sites? state.sites.map((site) => {
                    if (site.site_id === action.payload.site_id) {
                        return {
                            ...site,
                            disabled: action.payload.disabled
                        }
                    } 
                    return site
                }): null
            }
        case CHANGE_SITE_STATUS_FAIL:
            return {
                ...state,
                pending: false,
                failure: true,
                message: "",
            }
        case CHANGE_SITE_STATUS_SUCCESS:
            if (!action.payload.reason) {
                return {
                    ...state,
                    pending: false,
                    success: true,
                    message: "",
                }        
            } else {
                return {
                    ...state,
                    pending: false,
                    failure: true,
                    success: false,
                    message: "",
                }            
            }
        case FETCH_SITE_NODES_PENDING:
            return {
                ...state,
                pending: true,
                failure: false,
                success: false,
                message: "",
                mode: "",
            }
        case FETCH_SITE_NODES_FAIL:
            return {
                ...state,
                pending: false,
                failure: true,
                success: false,
                message: action.payload.message,
            }
        case FETCH_SITE_NODES_SUCCESS:
            if (action.payload.nodes) { 
                return {
                    ...state,
                    pending: false,
                    failure: false,
                    success: true,
                    nodes: action.payload.nodes,
                    message: "",
                }    
            } else {
                return {
                    ...state,
                    pending: false,
                    failure: true,
                    success: false,
                    message: action.payload.message,
                }                    
            }     
        case CHANGE_NODE_STATUS_PENDING:
            return {
                ...state,
                pending: true,
                failure: false,
                success: false,
                message: "",
                mode: "CHANGE_NODE_STATUS",
                nodes: state.nodes? state.nodes.map((node) => {
                    if (node.node_id === action.payload.node_id) {
                        return {
                            ...node,
                            disabled: action.payload.disabled
                        }
                    }
                    return node
                }): null
            }
        case CHANGE_NODE_STATUS_FAIL:
            return {
                ...state,
                pending: false,
                failure: true,
                message: "",
            }
        case CHANGE_NODE_STATUS_SUCCESS:
            if (!action.payload.reason) {
                return {
                    ...state,
                    pending: false,
                    success: true,
                    message: "",
                }        
            } else {
                return {
                    ...state,
                    pending: false,
                    failure: true,
                    success: false,
                    message: "",
                }            
            }
        case MOVE_NODE_PENDING:
            return {
                ...state,
                pending: true,
                failure: false,
                success: false,
                message: "",
                mode: "moveNode",
                nodes: state.nodes? state.nodes.map((node) => {
                    if (node.node_id === action.payload.node_id) {
                        return {
                            ...node,
                            site_id: action.payload.new_site_id,
                            site_name: action.payload.new_site_name
                        }
                    }
                    return node
                }): null
            }
        case MOVE_NODE_FAIL:
            return {
                ...state,
                pending: false,
                failure: true,
                message: "",
            }
        case MOVE_NODE_SUCCESS:
            if (!action.payload.reason) {
                return {
                    ...state,
                    pending: false,
                    failure: false,
                    success: true,
                    message: "",
                }        
            } else {
                return {
                    ...state,
                    pending: false,
                    failure: true,
                    success: false,
                    message: "",
                }            
            }
        case ADD_NODE_PENDING:
            return {
                ...state,
                pending: true,
                failure: false,
                success: false,
                message: "",
                mode: "ADD_NODE",
            }
        case ADD_NODE_FAIL:
            return {
                ...state,
                pending: false,
                failure: true,
                message: "",
            }
        case ADD_NODE_SUCCESS:
            if (!action.payload.reason) {
                return {
                    ...state,
                    pending: false,
                    failure: false,
                    success: true,
                    message: "",
                }        
            } else {
                return {
                    ...state,
                    pending: false,
                    failure: true,
                    success: false,
                    message: "",
                }            
            }     
        case FETCH_UNUSED_NODES_PENDING:
            return {
                ...state,
                pending: true,
                failure: false,
                success: false,
                message: "",
                mode: "",
            }
        case FETCH_UNUSED_NODES_FAIL:
            return {
                ...state,
                pending: false,
                failure: true,
                success: false,
                message: action.payload.message,
            }
        case FETCH_UNUSED_NODES_SUCCESS:
            if (action.payload.nodes) { 
                return {
                    ...state,
                    pending: false,
                    failure: false,
                    success: true,
                    unused_nodes: action.payload.nodes,
                    message: "",
                }    
            } else {
                return {
                    ...state,
                    pending: false,
                    failure: true,
                    success: false,
                    message: action.payload.message,
                }                    
            }                 
        case REMOVE_NODE_PENDING:
            return {
                ...state,
                nodes: state.nodes? state.nodes.filter((node)=>{
                    return node.node_id !== action.payload.node_id
                }): null,
                pending: true,
                failure: false,
                success: false,
                message: "",
                mode: "REMOVE_NODE",
            }
        case REMOVE_NODE_FAIL:
            return {
                ...state,
                pending: false,
                failure: true,
                success: false,
                message: action.payload.reason,
            }
        case REMOVE_NODE_SUCCESS:
            if (!action.payload.reason) { 
                return {
                    ...state,
                    pending: false,
                    failure: false,
                    success: true,
                }    
            } else {
                return {
                    ...state,
                    pending: false,
                    failure: true,
                    success: false,
                }                    
            }      
        case FETCH_ANIMAL_TYPES_PENDING:
            return {
                ...state,
                pending: true,
                failure: false,
                success: false,
                message: "",
                mode: "",
            }
        case FETCH_ANIMAL_TYPES_FAIL:
            return {
                ...state,
                pending: false,
                failure: true,
                success: false,
                message: action.payload.reason,
            }
        case FETCH_ANIMAL_TYPES_SUCCESS:
            if (action.payload.animal_types) { 
                return {
                    ...state,
                    pending: false,
                    failure: false,
                    success: true,
                    animalTypes: action.payload.animal_types,
                    message: "",
                }    
            } else {
                return {
                    ...state,
                    pending: false,
                    failure: true,
                    success: false,
                    message: action.payload.reason,
                }                    
            }                 
        case FETCH_ANIMAL_LIST_PENDING:
            return {
                ...state,
                pending: true,
                failure: false,
                success: false,
                message: "",
                mode: "",
            }
        case FETCH_ANIMAL_LIST_FAIL:
            return {
                ...state,
                pending: false,
                failure: true,
                success: false,
                message: action.payload.reason,
            }
        case FETCH_ANIMAL_LIST_SUCCESS:
            if (action.payload.animals) { 
                return {
                    ...state,
                    pending: false,
                    failure: false,
                    success: true,
                    animalList: action.payload.animals,
                    message: "",
                }    
            } else {
                return {
                    ...state,
                    pending: false,
                    failure: true,
                    success: false,
                    message: action.payload.reason,
                }                    
            }                             
        default:
            return state
    }
  };
  
  
  export default projectsManagementReducer