import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    FormText,
    Col,
    Row
} from "reactstrap";

import { checkValidation, validationI } from "../../helper/checkValidation";
import { login, LoginStateI } from '../../modules/loginModule'; 
import { RootState } from '../../modules';
import Logo from "../../assets/images/cab.svg";
import Logo_Img from "../../assets/images/login_img.svg";

type EmailErrorProps = {
    isError: boolean | null,
    message: string,
}

// type PasswordErrorProps = {
//     isError: boolean | null,
//     message: string,
// }

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState<EmailErrorProps>({isError: null, message: ""});
    // const [passwordError, setPasswordError] = useState<PasswordErrorProps>({isError: null, message: ""});

    const dispatch = useDispatch();
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const emailValid:validationI = checkValidation(email, 'email');

        if (!emailValid.validation) {
            setEmailError({isError: true, message: emailValid.validationMsg});
            return;
        } else {
            setEmailError({isError: false, message: ""});
        }
        
        dispatch(login({ email, password }));
    }

    const loginState:LoginStateI = useSelector((state: RootState) => state.loginReducer);
    return (
      (loginState.data && loginState.data.token) ?
        <Redirect to="/dashboard" />
      :
        <div className="d-flex" style={{height:'100vh'}}>
          <Col className="h-100 p-0" lg="4">
            <Card className="h-100">
              <CardHeader className="bg-light px-5 pt-5">
                <img src={Logo} width='200px' alt="logo_img"></img>
              </CardHeader>
              <CardBody className="px-5 bg-light">
                <p className="mt-5 mb-4 h4 font-weight-normal">Welcome, please sign in</p>
                <Form role="form" onSubmit={handleSubmit} autoComplete="off">
                  <div className="mb-5">
                    <FormGroup>
                      <InputGroup className="input-group-alternative input-group-lg shadow-lg">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-envelope"></i>
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input 
                            placeholder="Email" 
                            invalid={(emailError.isError === true) || ((loginState.data !== null) && (loginState.data.status_id !== null))} 
                            autoComplete="nope"
                            onChange={e=>setEmail(e.target.value)}/>
                      </InputGroup>
                      <FormText>{emailError.isError? emailError.message: ""}</FormText>
                    </FormGroup>
                    <FormGroup>
                        <InputGroup className="input-group-alternative input-group-lg shadow-lg">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="fas fa-unlock-alt" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                placeholder="Password"
                                type="password"
                                autoComplete="new-password"
                                invalid={(emailError.isError === true) || ((loginState.data !== null) && (loginState.data.status_id !== null))}
                                onChange={e=>setPassword(e.target.value)}
                            />
                        </InputGroup>
                        {/* <FormText>{passwordError.isError? passwordError.message: ""}</FormText> */}
                    </FormGroup>
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                          className="custom-control-input"
                          id=" customCheckLogin"
                          type="checkbox"
                      />
                      <label
                          className="custom-control-label"
                          htmlFor=" customCheckLogin"
                      >
                          <span>Remember me</span>
                      </label>
                    </div>
                  </div>
                  <Button
                    size="lg"
                    className="btn-block shadow-lg"
                    color="primary"
                    type="submit"
                  >
                      Sign In {' '}
                      <i className="fas fa-arrow-right"></i>
                  </Button>
                </Form>
              </CardBody>
              <CardFooter className="px-5 bg-light">
                Copyright © 2021 CAB
              </CardFooter>
            </Card>
          </Col>
          <Col className="d-none d-lg-flex align-items-center">
            <Row className="justify-content-center">
              <Col md={12}>
                <p className="h1 font-weight-light px-md-5 pb-md-5">Digital twin solution for asset monitoring, control, maintenance and management.</p>
              </Col>
              <Col md={10}><img src={Logo_Img} alt="logo-img" className="img-fluid"/></Col>
            </Row>
          </Col>
        </div>
    );
}
export default Login;
