import { useState } from 'react';
import { Route } from 'react-router-dom';
import { Redirect } from "react-router";

import { useMediaQuery } from 'react-responsive'

import Header from '../components/header/header';
import Sidebar from '../components/sidebar/sidebar';
import Footer from '../components/footer/footer';
import ThemeRoutes from '../routes/routing';
import UserProfile from '../views/userProfile/userProfile';
import AccountSettings from '../views/accountSettings/accountSettings';
import ProjectsManagement from '../views/projectsManagement/projectsManagement';
import Login from '../views/login/login';

const Fulllayout = () => {
    /*--------------------------------------------------------------------------------*/
    /*Check if width is less than 1170px            */
    /*--------------------------------------------------------------------------------*/

    const isMiniSideWidth = useMediaQuery({ query: '(max-width: 1170px)' })
    /*--------------------------------------------------------------------------------*/
    /*Change the layout settings [HEADER,SIDEBAR && DARK LAYOUT] from here            */
    /*--------------------------------------------------------------------------------*/
    const [isVisibleSideBar, showSideBar] = useState(false);
    const handleShowSideBar = () => {
        showSideBar(!isVisibleSideBar);
    }

    /*--------------------------------------------------------------------------------*/
    /* Theme Setting && Layout Options wiil be Change From Here                       */
    /*--------------------------------------------------------------------------------*/
    let token: string | null = localStorage.getItem('token');

    return (
        !(token) ?
        <Redirect to="/" />:            
        <div
            id="main-wrapper"
            data-theme="light"
            data-layout="vertical"
            data-sidebartype={`${isMiniSideWidth ? "mini-sidebar": "full"}`}
            data-sidebar-position="fixed"
            data-header-position="fixed"
            data-boxed-layout="full"
            className={`${isVisibleSideBar ? "show-sidebar": ""} ${isMiniSideWidth && "mini-sidebar"}`}
        >
            {/*--------------------------------------------------------------------------------*/}
            {/* Header                                                                         */}
            {/*--------------------------------------------------------------------------------*/}
            <Header onClickShowSideBarBtn={handleShowSideBar}/>
            {/*--------------------------------------------------------------------------------*/}
            {/* Sidebar                                                                        */}
            {/*--------------------------------------------------------------------------------*/}
            {/* <Sidebar {...props} routes={ThemeRoutes} /> */}
            <Sidebar onClickShowSideBarBtn={handleShowSideBar} />
            {/*--------------------------------------------------------------------------------*/}
            {/* Page Main-Content                                                              */}
            {/*--------------------------------------------------------------------------------*/}
            <div className="page-wrapper d-block">
                <div className="page-content container-fluid">
                    {ThemeRoutes.map((prop, key) => {
                        if (!prop.items) {
                            return (
                                <Route path={prop.path} component={prop.component} key={key} exact/>
                            )  
                        } else {
                            return prop.items.map((item, key) => {
                                return (
                                    <Route path={item.path} component={item.component} key={"subItem"+key} exact/>
                                )
                            })
                        }
                    })}

                    <Route path="/user_profile" component={UserProfile} exact/>
                    <Route path="/account_settings" component={AccountSettings} exact/>
                    <Route path="/project_management" component={ProjectsManagement} exact/>
                    <Route path="/logout" component={Login}/>
                </div>
                <Footer />
            </div>
        </div>
    );
}
export default Fulllayout;
