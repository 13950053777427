import {Dispatch} from "redux";

export const EXPAND_LOGO = "sidebarModule/EXPAND_LOGO";

export interface ExpandLogoRequestI {
  isExpandLogo: boolean;
}

export interface ExpandLogoI {
  type: typeof EXPAND_LOGO
  payload: ExpandLogoRequestI
}

export type SideBarDispatchTypes = ExpandLogoI

export const expandLogo = (data: ExpandLogoRequestI) => async (dispatch: Dispatch<SideBarDispatchTypes>) => {
    dispatch({
      type: EXPAND_LOGO,
      payload: data
    })
};
  
export interface SidebarStateI {
    isExpandLogo: boolean
  }
  
  const defaultState: SidebarStateI = {
    isExpandLogo: false,
  };
  
  const sidebarReducer = (state: SidebarStateI = defaultState, action: SideBarDispatchTypes) : SidebarStateI => {
    switch (action.type) {
      case EXPAND_LOGO:
        return {
            isExpandLogo: action.payload.isExpandLogo,
        }
      default:
        return state
    }
  };
  
  
  export default sidebarReducer