import { Row, Col } from 'reactstrap';

// import components
import Projects from '../../components/projectsManagement/projects';
import Sites from '../../components/projectsManagement/sites';
import Animals from '../../components/projectsManagement/animals';

const ProjectsManagement = () => {
    return (
        <Row>
            <Col sm={12}>
                <div className="d-flex align-items-center h1 mb-3">
                    <i className="ti-briefcase mr-2"></i> Projects Management
                </div>
                <Projects />
                <Sites />
                <Animals />
            </Col>
        </Row>
    );
}

export default ProjectsManagement;
