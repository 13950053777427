const constant = {
    testBaseUrl: process.env.REACT_APP_API_URL,
    prodBaseUrl: "https://cabtestapi.cogai.uk",
    nodeMarkerBGColor: "#fff",
    nodeMarkerBDColor: "#ff6e40",
    siteMarkerBGColor: "#fff",
    arableColor: "#00ff00",
    livestockColor: "#0000ff",
}
export let BaseUrl = `${process.env.NODE_ENV === "production" ? constant.prodBaseUrl: constant.testBaseUrl}`
export let NodeMarkerBDColor = constant.nodeMarkerBDColor;
export let NodeMarkerBGColor = constant.nodeMarkerBGColor;
export let SiteMarkerBGColor = constant.siteMarkerBGColor;
export let ArableColor = constant.arableColor;
export let LivestockColor = constant.livestockColor;