import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Table, Form, FormGroup, Input, Row, Col } from 'reactstrap';
import { ProjectsManagementStateI, SiteI, NodeI, changeNodeStatus, moveNode, addNode, fetchUnusedNodes, fetchSites, removeNode, fetchSiteNodes } from '../../modules/projectsManagementModule';
import { RootState } from '../../modules';
import Select from 'react-select';
import { ValueType } from "react-select/src/types";
type Props = {
    isOpen: boolean,
    onToggle: () => void,
    sites: SiteI[] | null,
    site: SiteI | null,
}

type selectOption = {
    label: string,
    value: string,
}

const EditNodeModal = ({ isOpen, onToggle, sites, site }: Props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { nodes, unused_nodes, mode, failure, success }: ProjectsManagementStateI = useSelector((state: RootState) => state.projectsManagementReducer);

    // fetching unused nodes
    useEffect(() => {
        dispatch(fetchUnusedNodes(history));
    }, [site, dispatch, history, isOpen])

    // if changeNodeStatus, moveNode and addNode is failure, refetching sites
    useEffect(() => {
        if ((mode === "CHANGE_NODE_STATUS" || mode === "MOVE_NODE" || mode === "ADD_NODE") && failure) {
            dispatch(fetchSites(history))
            onToggle();
        }
    }, [mode, failure, dispatch, history, onToggle])

    useEffect(() => {
        if (((mode === "REMOVE_NODE") && failure) || ((mode === "ADD_NODE") && success)) {
            dispatch(fetchSiteNodes({site_id: site? site.site_id: ""}, history))
        }
    }, [mode, failure, success, dispatch, history, site])

    const [selectedNodeOption, setNodeOption] = useState<ValueType<selectOption, false> | null>(null);
    const [isOpenAddNodeModal, setOpenAddNodeModal] = useState(false);

    let siteOptions:selectOption[] = [];
    if (sites) {
        siteOptions = sites.map((site) => {
            return {
                value: site.site_id,
                label: site.site_name
            }
        });
    }

    let unusedNodeOptions:selectOption[] = [];
    if (unused_nodes) {
        unusedNodeOptions = unused_nodes.map((node) => {
            return {
                value: node.node_id,
                label: node.node_type_name
            }
        });
    }

    const handleChangeNodeStatus = (e: React.ChangeEvent<HTMLInputElement>, node: NodeI) => {
        dispatch(changeNodeStatus({node_id: node.node_id, disabled: !e.target.checked}, history))
    }

    const handleChangeSelectField = (newSite: ValueType<selectOption, false> | null , node: NodeI) => {
        dispatch(moveNode({
            node_id: node.node_id,
            site_id: node.site_id,
            new_site_id: newSite ? newSite.value : "",
        }, newSite? newSite.label: "", history));
    }

    const handleChangeSelectFieldForAddNode = (newOption: ValueType<selectOption, false> | null , optionType: string) => {
        setNodeOption(newOption);
    }
    
    
    const handleToggleAddNodeModal = () => {
        setOpenAddNodeModal(!isOpenAddNodeModal);
        dispatch(fetchUnusedNodes(history));
    }

    const handleAddNodeToSite = () => {
        handleToggleAddNodeModal();
        if (selectedNodeOption || unusedNodeOptions[0]) {
            dispatch(addNode({
                node_id: (selectedNodeOption || unusedNodeOptions[0]).value,
                site_id: site? site.site_id: "",
            }, history));    
        }
        setNodeOption(null);
    }
    const handleRemoveNode = (node: NodeI, site: SiteI | null) => {
        if (site) {
            dispatch(removeNode({node_id: node.node_id, site_id: site.site_id}, history));
        }
    }

    return (
        <>
            <Modal isOpen={isOpen} toggle={onToggle} size="lg">
                <ModalHeader toggle={onToggle}>Edit Node</ModalHeader>
                <ModalBody>
                    <Table className="no-wrap v-middle" responsive size="sm">
                        <thead>
                            <tr className="border-0">
                                <th className="border-0">Type Name</th>
                                <th className="border-0">Project Name</th>
                                <th className="border-0">Status</th>
                                <th className="border-0">Sites</th>
                                <th className="border-0"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                nodes && nodes.map((node) => {
                                    return (
                                        <tr key={"node"+node.node_id} className="align-items-center">
                                            <td>
                                                {node.node_type_name}
                                            </td>
                                            <td>
                                                {node.project_name}
                                            </td>
                                            <td>
                                                <Form>
                                                    <FormGroup check>
                                                        <Input 
                                                            type="checkbox" 
                                                            checked={!node.disabled} 
                                                            onChange={(e)=>handleChangeNodeStatus(e, node)}/>
                                                    </FormGroup>                                            
                                                </Form>
                                            </td>
                                            <td>
                                                <Select
                                                    value={siteOptions.filter((site) => {return site.value === node.site_id})[0] || null}
                                                    onChange={(e) => handleChangeSelectField(e, node)}
                                                    options={siteOptions}
                                                    menuPortalTarget={document.body}
                                                    menuPosition={'fixed'} 
                                                    styles={{ menuPortal: fix => ({ ...fix, zIndex: 9999 }) }}
                                                />
                                            </td>
                                            <td>
                                                <Button color="white" onClick={() => handleRemoveNode(node, site)} className="btn-sm rounded"><i className="fa fa-trash-alt"></i></Button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary flaot-right" onClick={handleToggleAddNodeModal}>
                        <i className="fa fa-plus"></i> Add Node
                    </Button>                
                    <Button color="secondary" onClick={onToggle}>Close</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={isOpenAddNodeModal} toggle={handleToggleAddNodeModal} size="md">
                <ModalHeader toggle={handleToggleAddNodeModal}>Add Node</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm="6">
                            <Select
                                value={selectedNodeOption || unusedNodeOptions[0]}
                                onChange={(e) => handleChangeSelectFieldForAddNode(e, "node")}
                                options={unusedNodeOptions}
                                menuPortalTarget={document.body}
                                menuPosition={'fixed'} 
                                styles={{ menuPortal: fix => ({ ...fix, zIndex: 9999 }) }}
                            />                        
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleAddNodeToSite}>Save</Button>                    
                    <Button color="secondary" onClick={handleToggleAddNodeModal}>Close</Button>                    
                </ModalFooter>
            </Modal>
        </>
    )
}

export default EditNodeModal;