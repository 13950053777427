import {Dispatch} from "redux";
import { BaseUrl } from "../constant/constant";

export const LOGIN_PENDING = "LOGIN_PENDING";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const INIT_STATE = "INIT_STATE";

export interface LoginResultI {
    success: boolean,
    message: string,
    email: string,
    features: featuresI | null,
    firstname: string,
    image_url: string | null,
    is_admin: boolean,
    surname: string,
    token: string,
    user_id: string    
    status_id: string | null,
}

export interface LoginRequestI {
    email: string,
    password: string,
}

export interface LoginPending {
    type: typeof LOGIN_PENDING
}

export interface LoginFail {
    type: typeof LOGIN_FAIL
}

export interface LoginSuccess {
    type: typeof LOGIN_SUCCESS,
    payload: LoginResultI
}

export interface InitState {
    type: typeof INIT_STATE,
}

export type LoginDispatchTypes = LoginPending | LoginFail | LoginSuccess | InitState

export const login = (requestDetail: LoginRequestI) => async (dispatch: Dispatch<LoginDispatchTypes>) => {
    try {
        dispatch({
            type: LOGIN_PENDING
        })

        return fetch(`${BaseUrl}/auth`, {
            method: 'POST',
            body: JSON.stringify(requestDetail)
        })
        .then((res) => res.json())
        .then((json) => {
            if (json.token) {
                localStorage.setItem('token', json.token);
                localStorage.setItem('features', JSON.stringify(json.features));
            } else {
            //     localStorage.setItem('token', "");
            }
            dispatch({
                type: LOGIN_SUCCESS,
                payload: json
            })
        })
        .catch((error) => {
            dispatch({
                type: LOGIN_FAIL
            })
        });    

    } catch(e) {
        dispatch({
            type: LOGIN_FAIL
        })
    }
};

export const initLoginState = () => async (dispatch: Dispatch<LoginDispatchTypes>) => {
    dispatch({ type: INIT_STATE });
} 

export interface LoginStateI {
    pending: boolean,
    failure: boolean,
    data: LoginResultI | null 
}

export interface featuresI {
    arable: boolean,
    livestock: boolean,
    maps: boolean,
    news: boolean,
}
  
const defaultState: LoginStateI = {
    pending: false,
    failure: false,
    data: null,
};
  
const loginReducer = (state: LoginStateI = defaultState, action: LoginDispatchTypes) : LoginStateI => {
    switch (action.type) {
        case LOGIN_FAIL:
            return {
                pending: false,
                failure: true,
                data: null
            }
        case LOGIN_PENDING:
            return {
                pending: true,
                failure: false,
                data: null
            }
        case LOGIN_SUCCESS:
            return {
                pending: false,
                failure: false,
                data: action.payload
            }
        case INIT_STATE: 
            return {
                pending: false,
                failure: true,
                data: null
            }
        default:
            return state
    }
  };
  
  
  export default loginReducer