import Dashboard from '../views/dashboard/dashboard';
import Arables from '../views/arable/arable';
import Animals from '../views/livestock/livestock';
// import Login from '../views/login/login';
// import Projects from '../views/projects/projects';
// import Clusters from '../views/clusters/clusters';
// import Sites from '../views/site/sites';
// import Reports from '../views/reports/reports';
// import Profiles from '../views/profiles/profiles';

var ThemeRoutes = [
  { 
    path: '/dashboard', 
    name: 'Dashboard', 
    icon: 'ti-world', 
    component: Dashboard 
  },
  {
    path: '/projects',
    name: 'Projects',
    icon: 'ti-package',
    items: [
      { 
        path: '/livestock', 
        name: 'Livestock', 
        icon: 'ti-widget',
        component: Animals 
      },
      {
        path: '/livestock/project/:id',
        name: null,
        icon: null,
        component: Animals,
      },
      {
        path: '/livestock/:id',
        name: null,
        icon: null,
        component: Animals
      },
      {
        path: '/livestock/site/:id',
        name: null,
        icon: null,
        component: Animals
      },      
      { 
        path: '/arable', 
        name: 'Arable', 
        icon: 'ti-layout-sidebar-2', 
        component: Arables
      },
      {
        path: '/arable/project/:id',
        name: null,
        icon: null,
        component: Arables,
      },
      {
        path: '/arable/:id',
        name: null,
        icon: null,
        component: Arables
      },
      {
        path: '/arable/site/:id',
        name: null,
        icon: null,
        component: Arables
      },
    ],
  },
  // {
  //   path: '/logout',
  //   name: 'Logout',
  //   icon: 'ti-power-off',
  //   component: Login
  // },
  // {
  //   path: '/clusters',
  //   name: 'Clusters',
  //   icon: 'mdi mdi-apps',
  //   component: Clusters
  // },
  // {
  //   path: '/sites',
  //   name: 'Sites',
  //   icon: 'mdi mdi-map-marker',
  //   component: Sites
  // },
  // {
  //   path: '/reports',
  //   name: 'Reports',
  //   icon: 'mdi mdi-chart-bar',
  //   component: Reports
  // },
  // {
  //   path: '/profiles',
  //   name: 'Profiles',
  //   icon: 'mdi mdi-account-multiple',
  //   component: Profiles
  // },
  // { path: '/', pathTo: '/dashboard', name: 'Dashboard', redirect: true }
];
export default ThemeRoutes;
