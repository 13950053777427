import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// reactstrap components
import {
    Button, 
    Card, CardHeader, CardBody, 
    Form, FormGroup, Input, InputGroup, InputGroupAddon, Table,
} from "reactstrap";

// core components
import ProjectModal from './projectModal';

import { RootState } from '../../modules';
import { ProjectsManagementStateI, ProjectI, fetchProjects, ChangeProjectStatus,  } from '../../modules/projectsManagementModule';

const Projects = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { projects, mode, failure }: ProjectsManagementStateI = useSelector((state: RootState) => state.projectsManagementReducer);    
    useEffect(() => {
        dispatch(fetchProjects(history));
    }, [dispatch, history])

    useEffect(() => {
        if ((mode === "CHANGE_PROJECT_NODE") && failure) {
            dispatch(fetchProjects(history));
        }
    }, [mode, failure, dispatch, history])

    const [searchStr, setSearch] = useState("");
    const [isOpenProjectModal, setOpenProjectModal] = useState(false);
    const [projectDetailForEdit, setProjectDetailForEdit] = useState<ProjectI | null>(null); 

    const handleChangeSearchField = (value: string) => {
        setSearch(value);
    }

    const handleChangeProjectStatus = (e: React.ChangeEvent<HTMLInputElement>, project: ProjectI) => {
        dispatch(ChangeProjectStatus({project_id: project.project_id, disabled: !e.target.checked}, history));
    }

    const handleToggleCreateProject = () => {
        setProjectDetailForEdit(null); 
        setOpenProjectModal(!isOpenProjectModal);
        return;
    }

    const handleOpenProjectModal = async(project: ProjectI) => {
        if (project) {
            setProjectDetailForEdit(project); 
            setOpenProjectModal(!isOpenProjectModal);
        }
    }    

    const  funcMatchProjects = () => {
        let matchedProjects = projects;
        let matched = false;

        if (projects && searchStr) {
            matchedProjects = projects.filter((project) => {
                matched = false;
                if (project.address.indexOf(searchStr) !== -1) {
                    matched = true;
                } else if (project.description.indexOf(searchStr) !== -1) {
                    matched = true;
                } else if (project.project_name.indexOf(searchStr) !== -1) {
                    matched = true;
                }
                return matched;
            });
        }

        return matchedProjects;
    }

    const neededFields: string[] = ["Name", "Address", "Description", "Status"];
    let matchedProjects = funcMatchProjects();

    return (
        <>
            <Card className="table-y-scrollable">
                <CardHeader className="d-flex justify-content-between align-items-center">
                    Projects
                    <div className="d-flex">
                        <InputGroup className="mr-2">
                            <Input onChange={(e) => handleChangeSearchField(e.target.value)}/>
                            <InputGroupAddon addonType="append"><Button color="white"><i className="ti-search"></i></Button></InputGroupAddon>
                        </InputGroup>
                        <Button color="primary" onClick={handleToggleCreateProject}>
                            <i className="fa fa-plus"></i> Create Project
                        </Button>
                    </div>
                </CardHeader>
                <CardBody>
                    <Table className="no-wrap v-middle" responsive hover size="sm">
                        <thead>
                            <tr className="border-0">
                                {
                                    neededFields.map((field, index) => {
                                        return <th key={"projectField"+index} className="border-0">{field}</th>
                                    })
                                }
                                <th className="border-0">
                                    
                                </th>
                            </tr>
                        </thead>
                        <tbody style={{maxHeight: '100px'}}>
                            {
                                matchedProjects && matchedProjects.map((project) => {
                                    return <tr key={"project"+project.project_id}>
                                        <td className="pt-2">
                                            {project.project_name}
                                        </td>            
                                        <td className="pt-2">
                                            {project.address}
                                        </td>            
                                        <td className="pt-2">
                                            {project.description}
                                        </td>
                                        <td>
                                            <Form>
                                                <FormGroup check>
                                                    <Input 
                                                        type="checkbox" 
                                                        checked={!project.disabled} 
                                                        onChange={(e)=>handleChangeProjectStatus(e, project)}/>                                                        
                                                </FormGroup>                                            
                                            </Form>                                            
                                        </td>     
                                        <td>
                                            <Button color="white" onClick={() => handleOpenProjectModal(project)} className="btn-sm rounded"><i className="fa fa-edit"></i></Button>
                                        </td>            
                                    </tr>
                                })
                            }                            
                        </tbody>
                    </Table>
                </CardBody>
            </Card >
            <ProjectModal isOpen={isOpenProjectModal} onToggle={handleToggleCreateProject} projectDetail={projectDetailForEdit}/>
        </>        
    );
}

export default Projects;
