import React from 'react';


const Footer = () => {
    return (
        <footer className="footer text-center">
            Copyright © 2021
        </footer>
    );
}
export default Footer;
