import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BaseUrl } from "../../constant/constant";
import {
    Nav,
    Navbar,
    NavbarBrand,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu
} from 'reactstrap';
import { RootState } from '../../modules';
import { SidebarStateI } from '../../modules/sidebarModule';
import Logo from "../../assets/images/cab.svg";
import dafaultUserImage from '../../assets/images/default-user-image.jpg';
import { initLoginState } from '../../modules/loginModule';

type Props = {
    onClickShowSideBarBtn: () => void;
}

const Header = ({ onClickShowSideBarBtn }: Props) => {
    const history = useHistory();
    const dispatch = useDispatch();

    // import isExplandLogo to When hover or not, control NavBrand expand or collapse 
    const sidebarState:SidebarStateI = useSelector((state: RootState) => state.sidebarReducer);
    const userProfileStr = localStorage.getItem('user_data');
    const userProfile = userProfileStr? JSON.parse(userProfileStr): null; 
    // When click "My account", fetch profile info
    const handleClickMyAccount = () => {
        history.push('/user_profile');
    } 

    const handleClickAccountSettings = () => {
        history.push('/account_settings');
    }

    const handleClickProjectManagement = () => {
        history.push('/project_management');
    }

    const handleClickLogout = async () => {
        let token: string | null = localStorage.getItem('token');
        if (token) {
            await fetch(`${BaseUrl}/auth`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token
                },
                method: 'DELETE',
            })
            localStorage.setItem('token', '');
            localStorage.setItem('user_data', '');
            dispatch(initLoginState());
            history.push('/logout');
        }
    }
    return (
        <header className="topbar navbarbg">
            <Navbar className="top-navbar" expand="md">
                <Nav navbar className={`w-100 navbar-header ${sidebarState.isExpandLogo && "expand-logo"}`} id="logobg" data-logobg="">
                    {/*--------------------------------------------------------------------------------*/}
                    {/* Mobile View Toggler  [visible only after 768px screen]                         */}
                    {/*--------------------------------------------------------------------------------*/}
                    <button className="btn-link nav-toggler d-block d-md-none" onClick={onClickShowSideBarBtn}>
                      <i className="ti-menu ti-close text-dark" />
                    </button>
                    <NavbarBrand href="/" className="h1 text-dark">
                      <img src={Logo} alt="CAB" width="100px"/>
                    </NavbarBrand>
                    {/*--------------------------------------------------------------------------------*/}
                    {/* Start Profile Dropdown                                                         */}
                    {/*--------------------------------------------------------------------------------*/}
                    <UncontrolledDropdown nav inNavbar className="ml-md-auto">
                        <DropdownToggle nav caret className="pro-pic">
                            <img
                                src={userProfile ? (userProfile[0].image_name? userProfile[0].image_name: dafaultUserImage): dafaultUserImage}
                                alt="user"
                                className="rounded-circle border shadow"
                                width="31"
                            />
                        </DropdownToggle>
                        <DropdownMenu right className="user-dd">
                            <DropdownItem onClick={handleClickMyAccount}>
                                <i className="ti-user mx-1" /> My Account
                            </DropdownItem>
                            {
                                userProfile && userProfile[0].is_admin && 
                                <>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={handleClickAccountSettings}>
                                        <i className="ti-settings mr-1 ml-1" /> Account Settings
                                    </DropdownItem>
                                </>
                            }
                            {
                                userProfile && userProfile[0].is_admin && 
                                <>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={handleClickProjectManagement}>
                                        <i className="ti-briefcase mx-1" /> Project Management
                                    </DropdownItem>
                                </>
                            }
                            <DropdownItem divider />
                            <DropdownItem onClick={handleClickLogout}>
                                <i className="ti-power-off mx-1" /> Logout
                            </DropdownItem>
                            {/*<DropdownItem divider />*/}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    {/*--------------------------------------------------------------------------------*/}
                    {/* End Profile Dropdown                                                           */}
                    {/*--------------------------------------------------------------------------------*/}
                </Nav>
                {/*<Collapse className="navbarbg" navbar data-navbarbg="skin5" >
                    <Nav className="ml-auto float-right" navbar>
                        <NavItem></NavItem>
                    </Nav>
                </Collapse>*/}
            </Navbar>
        </header>
    );
}
export default Header;
