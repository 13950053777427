import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactPlayer from 'react-player'
import LoadingGif from "../../assets/images/loading.gif";

type Props = {
    url: string,
    isOpen: boolean,
    onToggle: () => void
}

const VideoPlayerModal = ({ url, isOpen, onToggle }: Props ) => {

  return (
      <Modal isOpen={isOpen} toggle={onToggle} >
        <ModalHeader toggle={onToggle}></ModalHeader>
        <ModalBody>
            <ReactPlayer width="100%" url={url} controls={true} fallback={<img src={LoadingGif} width="100%" alt="Loading..."></img>}/>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onToggle}>Close</Button>
        </ModalFooter>
      </Modal>
  );
}

export default VideoPlayerModal;