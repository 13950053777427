import {
    Button,
    Card,
    CardImg,
    CardTitle,
    CardBody,
    CardText,
    Modal, 
    ModalHeader,
    UncontrolledCollapse,
    Table
} from "reactstrap";
import DefaultAnimal from "../../assets/images/default-animal-image.jpg";

type Props = {
    onToggle: () => void
    isOpen: boolean
    animal: Animal | null
}

export type Animal = {
    animal_abattoir: boolean,
    animal_breed: string,
    animal_deceased: boolean,
    animal_dob: string,
    animal_id: string,
    animal_lost: boolean,
    animal_sold: boolean,
    animal_tag: string,
    animal_type: string,
    animal_type_id: number,
    dimensions: {
        animal_dimension_id: number,
        created_at: string,
        height: string,
        length: string,
        weight: string,
        width: string,        
    }[]
    image_name: null | string,
    med_vac: {
        datetime: string,
        med_vac_id: string,
        vaccine: string,       
    }[]    
}
const AnimalModal = ({ onToggle, isOpen, animal }: Props) => {
    return (
        <Modal size="lg" isOpen={isOpen} toggle={onToggle} >
            <ModalHeader toggle={onToggle}>Animal</ModalHeader>
            <div className="avatarCropModal">
                <Card>
                    <CardImg className="rounded-0" top width="100%" src={animal ? animal.image_name ? animal.image_name : DefaultAnimal : DefaultAnimal} alt="Animal Image" />
                    <CardBody>
                        <CardTitle tag="h3">Type: {animal ? animal.animal_type: ""}</CardTitle>
                        <CardText tag="h5" className="font-weight-normal">
                          Dob: <b>{animal ? animal.animal_dob: ""}</b></CardText>
                        <CardText tag="h5" className="font-weight-normal">Breed: <b>{animal ? animal.animal_breed: ""}</b></CardText>
                        <CardText tag="h5" className="font-weight-normal">Tag: <b>{animal ? animal.animal_tag: ""}</b></CardText>
                        <div><hr/></div>
                        <div>
                            <Button size="lg" block color="secondary" id="dimensionToggler" className="mb-1">
                                Dimension <i className="fa fa-chevron-down"></i>
                            </Button>
                            <UncontrolledCollapse toggler="#dimensionToggler">
                                <Table className="no-wrap v-middle" responsive hover size="sm">
                                    <thead>
                                        <tr className="border-0">
                                            <th className="border-0">
                                                Created Date
                                            </th>
                                            <th className="border-0">
                                                Weight
                                            </th>
                                            <th className="border-0">
                                                Height
                                            </th>
                                            <th className="border-0">
                                                Length
                                            </th>
                                            <th className="border-0">
                                                Width
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody style={{maxHeight: '100px'}}>
                                        {
                                            (animal && animal.dimensions.map((dimension, itemIndex: number) => {
                                                return (<tr key={"vac"+itemIndex}>
                                                    <td className="pt-2">
                                                        {dimension.created_at}
                                                    </td>  
                                                    <td className="pt-2">
                                                        {dimension.weight}
                                                    </td>  
                                                    <td>
                                                        {dimension.height}
                                                    </td>  
                                                    <td>
                                                        {dimension.length}
                                                    </td>  
                                                    <td className="pt-2">
                                                        {dimension.width}
                                                    </td>    
                                                </tr>)
                                            }))                             
                                        }                            
                                    </tbody>
                                </Table>

                            </UncontrolledCollapse>
                        </div>   
                        <div>
                            <Button size="lg" block color="secondary" id="vaccinetToggler" className="mb-1">
                                Vaccine Details <i className="fa fa-chevron-down"></i>
                            </Button>
                            <UncontrolledCollapse toggler="#vaccinetToggler">
                                <Table className="no-wrap v-middle" responsive hover size="sm">
                                    <thead>
                                        <tr className="border-0">
                                            <th className="border-0">
                                                Date
                                            </th>
                                            <th className="border-0">
                                                Vaccine
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody style={{maxHeight: '100px'}}>
                                        {
                                            (animal && animal.med_vac.map((vac, itemIndex: number) => {
                                                return (<tr key={"vac"+itemIndex}>
                                                    <td>
                                                        {vac.datetime}
                                                    </td>
                                                    <td className="pt-2">
                                                        {vac.vaccine}
                                                    </td>
                                                </tr>)
                                            }))                             
                                        }                            
                                    </tbody>
                                </Table>

                            </UncontrolledCollapse>
                        </div>                        
                    </CardBody>
                </Card>
            </div>
        </Modal> 
    )
}

export default AnimalModal;