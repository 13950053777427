import {
  Card, CardImg, CardText, CardBody, CardLink,
  CardTitle, CardSubtitle
} from 'reactstrap';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"


// import Swiper core and required modules
import SwiperCore, {
  Pagination,Navigation
} from 'swiper/core';

// install Swiper modules
SwiperCore.use([Pagination,Navigation]);

type Props = {
  articles: {
    author: string,
    content: string,
    description: string,
    publishedAt: string,
    source: {
      id: string,
      name: string,
    }
    title: string,
    url: string,
    urlToImage: string      
  }[],
}

export default function Slider({articles}: Props) {

  return (
    <>
        <Swiper slidesPerView={1} spaceBetween={30} loop={true} pagination={{
            "clickable": true
            }} navigation={true} breakpoints={{
              "640": {
                "slidesPerView": 2,
                // "spaceBetween": 20
              },
              "768": {
                "slidesPerView": 2,
                // "spaceBetween": 40
              },
              "992": {
                "slidesPerView": 3,
                // "spaceBetween": 50
              },
              "1200": {
                "slidesPerView": 3,
                // "spaceBetween": 50
              },
              "1400": {
                "slidesPerView": 4
              }
            }} className="mySwiper">
            {
              articles.map((article, articleIndex) => (
                <SwiperSlide key={"article" + articleIndex}>
                  <Card>
                    <CardImg style={{height: "200px"}} top width="100%" src={article.urlToImage} alt="Card image cap" />
                    <CardBody className="d-flex flex-column justify-content-between">
                      <div>
                        <CardTitle tag="h4">{article.title}</CardTitle>
                        <CardSubtitle tag="h6" className="mb-2 text-muted">{article.author}</CardSubtitle>
                        <CardText>{article.content}</CardText>
                      </div>
                      <CardLink className="align-self-end" href={article.url}>Read More</CardLink>
                    </CardBody>
                  </Card>                  
                </SwiperSlide>                
              ))
            }
        </Swiper>
    </>
  )
}