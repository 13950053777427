import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Avatar from 'react-avatar-edit'
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    CardFooter,
    FormGroup,
    Form,
    Input,
    FormText,
    Row,
    Col,
    Modal, 
    ModalHeader,
    ModalBody, 
    ModalFooter   
} from "reactstrap";
import ChangePasswordModal from "../../components/userProfile/changePasswordModal";

import { RootState } from "../../modules";
import { UserProfileStateI, fetchProfile, saveUserProfile } from "../../modules/userProfileModule";
import { checkValidation, validationI } from "../../helper/checkValidation";
import dafaultUserImage from '../../assets/images/default-user-image.jpg';
import { useHistory } from "react-router";

type ErrorProps = {
    isError: boolean | null,
    message: string,
}

const UserProfile = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    // When loading page, fetch data from server
    useEffect(() => {
        dispatch(fetchProfile(history));
    }, [dispatch, history])
    // 

    const { user_data, user_id }: UserProfileStateI = useSelector((state: RootState) => state.userProfileReducer);

    // input field variable 
    const [firstname, setFirstName] = useState("");
    const [surname, setSurName] = useState("");
    const [email, setEmail] = useState("");
    const [telephone, setTelephone] = useState("");
    // image is variable that be sent to db
    const [image, setImage] = useState("");
    // tempImage is used for avatar
    const [tempImage, setTempImage] = useState("");
    // imageSrc is used for avatar modal
    const [imageSrc, setImageSrc] = useState("");
    const [isChangedProfile, setIsChangedProfile] = useState(false);

    // set error 
    const [emailError, setEmailError] = useState<ErrorProps>({isError: null, message: ""});
    const [firstnameError, setFirstnameError] = useState<ErrorProps>({isError: null, message: ""});
    const [surnameError, setSurnameError] = useState<ErrorProps>({isError: null, message: ""});
    const [telephoneError, setTelephoneError] = useState<ErrorProps>({isError: null, message: ""});

    // open avatar crop modal
    const [modal, setAvatarCropModal] = useState(false);
    const handleAvatarCropModalToggle = () => setAvatarCropModal(!modal);

    // open change password modal
    const [isOpenChangePasswordModal, setOpenChangePasswordModal] = useState(false);

    // after load user_data, set state with default value
    useEffect(() => {
        if (user_data) {
            setFirstName(user_data[0].firstname);
            setSurName(user_data[0].surname);
            setEmail(user_data[0].email);
            setTelephone(user_data[0].telephone);
            if (user_data[0].image_name) {
                setImage(user_data[0].image_name);
                setTempImage(user_data[0].image_name);
            }
        }
    }, [user_data])  

    // after save user profile, if result is success user_id is "Done", 
    useEffect(() => {
        if (user_id === "Done") {
            setIsChangedProfile(false);
            dispatch(fetchProfile(history));
            // store.addNotification({
            //     title: "Success!",
            //     message: "Your profile was saved successfully.",
            //     type: "success",
            //     insert: "top",
            //     container: "top-right",
            //     animationIn: ["animate__animated", "animate__fadeIn"],
            //     animationOut: ["animate__animated", "animate__fadeOut"],
            //     dismiss: {
            //         duration: 1000,
            //         onScreen: true
            //     }
            // });        
        }
    }, [dispatch, history, user_id])  

    const handleCrop = (base64: string) => {
        setImageSrc(base64);
    }

    const handleSaveCroppedImage = () => {
        setImage(imageSrc);
        setTempImage(imageSrc);
        if (user_data && imageSrc !== user_data[0].image_name) {
            setIsChangedProfile(true);
        }        
        setAvatarCropModal(!modal)
    }

    const handleCancelSaveCroppedImage = () => {
        if (user_data && user_data[0].image_name) {
            setTempImage(user_data[0].image_name);
            setImageSrc(user_data[0].image_name);
        }
        setAvatarCropModal(!modal)
    }

    const handleChangeFirstName = (value: string) => {
        setFirstName(value);
        setFirstnameError({isError: false, message: ""});
        if (user_data && value !== user_data[0].firstname) {
            setIsChangedProfile(true);
        }
    }

    const handleChangeSurName = (value: string) => {
        setSurName(value);
        setSurnameError({isError: false, message: ""});
        if (user_data && value !== user_data[0].surname) {
            setIsChangedProfile(true);
        }
    }

    const handleChangeEamil = (value: string) => {
        setEmail(value);
        setEmailError({isError: false, message: ""});
        if (user_data && value !== user_data[0].email) {
            setIsChangedProfile(true);
        }
    } 

    const handleChangeTelephone = (value: string) => {
        setTelephone(value);
        setTelephoneError({isError: false, message: ""});
        if (user_data && value !== user_data[0].telephone) {
            setIsChangedProfile(true);
        }
    }

    const handleSaveUserProfile = () => {
        const emailValid:validationI = checkValidation(email, 'email');

        if (!emailValid.validation) {
            setEmailError({isError: true, message: emailValid.validationMsg});
            return;
        } else {
            setEmailError({isError: false, message: ""});
        }


        if (firstname === "") {
            setFirstnameError({isError: true, message: "Please enter this field."});
            return;
        }

        if (surname === "") {
            setSurnameError({isError: true, message: "Please enter this field."});
            return;
        }

        if (telephone === "") {
            setTelephoneError({isError: true, message: "Please enter this field."});
            return;
        }

        if (email === "") {
            setSurnameError({isError: true, message: "Please enter this field."});
            return;
        }
    
        dispatch(
            saveUserProfile({
                firstname,
                surname,
                email,
                telephone,
                image_name: image,
                is_admin: user_data ? user_data[0].is_admin : false
            }, history)
        );
    }

    const handleChangeUserPassword = async() => {
        setOpenChangePasswordModal(!isOpenChangePasswordModal);
    }

    return (
        <>
            <Row>
                <Col sm={12} className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center h1 mb-3">
                        <i className="ti-user mr-2"></i> My Account
                    </div>
                    <Button color="primary" style={ !isChangedProfile ? { opacity: '0' } : {} } onClick={handleSaveUserProfile}><i className="fa fa-user"></i> Save</Button>
                </Col>
                <Col sm={12}>
                    <Card>
                        <CardHeader>
                            {(tempImage !== null)? 
                                <div className="d-inline-block relative myaccount-avatar-container">
                                    <div className="myaccount-avatar-box">
                                        <img src={tempImage} alt="Preview" className="w-100 h-100"/> 
                                        <Button color="white" onClick={handleAvatarCropModalToggle} className="avatar-edit-btn rounded btn-sm"><i className="fa fa-edit"></i></Button>
                                    </div>
                                </div>
                            :
                                <div className="d-inline-block relative myaccount-avatar-container" >
                                    <div className="myaccount-avatar-box">
                                        <img src={dafaultUserImage} alt="Preview" className="w-100 h-100"/> 
                                        <Button color="white" onClick={handleAvatarCropModalToggle} className="avatar-edit-btn rounded btn-sm"><i className="fa fa-edit"></i></Button>
                                    </div>
                                </div>                            
                            }
                        </CardHeader>
                        <CardBody>
                            <CardTitle>User Details</CardTitle>
                            <Form autoComplete="off">
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-first-name"
                                            >
                                                First name
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                id="input-first-name"
                                                placeholder="First name"
                                                type="text"
                                                value={firstname}
                                                onChange={(e)=>{handleChangeFirstName(e.target.value)}}
                                                invalid={(firstnameError.isError === true)}
                                            />
                                        </FormGroup>
                                        <FormText>{firstnameError.isError? firstnameError.message: ""}</FormText>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-last-name"
                                            >
                                                Last name
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                id="input-last-name"
                                                placeholder="Last name"
                                                type="text"
                                                value={surname}
                                                onChange={(e)=>{handleChangeSurName(e.target.value)}}
                                                invalid={(surnameError.isError === true)}
                                            />
                                        </FormGroup>
                                        <FormText>{surnameError.isError? surnameError.message: ""}</FormText>                      
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-email"
                                            >
                                                Email address
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                id="input-email"
                                                placeholder="jesse@example.com"
                                                type="email"
                                                autoComplete="nope"
                                                value={email}
                                                onChange={(e)=>{handleChangeEamil(e.target.value)}}
                                                invalid={(emailError.isError === true)}
                                            />
                                        </FormGroup>
                                        <FormText>{surnameError.isError? surnameError.message: ""}</FormText>                                       
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-phonenumber"
                                            >
                                                Phone Number
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                id="input-phonenumber"
                                                placeholder="Phone Number"
                                                type="text"
                                                value={telephone}
                                                onChange={(e)=>{handleChangeTelephone(e.target.value)}}
                                                invalid={(telephoneError.isError === true)}
                                            />
                                        </FormGroup>
                                        <FormText>{telephoneError.isError? telephoneError.message: ""}</FormText>                                       
                                    </Col>                
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm={12} className="mb-sm-5 " primary>
                    <Button color="primary" onClick={() => setOpenChangePasswordModal(true)}>Change Password</Button>
                </Col>
                {user_data && 
                    user_data[0].projects.map((project) => {
                        return (
                            <Col sm="3" key={project.project_id}>
                                <Card>
                                    <CardBody>
                                        <div><i className="mdi mdi-arrange-send-backward"></i></div>
                                        <p className="h4 mt-3">{project.project_name}</p>
                                        <p>{project.description}</p>
                                    </CardBody>
                                    <CardFooter className="small"><i className="mdi mdi-map-marker"></i> {project.address}</CardFooter>
                                </Card>
                            </Col>
                        )
                    })
                }
            </Row>   

            <Modal isOpen={modal} toggle={handleAvatarCropModalToggle} >
                <ModalHeader toggle={handleAvatarCropModalToggle}>Edit Profile Image</ModalHeader>
                    <ModalBody className="avatarCropModal">
                        <Avatar
                            width={390}
                            height={295}
                            onCrop={handleCrop}
                            src={imageSrc}
                            />
                    </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSaveCroppedImage}>Ok</Button>{' '}
                    <Button color="secondary" onClick={handleCancelSaveCroppedImage}>Cancel</Button>
                </ModalFooter>
            </Modal> 
            <ChangePasswordModal isOpen={isOpenChangePasswordModal} onToggle={handleChangeUserPassword} />
        </>
    )
};

export default UserProfile;

