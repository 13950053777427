import { NavLink } from 'react-router-dom';
import { Nav } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar'
import ThemeRoutes from '../../routes/routing';
import { useDispatch } from 'react-redux';
import { expandLogo } from '../../modules/sidebarModule';
import SubMenu from "./subMenu";

type Props = {
  onClickShowSideBarBtn: () => void;
}

const Sidebar = ({ onClickShowSideBarBtn }: Props) => {
    const dispatch = useDispatch();
    /*--------------------------------------------------------------------------------*/
    /*To Expand SITE_LOGO With Sidebar-Menu on Hover                                  */
    /*--------------------------------------------------------------------------------*/
   
    const handleExpandLogo = (isExpandLogo: boolean) => {
        dispatch(expandLogo({isExpandLogo}))
    }

    return (
        <aside className="left-sidebar" id="sidebarbg" data-sidebarbg="skin6" onMouseEnter={()=>{handleExpandLogo(true)}} onMouseLeave={()=>{handleExpandLogo(false)}}>
            <div className="scroll-sidebar">
                <PerfectScrollbar className="sidebar-nav">
                    {/*--------------------------------------------------------------------------------*/}
                    {/* Sidebar Menus will go here                                                */}
                    {/*--------------------------------------------------------------------------------*/}
                    <Nav id="sidebarnav" vertical>
                        {ThemeRoutes.map((prop, key) => {
                            if (!prop.items) {
                                return (
                                    /*--------------------------------------------------------------------------------*/
                                    /* Adding Sidebar Item                                                            */
                                    /*--------------------------------------------------------------------------------*/
                                    <li className={'sidebar-item'} key={key}>
                                        <NavLink to={prop.path} className="sidebar-link" activeClassName="active" onClick={onClickShowSideBarBtn}>
                                            <i className={prop.icon} />
                                            <span className="hide-menu">{prop.name}</span>
                                        </NavLink>
                                    </li>
                                );    
                            } else {
                                return (
                                    <SubMenu name={prop.name} key={key} icon={"ti-package"} items={prop.items} path={prop.path} onClickShowSideBarBtn={onClickShowSideBarBtn} />
                                )
                            }
                        })}
                    </Nav>
                </PerfectScrollbar>
            </div>
        </aside>
    );
}
export default Sidebar;
