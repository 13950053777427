import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';

type Props = {
    isOpen: boolean,
    onToggle: () => void
}

const AddNodeModal = ({ isOpen, onToggle }: Props) => {


  return (
      <Modal isOpen={isOpen} toggle={onToggle} >
        <ModalHeader toggle={onToggle}>Wrong Coordinate Value</ModalHeader>
        <ModalBody>
            <Row>
                <Col sm="12">
                    Please Input Correct Coordinate Value!
                </Col>
            </Row>
            <Row>
                <Col>
                    For example: 52.23232332323
                </Col>
            </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onToggle}>Ok</Button>
        </ModalFooter>
      </Modal>
  );
}

export default AddNodeModal;