import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Avatar from 'react-avatar-edit'
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    FormGroup,
    Form,
    Input,
    FormText,
    Row,
    Col,
    Modal, 
    ModalHeader,
    ModalBody, 
    ModalFooter   
} from "reactstrap";
import Select from 'react-select';
import { ValueType } from "react-select/src/types";

import { EditUserRequestI, SettingsStateI, UserRoleI } from '../../modules/accountSettingsModule';
import dafaultUserImage from '../../assets/images/default-user-image.jpg';
import { RootState } from '../../modules';

type ErrorProps = {
    isError: boolean | null,
    message: string,
}

type Props = {
    onSaveProfile: (value: EditUserRequestI) => void,
    onToggle: () => void,
    isOpen: boolean,
}

const EditUserProfileModal = ({onSaveProfile, onToggle, isOpen }: Props) => {
    // input field variable 
    const [firstname, setFirstName] = useState("");
    const [surname, setSurName] = useState("");
    const [telephone, setTelephone] = useState("");
    // image is variable that be sent to db
    const [image, setImage] = useState("");
    // tempImage is used for avatar
    const [tempImage, setTempImage] = useState("");
    // imageSrc is used for avatar modal
    const [imageSrc, setImageSrc] = useState("");
    const [selectedUserRoleOption, setUserRoleOption] = useState<ValueType<UserRoleI, false> | null>(null);
    const [isChangedProfile, setIsChangedProfile] = useState(false);

    // set error 
    const [firstnameError, setFirstnameError] = useState<ErrorProps>({isError: null, message: ""});
    const [surnameError, setSurnameError] = useState<ErrorProps>({isError: null, message: ""});
    const [telephoneError, setTelephoneError] = useState<ErrorProps>({isError: null, message: ""});
    const [selectedUserRoleOptionError, setSelectUserRoleOptionError] = useState<ErrorProps>({isError: null, message: ""});

    // open avatar crop modal
    const [modal, setAvatarCropModal] = useState(false);
    const handleAvatarCropModalToggle = () => setAvatarCropModal(!modal);
    const { setting_user, user_roles }: SettingsStateI = useSelector((state: RootState) => state.accountSettingsReducer);

    useEffect(() => {
        if (setting_user) {
            setFirstName(setting_user.forename);
            setSurName(setting_user.surname);
            // setEmail(defaultUserDetails.email);
            setTelephone(setting_user.telephone);
            if (setting_user.image_name) {
                setImage(setting_user.image_name);
                setTempImage(setting_user.image_name);
            }   
            // set user role
            let tempSelectedUserRole = null;
            if (setting_user.role_id) {
                user_roles?.find((role) =>{
                    if (role.role_id === setting_user.role_id) {
                        tempSelectedUserRole = role;
                        return true;
                    } 
                    return false;
                })
                setUserRoleOption(tempSelectedUserRole);
            }
        }
    }, [setting_user, user_roles])  

    const handleCrop = (base64: string) => {
        setImageSrc(base64);
        setIsChangedProfile(true);
    }

    const handleSaveCroppedImage = () => {
        setImage(imageSrc);
        setTempImage(imageSrc);
        if (setting_user && imageSrc !== setting_user.image_name) {
            setIsChangedProfile(true);
        }        
        setAvatarCropModal(!modal)
    }

    const handleCancelSaveCroppedImage = () => {
        setTempImage("");
        setImageSrc("");
        setAvatarCropModal(!modal)
    }

    const handleChangeFirstName = (value: string) => {
        setFirstName(value);
        setFirstnameError({isError: false, message: ""});

        if (value !== "") {
            setIsChangedProfile(true);
        }

    }

    const handleChangeSurName = (value: string) => {
        setSurName(value);
        setSurnameError({isError: false, message: ""});
       
        if (value !== "") {
            setIsChangedProfile(true);
        }
    }

    const handleChangeTelephone = (value: string) => {
        setTelephone(value);
        setTelephoneError({isError: false, message: ""});
        if (value !== "") {
            setIsChangedProfile(true);
        }
    }
    
    const handleChangeUserRoleOption = (newOption: ValueType<UserRoleI, false> | null) => {
        setUserRoleOption(newOption);
        if (selectedUserRoleOptionError.isError) {
            setSelectUserRoleOptionError({isError: false, message: ""});
        }

        if (setting_user?.role_id !== newOption?.role_id) {
            setIsChangedProfile(true);            
        }
    }

    const handleSaveUserProfile = () => {
        if (firstname === "") {
            setFirstnameError({isError: true, message: "Please enter this field."});
            return;
        }

        if (surname === "") {
            setSurnameError({isError: true, message: "Please enter this field."});
            return;
        }

        if (telephone === "") {
            setTelephoneError({isError: true, message: "Please enter this field."});
            return;
        }

        if (selectedUserRoleOption === null) {
            setSelectUserRoleOptionError({ isError: true, message: "Please enter this field." });
        }

        if (setting_user && selectedUserRoleOption) {
            onSaveProfile({
                firstname,
                surname,
                telephone,
                image_name: image,
                user_id: setting_user.user_id,
                role_id: selectedUserRoleOption?.role_id
            })    
        }
        handleToggleModal();
    }

    const handleToggleModal = () => {
        onToggle();
        setFirstName('');
        setSurName('');
        setTelephone('');
        setImage('');
        setImageSrc('');
        setTempImage('');
        setUserRoleOption(null);
        setFirstnameError({ isError: false, message: "" });
        setSurnameError({ isError: false, message: "" });
        setTelephoneError({ isError: false, message: "" });
        setSelectUserRoleOptionError({ isError: false, message: "" });
    }

    const getStyleForReactSelect = (value: ErrorProps) => {
        const style = {
            control: (base: any, state: any) => ({
              ...base,
                // state.isFocused can display different borderColor if you need it
                borderColor: state.isFocused ?
                    '#ddd' : value.isError ?
                    'red' : '#ddd',
            })
        }        
        return style
    }
    return (
        <>
            <Modal isOpen={isOpen} toggle={handleToggleModal} >
                <ModalHeader toggle={handleToggleModal}>Edit User</ModalHeader>
                <ModalBody>
                    <Row>
                    <Col sm={12}>
                        <Card>
                            <CardHeader>
                                {((tempImage !== "") && (tempImage !== null)) ? 
                                    <div className="d-inline-block relative myaccount-avatar-container">
                                        <div className="myaccount-avatar-box">
                                            <img src={tempImage} alt="Preview" className="w-100 h-100"/> 
                                            <Button color="danger" onClick={handleAvatarCropModalToggle} className="avatar-edit-btn btn-sm"><i className="fa fa-edit"></i></Button>
                                        </div>
                                    </div>
                                :
                                    <div className="d-inline-block relative myaccount-avatar-container" >
                                        <div className="myaccount-avatar-box">
                                            <img src={dafaultUserImage} alt="Preview" className="w-100 h-100"/> 
                                            <Button color="danger" onClick={handleAvatarCropModalToggle} className="avatar-edit-btn btn-sm"><i className="fa fa-edit"></i></Button>
                                        </div>
                                    </div>                            
                                }
                            </CardHeader>
                            <CardBody>
                                <CardTitle>User Profile</CardTitle>      
                                <Form autoComplete="off">
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-first-name"
                                                    >
                                                        First name
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-first-name"
                                                        placeholder="First name"
                                                        type="text"
                                                        value={firstname}
                                                        onChange={(e)=>{handleChangeFirstName(e.target.value)}}
                                                        invalid={(firstnameError.isError === true)}
                                                    />
                                                </FormGroup>
                                                <FormText>{firstnameError.isError? firstnameError.message: ""}</FormText>
                                            </Col>
                                            <Col sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-last-name"
                                                    >
                                                        Last name
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-last-name"
                                                        placeholder="Last name"
                                                        type="text"
                                                        value={surname}
                                                        onChange={(e)=>{handleChangeSurName(e.target.value)}}
                                                        invalid={(surnameError.isError === true)}
                                                    />
                                                </FormGroup>
                                                <FormText>{surnameError.isError? surnameError.message: ""}</FormText>                      
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-phonenumber"
                                                    >
                                                        Phone Number
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-phonenumber"
                                                        placeholder="Phone Number"
                                                        type="text"
                                                        value={telephone}
                                                        onChange={(e)=>{handleChangeTelephone(e.target.value)}}
                                                        invalid={(telephoneError.isError === true)}
                                                    />
                                                </FormGroup>
                                                <FormText>{telephoneError.isError? telephoneError.message: ""}</FormText>                                       
                                            </Col> 
                                            <Col sm="6">
                                                <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="selectProjectOption"
                                                        >
                                                            User Role
                                                        </label>

                                                        <Select
                                                            id="selectProjectOption"
                                                            value={selectedUserRoleOption}
                                                            options={user_roles? user_roles: []}
                                                            getOptionLabel ={(option)=>option.name}
                                                            getOptionValue ={(option)=>(option.role_id).toString()}
                                                            onChange={(e) => handleChangeUserRoleOption(e)}
                                                            placeholder="Select a user role"
                                                            styles={getStyleForReactSelect(selectedUserRoleOptionError)}
                                                        />     
                                                </FormGroup>                                                                  
                                            </Col>
                                        </Row>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>   
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" disabled={!isChangedProfile} onClick={handleSaveUserProfile}>Ok</Button>{' '}
                    <Button color="secondary" onClick={handleToggleModal}>Cancel</Button>
                </ModalFooter>
            </Modal> 
            <Modal isOpen={modal} toggle={handleAvatarCropModalToggle} >
                <ModalHeader toggle={handleAvatarCropModalToggle}>Edit Profile Image</ModalHeader>
                    <ModalBody className="avatarCropModal">
                        <Avatar
                            width={390}
                            height={295}
                            onCrop={handleCrop}
                            src={imageSrc}
                            />
                    </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSaveCroppedImage} invalid>Ok</Button>{' '}
                    <Button color="secondary" onClick={handleCancelSaveCroppedImage}>Cancel</Button>
                </ModalFooter>
            </Modal> 
        </>
    )
};

export default EditUserProfileModal;

