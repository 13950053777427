import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'reactstrap';

// import actions, types, reducers
import { fetchSettingsAndUnits } from '../../modules/accountSettingsModule';

// import components
import SettingUnits from '../../components/accountSettings/settingUnits';
import SettingUsers from '../../components/accountSettings/settingUsers';
import SettingClientsProjects from '../../components/accountSettings/settingClientsProjects';
import SettingClientProjectSites from '../../components/accountSettings/settingClientProjectSites';
import { useHistory } from 'react-router';

const AccountSettings = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    // const { pending, failure, message }: SettingsStateI = useSelector((state: RootState) => state.accountSettingsReducer);
    useEffect(() => {
        dispatch(fetchSettingsAndUnits(history));
    }, [dispatch, history])

    return (
        <Row>
            <Col sm={12}>
                <div className="d-flex align-items-center h1 mb-3">
                    <i className="ti-settings mr-2"></i> Account Settings
                </div>
                <SettingUnits />
                <SettingUsers />
                <SettingClientsProjects />
                <SettingClientProjectSites />
            </Col>
        </Row>
    );
}

export default AccountSettings;
