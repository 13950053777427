import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// reactstrap components
import {
    Button, 
    Card, CardHeader, CardBody, 
    Input, InputGroup, InputGroupAddon, Table
} from "reactstrap";

// core components
import EditAnimalModal from './EditAnimalModal';

import { RootState } from '../../modules';
import { ProjectsManagementStateI, fetchAnimalTypes, fetchAnimalList, AnimalI } from '../../modules/projectsManagementModule';
import { ExtendedAnimalProps } from "./EditAnimalModal";
import { BaseUrl } from "../../constant/constant";

const Animals = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { animalList }: ProjectsManagementStateI = useSelector((state: RootState) => state.projectsManagementReducer);
    useEffect(() => {
        dispatch(fetchAnimalTypes(history));
        dispatch(fetchAnimalList(history));
    }, [dispatch, history])


    const [searchStr, setSearch] = useState("");
    const [isOpenAnimalModal, setOpenAnimalModal] = useState(false);
    const [animalDetailForEdit, setAnimalDetailForEdit] = useState<ExtendedAnimalProps | null>(null); 
    
    const handleChangeSearchField = (value: string) => {
        setSearch(value);
    }

    const handleToggleCreateAnimal = () => {
        setAnimalDetailForEdit(null); 
        setOpenAnimalModal(!isOpenAnimalModal);
        return;
    }

    const handleOpenAnimalModal = async(animal: AnimalI) => {
            if (animal) {   
                let token: string | null = localStorage.getItem('token');
                if (token) {
                    let res = await fetch(`${BaseUrl}/animal/${animal.animal_id}`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'x-access-token': token
                        },
                        method: 'GET',
                    })
                    let json = await res.json();
                    if (json.message === "token is invalid!") {
                        history.push("/");
                        localStorage.setItem('token', '');
                    } 
                    if (!json.message && !json.reason) {
                        setAnimalDetailForEdit(json.animal[0]); 
                        setOpenAnimalModal(!isOpenAnimalModal);;                
                    } 
                    if (json.reason) {
                        dispatch(fetchAnimalList(history));
                    }
                }
            }
    }

    const  funcMatchAnimals = () => {
        let matchedAnimals = animalList;
        let matched = false;
        if (animalList && searchStr) {
            matchedAnimals = animalList.filter((animal) => {
                matched = false;
                if (animal.animal_tag.indexOf(searchStr) !== -1) {
                    matched = true;
                } else if (animal.animal_type.indexOf(searchStr) !== -1) {
                    matched = true;
                }
                return matched;
            });
        }

        return matchedAnimals;
    }
    let matchedAnimals = funcMatchAnimals();
    const neededFields: string[] = ["Type", "Tag", "Dob", "Site Name", ""];

    return (
        <>
            <Card className="table-y-scrollable">
                <CardHeader className="d-flex justify-content-between align-items-center">
                    Livestock
                    <div className="d-flex">
                        <InputGroup className="mr-2">
                            <Input onChange={(e) => handleChangeSearchField(e.target.value)}/>
                            <InputGroupAddon addonType="append"><Button color="white"><i className="ti-search"></i></Button></InputGroupAddon>
                        </InputGroup>
                        <Button color="primary" onClick={handleToggleCreateAnimal}>
                            <i className="fa fa-plus"></i> Add Livestock
                        </Button>
                    </div>
                </CardHeader>
                <CardBody>
                    <Table className="no-wrap v-middle" responsive hover size="sm">
                        <thead>
                            <tr className="border-0">
                                {
                                    neededFields.map((field, index) => {
                                        return <th key={"animalField"+index} className="border-0">{field}</th>
                                    })
                                }
                                <th className="border-0">
                                    
                                </th>
                            </tr>
                        </thead>
                        <tbody style={{maxHeight: '100px'}}>
                            {
                                matchedAnimals && matchedAnimals.map((animal) => {
                                    return <tr key={"animal"+animal.animal_id}>
                                        <td className="pt-2">
                                            {animal.animal_type}
                                        </td>            
                                        <td className="pt-2">
                                            {animal.animal_tag}
                                        </td>            
                                        <td className="pt-2">
                                            {animal.dob}
                                        </td>            
                                        <td className="pt-2">
                                            {animal.site_name}
                                        </td>            
                                        <td className="pt-2">
                                            <img
                                                src={animal.image_name !== null ? animal.image_name: ""}
                                                alt="animal"
                                                className="rounded-circle"
                                                width="31"
                                                title={animal.animal_type}
                                            />                                            
                                        </td>            
                                        <td>
                                            <Button color="white" onClick={() => handleOpenAnimalModal(animal)} className="btn-sm rounded"><i className="fa fa-edit"></i></Button>
                                        </td>           
                                    </tr>
                                })
                            }                            
                        </tbody>
                    </Table>
                </CardBody>
            </Card >
            <EditAnimalModal isOpen={isOpenAnimalModal} onToggle={handleToggleCreateAnimal} animalDetails={animalDetailForEdit} />
        </>        
    );
}

export default Animals;
