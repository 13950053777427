import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// reactstrap components
import {
    Button, 
    Card, CardHeader, CardBody, 
    Form, FormGroup, Input, InputGroup, InputGroupAddon, Table
} from "reactstrap";

// core components
import EditNodeModal from './editNodeModal';
import SiteModal from './siteModal';

import { RootState } from '../../modules';
import { ProjectsManagementStateI, SiteI, fetchSites, fetchSiteTypes, ChangeSiteStatus, fetchSiteNodes } from '../../modules/projectsManagementModule';

const Sites = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { sites, mode, failure }: ProjectsManagementStateI = useSelector((state: RootState) => state.projectsManagementReducer);
    useEffect(() => {
        dispatch(fetchSites(history));
        dispatch(fetchSiteTypes(history));
    }, [dispatch, history])

    useEffect(() => {
        if ((mode === "CHANGE_SITE_STATUS") && failure) {
            dispatch(fetchSites(history));
        }
    }, [mode, failure, history, dispatch])

    const [isOpenEditNodeModal, setEditNodeModal] = useState(false);
    const [selectedSite, setSiteForEditNodeModal] = useState<null | SiteI>(null);
    const [searchStr, setSearch] = useState("");
    const [isOpenSiteModal, setOpenSiteModal] = useState(false);
    const [siteDetailForEdit, setSiteDetailForEdit] = useState<SiteI | null>(null); 


    const handleChangeSearchField = (value: string) => {
        setSearch(value);
    }

    const handleChangeSiteStatus = (e: React.ChangeEvent<HTMLInputElement>, site: SiteI) => {
        dispatch(ChangeSiteStatus({site_id: site.site_id, disabled: !e.target.checked}, history));
    }

    const handleEditSiteNodeEditModal = (site: SiteI) => {
        dispatch(fetchSiteNodes({site_id: site.site_id}, history));
        setEditNodeModal(true);
        setSiteForEditNodeModal(site);
    }

    const handleChangeEditNodeModalVisible = () => {
        setEditNodeModal(!isOpenEditNodeModal)
    }

    const handleToggleCreateSite = () => {
        setSiteDetailForEdit(null); 
        setOpenSiteModal(!isOpenSiteModal);
        return;
    }

    const handleOpenSiteModal = async(site: SiteI) => {
        if (site) {
            setSiteDetailForEdit(site); 
            setOpenSiteModal(!isOpenSiteModal);
        }
    }


    const  funcMatchSites = () => {
        let matchedSites = sites;
        let matched = false;
        if (sites && searchStr) {
            matchedSites = sites.filter((site) => {
                matched = false;
                if (site.address.indexOf(searchStr) !== -1) {
                    matched = true;
                } else if (site.site_name.indexOf(searchStr) !== -1) {
                    matched = true;
                }
                return matched;
            });
        }

        return matchedSites;
    }
    let matchedSites = funcMatchSites();

    const neededFields: string[] = ["Name", "Address", "Nodes", "Status" ];
    return (
        <>
            <Card className="table-y-scrollable">
                <CardHeader className="d-flex justify-content-between align-items-center">
                    Sites
                    <div className="d-flex">
                        <InputGroup className="mr-2">
                            <Input onChange={(e) => handleChangeSearchField(e.target.value)}/>
                            <InputGroupAddon addonType="append"><Button color="white"><i className="ti-search"></i></Button></InputGroupAddon>
                        </InputGroup>
                        <Button color="primary" onClick={handleToggleCreateSite}>
                            <i className="fa fa-plus"></i> Create Site
                        </Button>
                    </div>
                </CardHeader>
                <CardBody>
                    <Table className="no-wrap v-middle" responsive hover size="sm">
                        <thead>
                            <tr className="border-0">
                                {
                                    neededFields.map((field, index) => {
                                        return <th key={"siteField"+index} className="border-0">{field}</th>
                                    })
                                }
                                <th className="border-0">
                                    
                                </th>
                            </tr>
                        </thead>
                        <tbody style={{maxHeight: '100px'}}>
                            {
                                matchedSites && matchedSites.map((site) => {
                                    return <tr key={"site"+site.site_id}>
                                        <td className="pt-2">
                                            {site.site_name}
                                        </td>            
                                        <td className="pt-2">
                                            {site.address}
                                        </td>            
                                        <td>
                                            <Button color="white" onClick={() => handleEditSiteNodeEditModal(site)} className="btn-sm rounded"><i className="fas fa-info"></i></Button>
                                        </td>            
                                        <td>
                                            <Form>
                                                <FormGroup check>
                                                    <Input 
                                                        type="checkbox" 
                                                        checked={!site.disabled} 
                                                        onChange={(e)=>handleChangeSiteStatus(e, site)}/>                                                        
                                                </FormGroup>                                            
                                            </Form>    
                                        </td> 
                                        <td>
                                            <Button color="white" onClick={() => handleOpenSiteModal(site)} className="btn-sm rounded"><i className="fa fa-edit"></i></Button>
                                        </td>           
                                    </tr>
                                })
                            }                            
                        </tbody>
                    </Table>
                </CardBody>
            </Card >
            <EditNodeModal 
                isOpen={isOpenEditNodeModal}
                onToggle={handleChangeEditNodeModalVisible}
                site={selectedSite}
                sites={sites}
            />
            <SiteModal isOpen={isOpenSiteModal} onToggle={handleToggleCreateSite} siteDetail={siteDetailForEdit} />
        </>        
    );
}

export default Sites;
