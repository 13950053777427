import React, { useCallback, useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Button, Input } from 'reactstrap';
import { NodeProps } from './siteModal';
import { checkValidation } from "../../helper/checkValidation";
import { v4 as uuidv4 } from 'uuid';

type Props = {
    nodes: {
        id: string,
        lat: string,
        long: string,
    }[],
    onChangeNodes: (nodes: NodeProps[]) => void,
}

type RowProps = {
    lat: string,
    long: string,
}

type ColumnProps = {
    name: string,
    selector: string,
    sortable: boolean,
    editable: boolean
}

const columns = [
  {
    name: 'Latitude',
    selector: 'lat',
    editable: true,
  },
  {
    name: 'Longitude',
    selector: 'long',
    editable: true,
  },
];

const EditableCell = ({ row, index, column, col, onChange }: any) => {
  const [value, setValue] = useState(row[column.selector]);
  const handleOnChange = (e: any) => {
    setValue(e.target.value);
    onChange?.(e);
  };

  if (column?.editing) {
    return (
      <Input
        type={column.type || 'text'}
        name={column.selector}
        style={{ width: '100%' }}
        onChange={handleOnChange}
        value={value}
      />
    );
  }

  if (col.cell) {
    return col.cell(row, index, column);
  }
  return row[column.selector];
};

const NodesTable = ({ nodes, onChangeNodes }: Props) => {
  let formData = useRef({}).current;
  const isEditing = (record: any) => record.id === editingId;
  const [editingId, setEditingId] = useState('');


  const formOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nam = event.target.name;
    const val = event.target.value;

    formData = {
      ...formData,
      [nam]: val,
    };
  };

  const edit = (record:any) => {
    setEditingId(record.id);
  };

  const cancel = () => {
    setEditingId('');
  };

  const deleteRecord = (record: any) => {
    const tempData = nodes.filter((node) => {
      return node.id !== record.id
    })
    onChangeNodes(tempData);
  }

  const save = (item: any) => {
    const payload = { ...item, ...formData };
    const tempData = [...nodes];    
    const index = tempData.findIndex(item => editingId === item.id);
    if (index > -1) {
      
      const item = tempData[index];
      tempData.splice(index, 1, {
        ...item,
        ...payload,
      });

      setEditingId('');
      onChangeNodes(tempData);
    }
  };

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      cell: (row: RowProps, index: number, column: ColumnProps) => {
        const editing = isEditing(row);
        return (
          <EditableCell
            row={row}
            index={index}
            column={{ ...column, editing }}
            col={col}
            onChange={formOnChange}
          />
        );
      },
    };
  });

  const createColumns = useCallback(() => {
    return [
      ...mergedColumns,
      {
        name: 'Actions',
        allowOverflow: true,
        minWidth: '60px',
        cell: (row: RowProps) => {
          const editable = isEditing(row);
          if (editable) {
            return (
              <div>
                <Button color="white" onClick={() => save(row)} className="mr-2"><i className="fas fa-check"></i></Button>
                <Button color="white" onClick={cancel}><i className="fas fa-times"></i></Button>
              </div>
            );
          }
          return (
            <div>
                <Button color="white" onClick={() => edit(row)} className="mr-2"><i className="fas fa-edit"></i></Button>
                <Button color="white" onClick={() => deleteRecord(row)} ><i className="fas fa-trash-alt"></i></Button>
            </div>
          )
        },
      },
    ];
  }, [mergedColumns]);

  const handleNewAddNode = () => {
    let tempNode = { id: uuidv4(), lat: "", long: "" }
    onChangeNodes(nodes.concat([tempNode]));
    setEditingId(tempNode.id);
    return
  }

  const conditionalRowStyles = [
    {
      when: (row:any) => !checkValidation(row.lat, 'floatAndNumber').validation,
      style: {
        backgroundColor: '#f8d7da',
        color: 'white',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  ];  
  const actions = React.useMemo(() => <Button onClick={() => handleNewAddNode()}><i className="fas fa-plus"></i>Add</Button>, [nodes]);
  return (
    <DataTable
      title="Site Geolocation"
      columns={createColumns()}
      data={nodes}
      actions={actions}
      conditionalRowStyles={conditionalRowStyles}
    />
  );
};

export default NodesTable;