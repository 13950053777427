import { useState } from "react";
import classNames from "classnames";
import { Collapse, } from "reactstrap";
import { NavLink } from "react-router-dom";

type Props = {
  icon: string,
  name: string,
  path: string,
  items: {
      path: string,
      name: string | null ,
      icon: string | null,
      component: () => JSX.Element,
  }[] | undefined,
  onClickShowSideBarBtn: () => void;
}

const SubMenu = ({onClickShowSideBarBtn, icon, name, items}: Props) => {
  const [collapsed, setCollapsed] = useState(true);
  const toggle = () => setCollapsed(!collapsed);
  // const { icon, name, items } = props;
  return (
    <>
      <li
        onClick={toggle}
        className={classNames({ "menu-open": !collapsed }, 'sidebar-item')}
      >
        <div className="dropdown-toggle sidebar-link">
          <i className={icon} />
          <span className="hide-menu">{name}</span>
        </div>
      </li>
      <Collapse
        isOpen={!collapsed}
      >
        {items && items.map((item, index) => {
          if (item.name && item.icon) {
            return (
              <li key={index} className="sidebar-item">
                <NavLink to={item.path} className="sidebar-link" onClick={onClickShowSideBarBtn}>
                  <i className={item.icon} />
                  <span className="hide-menu">{item.name}</span>
                </NavLink>
              </li>
            )
          } else {
            return null
          }
        })}
      </Collapse>
    </>
  );
};

export default SubMenu;
