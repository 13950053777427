import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router";

// reactstrap components
import {
    Button,
    FormGroup,
    Form,
    Input,
    FormText,
    Row,
    Col,
    Modal, 
    ModalHeader,
    ModalBody, 
    ModalFooter   
} from "reactstrap";

import { BaseUrl } from "../../constant/constant";
import { fetchProjects, ProjectI,} from '../../modules/projectsManagementModule';

type ErrorProps = {
    isError: boolean | null,
    message: string,
}

type Props = {
    onToggle: () => void,
    isOpen: boolean,
    projectDetail: null | ProjectI
}

const ProjectModal = ({ onToggle, isOpen, projectDetail }: Props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        setProjectID(projectDetail? projectDetail.project_id: "");
        setProjectName(projectDetail? projectDetail.project_name: "");
        setAddress(projectDetail? projectDetail.address: "");
        setProjectDesc(projectDetail? projectDetail.description: "");
    }, [projectDetail])

    // input field variable 
    const [project_id, setProjectID] = useState(projectDetail? projectDetail.project_id: "");
    const [projectName, setProjectName] = useState(projectDetail? projectDetail.project_name: "");
    const [projectAddress, setAddress] = useState(projectDetail? projectDetail.address: "");
    const [projectDesc, setProjectDesc] = useState(projectDetail? projectDetail.description: "");

    // set error 
    const [projectNameError, setProjectNameError] = useState<ErrorProps>({isError: null, message: ""});
    const [projectAddressError, setProjectAddressError] = useState<ErrorProps>({isError: null, message: ""});
    const [projectDescError, setProjectDescError] = useState<ErrorProps>({isError: null, message: ""});

    const handleChangeProjectName = (value: string) => {
        setProjectName(value);
        setProjectNameError({isError: false, message: ""});
    }

    const handleChangeAddress = (value: string) => {
        setAddress(value);
        setProjectAddressError({isError: false, message: ""});
    }

    const handleChangeDescription = (value: string) => {
        setProjectDesc(value);
        setProjectDescError({isError: false, message: ""});
    }

    const handleSaveProject = async() => {
        let returnFlag = false;
        if (!projectDetail && projectName === "") {
            setProjectNameError({isError: true, message: "Please enter this field."});
            returnFlag = true;
        }

        if (!projectDetail && projectAddress === "") {
            setProjectAddressError({isError: true, message: "Please enter this field."});
            returnFlag = true;
        }

        if (!projectDetail && projectDesc === "") {
            setProjectDescError({isError: true, message: "Please enter this field."});
            returnFlag = true;
        }

        if (returnFlag) {
            return 
        }

        onToggle();

        if (projectDetail) {
            let requestDetail = {
                project_id: project_id,
                project_name: projectName,
                description: projectDesc,
                address: projectAddress
            };
    
            let token: string | null = localStorage.getItem('token');
            if (token) {
                let res = await fetch(`${BaseUrl}/project`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token
                    },
                    method: 'PUT',
                    body: JSON.stringify(requestDetail)
                })
                let json = await res.json();
                if (json.message === "token is invalid!") {
                    history.push("/");
                    localStorage.setItem('token', '');
                } 
                if (!json.message) {
                    dispatch(fetchProjects(history));                
                }

            }
        } else {
            let requestDetail = {
                project_name: projectName,
                description: projectDesc,
                address: projectAddress
            };
    
            let token: string | null = localStorage.getItem('token');
            if (token) {
                let res = await fetch(`${BaseUrl}/project`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token
                    },
                    method: 'POST',
                    body: JSON.stringify(requestDetail)
                })
                let json = await res.json();
                if (json.message === "token is invalid!") {
                    history.push("/");
                    localStorage.setItem('token', '');
                } 
                
                if (!json.message) {
                    dispatch(fetchProjects(history));                
                }
            }
        }


        setProjectName('');
        setAddress('');
        setProjectDesc('');
    }

    const handleToggleModal = () => {
        onToggle();
        setProjectName('');
        setAddress('');
        setProjectDesc('');
    }

    return (
        <>
            <Modal isOpen={isOpen} toggle={handleToggleModal} >
                <ModalHeader toggle={handleToggleModal}>{projectDetail ? "Edit Project" : "Create Project"}</ModalHeader>
                <ModalBody>
                    <Form autoComplete="off">
                        <div className="pl-lg-4">
                            <Row>
                                <Col sm="12">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-project-name"
                                        >
                                            Name
                                        </label>
                                        <Input
                                            className="form-control-alternative"
                                            id="input-project-name"
                                            type="text"
                                            value={projectName}
                                            onChange={(e)=>{handleChangeProjectName(e.target.value)}}
                                            invalid={(projectNameError.isError === true)}
                                        />
                                    </FormGroup>
                                    <FormText>{projectNameError.isError? projectNameError.message: ""}</FormText>
                                </Col>
                                <Col sm="12">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-project-address"
                                        >
                                            Address
                                        </label>
                                        <Input
                                            className="form-control-alternative"
                                            id="input-project-address"
                                            type="text"
                                            value={projectAddress}
                                            onChange={(e)=>{handleChangeAddress(e.target.value)}}
                                            invalid={(projectAddressError.isError === true)}
                                        />
                                    </FormGroup>
                                    <FormText>{projectAddressError.isError? projectAddressError.message: ""}</FormText>                      
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-project-description"
                                        >
                                            Description
                                        </label>
                                        <Input
                                            className="form-control-alternative"
                                            id="input-project-description"
                                            type="textarea"
                                            value={projectDesc}
                                            onChange={(e)=>{handleChangeDescription(e.target.value)}}
                                            invalid={(projectDescError.isError === true)}
                                        />
                                    </FormGroup>
                                    <FormText>{projectDescError.isError? projectDescError.message: ""}</FormText>                                       
                                </Col>                
                            </Row>
                        </div>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSaveProject}>Save</Button>
                    <Button color="secondary" onClick={handleToggleModal}>Cancel</Button>
                </ModalFooter>
            </Modal> 
        </>
    )
};

export default ProjectModal;

