import { combineReducers } from 'redux';
// import reducers
import sidebarReducer from './sidebarModule';
import loginReducer from './loginModule';
import userProfileReducer from './userProfileModule';
import accountSettingsReducer from './accountSettingsModule';
import projectsManagementReducer from './projectsManagementModule';

// combine all reducers
const rootReducer = combineReducers({
  sidebarReducer,
  loginReducer,
  userProfileReducer,
  accountSettingsReducer,
  projectsManagementReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;