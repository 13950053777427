import { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Row, Col, Card, CardBody, CardHeader, CardTitle, CardSubtitle, CardText, Badge, Table } from 'reactstrap';
import { Slider } from '../../components/dashboard';
import { fetchProfile } from "../../modules/userProfileModule";

import GoogleMapReact from 'google-map-react';
import apiKeys from "../../constant/apiKeys";
import { BaseUrl } from "../../constant/constant";
import LoadingGif from "../../assets/images/loading.gif";

interface DashboardDataItemI {
    arable: {
        crop_counts: number,
        crop_site_counts: number,
        crop_site_polygons: {
            lats: string[],
            longs: string[]
        }[],
        crop_sites: string[],
        crop_type_counts: number,
        crop_types: string[]
    },
    livestock: {
        animal_counts: number,
        animal_site_counts: number,
        animal_site_polygons: {
            lats: string[],
            longs: string[]
        }[],
        animal_sites: string[],
        animal_type_counts: number,
        animal_types: string[],        
    },
    no_of_devices: number,
    node_type_name: string[],
    site_counts: number,
    total_project: number,
    client_name: string,
    lat: string,
    lon: string,
}

const Dashboard = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [googleNews, setGoogleNews] = useState([]);
    const [dashboardData, setDashboardData] = useState<DashboardDataItemI[] | null>(null);
    const [loadingDashboarData, setLoadingDashboardData] = useState(0); // 1: pending, 2: success, 3: failure, 0: initial

    const fetchData = useCallback(async (history) => {
        let token: string | null = localStorage.getItem('token');
        if (token) {
            setLoadingDashboardData(1);

            let res = await fetch(`${BaseUrl}/dashboard`, {
              headers: {
                  'Content-Type': 'application/json',
                  'x-access-token': token
              },
              method: 'GET',
            })
            let json = await res.json();
            if (json.message) {
              setLoadingDashboardData(3);
            }
            if (json.message === "token is invalid!") {
              history.push("/");
            }  
            if (json.dashboard) {
              setLoadingDashboardData(2);

              setDashboardData(json.dashboard);
              return json.dashboard;
            }                      
        }
    }, [])
    // When loading page, fetch user profile data from server
    useEffect(() => {
        dispatch(fetchProfile(history));

        // fetch google news
        async function fetchGoogleNews (source: string) {
            let res = await fetch(`https://newsapi.org/v2/top-headlines?sources=${source}&apiKey=${apiKeys.googleNewsApikKey}`, {
                method: 'GET',
            });//process.env.API_KEY; set API key via environment or dotenv file
            let json = await res.json();
            if (json.articles) {
              setGoogleNews(json.articles);
            }
        } 
        fetchGoogleNews("google-news");
        fetchData(history);
    }, [dispatch, history, fetchData])


    const handleApiLoaded = async (map: any, maps: any) => {
        map.mapTypeId = "terrain";

        let dashboardData = null;
        dashboardData = await fetchData(history);

        let loginUserFeaturesStr = localStorage.getItem('features');        
        let loginUserFeatures = loginUserFeaturesStr !== null ? JSON.parse(loginUserFeaturesStr): null;

        if (dashboardData) {
            for (let dataIndex = 0; dataIndex < dashboardData.length; dataIndex++) {
                if (loginUserFeatures.arable) {
                    const cropSitePolygonsData = dashboardData[dataIndex].arable.crop_site_polygons;
                    // cropSitePolygonsData
                    if (cropSitePolygonsData) {
                      for (let cropSitePolygonIndex = 0; cropSitePolygonIndex < cropSitePolygonsData.length; cropSitePolygonIndex++) {
                          const cropSitePolygonCoords = cropSitePolygonsData[cropSitePolygonIndex].lats.map((lat: string, index: number) => {
                              return {lat: parseFloat(lat), lng: parseFloat(cropSitePolygonsData[cropSitePolygonIndex].longs[index])}
                          });
                          const cropSitePolygon = new maps.Polygon({
                              paths: cropSitePolygonCoords,
                              strokeColor: "#00FF00",
                              strokeOpacity: 0.8,
                              strokeWeight: 3,
                              fillColor: "#00FF00",
                              fillOpacity: 0.35,                        
                          })
                          cropSitePolygon.setMap(map);     
                      }    
                    }
                }

                if (loginUserFeatures.livestock) {
                    const animalSitePolygonsData = dashboardData[dataIndex].livestock.animal_site_polygons;
                    // liveStock PolygonsData
                    if (animalSitePolygonsData) {
                      for (let animalPolygonIndex = 0; animalPolygonIndex < animalSitePolygonsData.length; animalPolygonIndex++) {
                          const animalSitePolygonCoords = animalSitePolygonsData[animalPolygonIndex].lats.map((lat: string, index: number) => {
                              return {lat: parseFloat(lat), lng: parseFloat(animalSitePolygonsData[animalPolygonIndex].longs[index])}
                          });
                          const animalCropSitePolygon = new maps.Polygon({
                              paths: animalSitePolygonCoords,
                              strokeColor: "#FF0000",
                              strokeOpacity: 0.8,
                              strokeWeight: 3,
                              fillColor: "#FF0000",
                              fillOpacity: 0.35,                        
                          })
                          animalCropSitePolygon.setMap(map);                       
                      }                                       
                    }
                }
            }    
        }
    }
    return (
        <div className="dashboard">
          {
            // 
            (dashboardData !== null) && 
            dashboardData.map((item, itemIndex) => {
              return (
                /*--------------------------------------------------------------------------------*/
                /* Table with Arable and Livestock polygon                                                  */
                /*--------------------------------------------------------------------------------*/
                <div key={"dashboardDataItem"+itemIndex}>
                  <p className="h1">{item.client_name}</p>
                  <hr />
                  {/*--------------------------------------------------------------------------------*/
                  /* Google map                                                  */
                  /*--------------------------------------------------------------------------------*/}
                  <Card>
                    {/*<CardHeader className="d-flex justify-content-between align-items-center">
                        Google Map
                    </CardHeader>*/}
                    <CardBody>
                      <div style={{height: '50vh'}}>
                        <GoogleMapReact
                          bootstrapURLKeys={{ key: apiKeys.googleMapApiKey }}
                          defaultZoom={13}
                          defaultCenter={{lat: parseFloat(item.lat), lng: parseFloat(item.lon)}} // Nigeria
                          yesIWantToUseGoogleMapApiInternals
                          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                        >
                        </GoogleMapReact>           
                      </div>
                    </CardBody>
                  </Card>
                  <Row>
                    <Col md={6} className="dashboard__client">
                      <Card>
                        <CardBody className="p-4">
                          <CardTitle tag="h2" className="mb-4">
                            Overview <hr />
                          </CardTitle>
                          <Row>
                            <Col sm={4}>
                              <div className="bg-light text-primary p-3 border rounded">
                                <p className="h1 m-0">
                                  <i className="ti-package mr-2" style={{fontSize: '1.5rem'}}></i>
                                  {item.total_project}
                                </p>
                                Total Projects
                              </div>    
                            </Col>
                            <Col sm={4}>
                              <div className="bg-light text-primary p-3 border rounded">
                                <p className="h1 m-0">
                                  <i className="ti-map-alt mr-2" style={{fontSize: '1.5rem'}}></i>
                                  {item.site_counts}
                                </p>
                                Site Counts
                              </div>    
                            </Col>
                            <Col sm={4}>
                              <div className="bg-light text-primary p-3 border rounded">
                                <p className="h1 m-0">
                                  <i className="ti-signal mr-2" style={{fontSize: '1.5rem'}}></i>
                                  {item.no_of_devices}
                                </p>
                                No of Devices
                              </div>    
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={3} className="dashboard__item">
                      <Card>
                        <CardBody>
                          <CardTitle tag="h3" className="mb-1 font-weight-bold">
                            <div className="d-flex justify-content-between">
                              Livestock <i className="ti-widget"></i>
                            </div> 
                            <hr/>
                          </CardTitle>
                          <CardSubtitle tag="h5" className="mb-2 text-muted m-0"></CardSubtitle>
                          <div className="d-flex justify-content-between align-items-center my-1">Animals <Badge color="primary" className="font-weight-bold">{item.livestock.animal_counts}</Badge></div>
                          <div className="d-flex justify-content-between align-items-center my-1">Sites <Badge color="primary" className="font-weight-bold">{item.livestock.animal_site_counts}</Badge></div>
                          <div className="d-flex justify-content-between align-items-center my-1">Types <Badge color="primary" className="font-weight-bold">{item.livestock.animal_type_counts}</Badge></div>
                          <CardText></CardText>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={3} className="dashboard__item">
                      <Card>
                        <CardBody>
                          <CardTitle tag="h3" className="mb-1 font-weight-bold">
                            <div className="d-flex justify-content-between">
                              Arable <i className="ti-layout-sidebar-2"></i>
                            </div>
                            <hr/>
                          </CardTitle>
                          <CardSubtitle tag="h5" className="mb-2 text-muted m-0"></CardSubtitle>
                          <div className="d-flex justify-content-between align-items-center my-1">Crops <Badge color="primary" className="font-weight-bold">{item.arable.crop_counts}</Badge></div>
                          <div className="d-flex justify-content-between align-items-center my-1">Sites <Badge color="primary" className="font-weight-bold">{item.arable.crop_site_counts}</Badge></div>
                          <div className="d-flex justify-content-between align-items-center my-1">Types <Badge color="primary" className="font-weight-bold">{item.arable.crop_type_counts}</Badge></div>
                          <CardText></CardText>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              )
              })
            }
            {/*--------------------------------------------------------------------------------*/
            /* Table with Arable and Livestock polygon                                                  */
            /*--------------------------------------------------------------------------------*/}
            <Card className="table-y-scrollable">
                <CardHeader className="d-flex justify-content-between align-items-center">
                    Clients And Projects
                </CardHeader>
                <CardBody>    
                  {
                    (loadingDashboarData === 1) ?
                      <div className="d-flex justify-content-center">
                          <img src={LoadingGif} alt="loading" width="20px" /> 
                      </div> 
                    :          

                    <Table className="no-wrap v-middle" responsive hover size="sm">
                      <thead>
                        <tr className="border-0">
                            <th className="border-0">
                                Client Name
                            </th>
                            <th className="border-0">
                                Number Of Devices
                            </th>
                            <th className="border-0">
                                Site Counts
                            </th>
                            <th className="border-0">
                                Total Projects
                            </th>
                        </tr>
                      </thead>
                      <tbody style={{maxHeight: '100px'}}>
                        {dashboardData && dashboardData.map((item, itemIndex) => {
                            return (<tr key={"dashboardDataItem"+itemIndex}>
                                <td className="pt-2">
                                    {item.client_name}
                                </td>    
                                <td>
                                    {item.no_of_devices}
                                </td>  
                                <td className="pt-2">
                                    {item.site_counts}
                                </td>
                                <td>
                                    {item.total_project}
                                </td>  
                            </tr>)
                        })}                            
                      </tbody>
                    </Table>
                  }
                </CardBody>
            </Card>
            {/*--------------------------------------------------------------------------------*/
            /* Google News                                                  */
            /*--------------------------------------------------------------------------------*/}
            <div className="">
              <p className="h1">News</p>
              <hr/>
              <Slider articles={googleNews}/>
            </div>
        </div>
    );
}

export default Dashboard;
