import {Dispatch} from "redux";
import { History } from "history";
import { BaseUrl } from "../constant/constant";

// FETCH PROFILE ACTION TYPES
export const FETCH_PROFILE_PENDING = "userProfile/FETCH_PROFILE_PENDING";
export const FETCH_PROFILE_FAIL = "userProfile/FETCH_PROFILE_FAIL";
export const FETCH_PROFILE_SUCCESS = "userProfile/FETCH_PROFILE_SUCCESS";

// SAVE USER PROFIEL ACTION TYPES
export const SAVE_PROFILE_PENDING = "userProfile/SAVE_PROFILE_PENDING";
export const SAVE_PROFILE_FAIL = "userProfile/SAVE_PROFILE_FAIL";
export const SAVE_PROFILE_SUCCESS = "userProfile/SAVE_PROFILE_SUCCESS";

// INTERFACES FOR THE FETCH PROFILE 
export interface FetchProfileResultI {
    message: string,
    user_data: ProfileI[]
}

export interface UserProjectI {
    project_id: string,
    project_name: string, 
    description: string,
    address: string,
}

export interface SubProfileI {
    firstname: string,
    surname: string,
    email: string,
    image_name: string,
    telephone: string,
    is_admin: boolean,
}

export interface ProfileI extends SubProfileI {
    projects: UserProjectI[],
}

// FETCH PROFIEL DISPATCH TYPE
export interface FetchProfilePending {
    type: typeof FETCH_PROFILE_PENDING
}

export interface FetchProfileFail {
    type: typeof FETCH_PROFILE_FAIL
    payload: FetchProfileResultI
}

export interface FetchProfileSuccess {
    type: typeof FETCH_PROFILE_SUCCESS,
    payload: FetchProfileResultI
}

// INTERFACES FOR THE SAVE PROFILE
export interface SaveProfileResultI {
    message: string,
    user_data: ProfileI[],
    user_id: string
}

// SAVE PROFIEL DISPATCH TYPE
export interface SaveProfilePending {
    type: typeof SAVE_PROFILE_PENDING
}

export interface SaveProfileFail {
    type: typeof SAVE_PROFILE_FAIL
    payload: SaveProfileResultI
}

export interface SaveProfileSuccess {
    type: typeof SAVE_PROFILE_SUCCESS,
    payload: SaveProfileResultI
}
// 
export type ProfileDispatchTypes = 
    FetchProfilePending | 
    FetchProfileFail | 
    FetchProfileSuccess |
    SaveProfilePending |
    SaveProfileFail |
    SaveProfileSuccess 


export const fetchProfile = (history: History) => async (dispatch: Dispatch<ProfileDispatchTypes>) => {
    try {
        dispatch({
            type: FETCH_PROFILE_PENDING
        })
        let token: string | null = localStorage.getItem('token');
        if (token) {
            return fetch(`${BaseUrl}/profile`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token
                },
                method: 'GET',
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.message === "token is invalid!") {
                    history.push('/');
                    localStorage.setItem('token', '');
                }   
                if (json.message) {
                    dispatch({
                        type: FETCH_PROFILE_FAIL,
                        payload: json
                    })    
                } else {
                    localStorage.setItem('user_data', JSON.stringify(json.user_data));
                    dispatch({
                        type: FETCH_PROFILE_SUCCESS,
                        payload: json
                    })
                }
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_PROFILE_FAIL,
                    payload: error
                })
            });    
        }

    } catch(e) {
        dispatch({
            type: FETCH_PROFILE_FAIL,
            payload: e,
        })
    }
};

export const saveUserProfile = (requestDetail: SubProfileI, history: History) => async (dispatch: Dispatch<ProfileDispatchTypes>) => {
    try {
        dispatch({
            type: SAVE_PROFILE_PENDING
        })
        let token: string | null = localStorage.getItem('token');

        if (token) {
            return fetch(`${BaseUrl}/profile`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token
                },
                method: 'PUT',
                body: JSON.stringify(requestDetail)
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.message === "token is invalid!") {
                    history.push('/');
                    localStorage.setItem('token', '');
                }                   
                if (json.message) {
                    dispatch({
                        type: SAVE_PROFILE_FAIL,
                        payload: json
                    })    
                } else {
                    dispatch({
                        type: SAVE_PROFILE_SUCCESS,
                        payload: json
                    })    
                }
            })
            .catch((error) => {
                dispatch({
                    type: SAVE_PROFILE_FAIL,
                    payload: error
                })
            });    
        }

    } catch(e) {
        dispatch({
            type: SAVE_PROFILE_FAIL,
            payload: e,
        })
    }
};
  
export interface UserProfileStateI {
    pending: boolean,
    failure: boolean,
    success: boolean,
    user_data: ProfileI[] | null, 
    message: string, 
    user_id: string,
}

const defaultState: UserProfileStateI = {
    pending: false,
    failure: false,
    success: true,
    message: "",
    user_data: null,
    user_id: "",
};
  
const userProfileReducer = (state: UserProfileStateI = defaultState, action: ProfileDispatchTypes) : UserProfileStateI => {
    switch (action.type) {
        case FETCH_PROFILE_PENDING:
            return {
                ...state,
                pending: true,
                failure: false,
                success: false,
                message: "",
                user_id: "",
            }
        case FETCH_PROFILE_FAIL:
            return {
                ...state,
                pending: false,
                failure: true,
                message: action.payload.message,
                user_id: "",
            }
        case FETCH_PROFILE_SUCCESS:
            if (action.payload.user_data) { 
                return {
                    ...state,
                    pending: false,
                    success: true,
                    user_data: action.payload.user_data,
                }    
            } else {
                return {
                    ...state,
                    pending: false,
                    failure: true,
                    message: action.payload.message,
                }                    
            }

        case SAVE_PROFILE_PENDING:
            return {
                ...state,
                pending: true,
                failure: false,
                success: false,
                message: "",
                user_id: "",
            }
        case SAVE_PROFILE_FAIL:
            return {
                ...state,
                pending: false,
                failure: true,
                message: action.payload.message,
            }
        case SAVE_PROFILE_SUCCESS:
            if (action.payload.user_id === "Done") {
                return {
                    ...state,
                    pending: false,
                    success: true,
                    user_id: action.payload.user_id,
                }            
            } else {
                return {
                    ...state,
                    pending: false,
                    failure: true,
                    user_id: action.payload.user_id,
                    message: action.payload.message,
                }            
            }
        default:
            return state
    }
  };
  
  
  export default userProfileReducer