import {Dispatch} from "redux";
import { History } from "history";
import { BaseUrl } from "../constant/constant";

// FETCH SETTINGS UNITS ACTION TYPES
export const FETCH_SETTINGS_AND_UNITS_PENDING = "settings/FETCH_SETTINGS_AND_UNITS_PENDING";
export const FETCH_SETTINGS_AND_UNITS_FAIL = "settings/FETCH_SETTINGS_AND_UNITS_FAIL";
export const FETCH_SETTINGS_AND_UNITS_SUCCESS = "settings/FETCH_SETTINGS_AND_UNITS_SUCCESS";

// SAVE SETTING UNIT ACTION TYPES
export const SAVE_SETTINGS_AND_UNITS_PENDING = "settings/SAVE_SETTINGS_AND_UNITS_PENDING";
export const SAVE_SETTINGS_AND_UNITS_FAIL = "settings/SAVE_SETTINGS_AND_UNITS_FAIL";
export const SAVE_SETTINGS_AND_UNITS_SUCCESS = "settings/SAVE_SETTINGS_AND_UNITS_SUCCESS";

// FETCH SETTING USERS ACTION TYPES
export const FETCH_SETTING_USERS_PENDING = "settings/FETCH_SETTING_USERS_PENDING";
export const FETCH_SETTING_USERS_FAIL = "settings/FETCH_SETTING_USERS_FAIL";
export const FETCH_SETTING_USERS_SUCCESS = "settings/FETCH_SETTING_USERS_SUCCESS";

// FETCH SETTING USER ACTION TYPES
export const FETCH_USER_PENDING = "settings/FETCH_USER_PENDING";
export const FETCH_USER_FAIL = "settings/FETCH_USER_FAIL";
export const FETCH_USER_SUCCESS = "settings/FETCH_USER_SUCCESS";

// CREATE USER ACTION TYPES
export const CREATE_USER_PENDING = "settings/CREATE_USER_PENDING";
export const CREATE_USER_FAIL = "settings/CREATE_USER_FAIL";
export const CREATE_USER_SUCCESS = "settings/CREATE_USER_SUCCESS";

// EDIT USER ACTION TYPES
export const EDIT_USER_PENDING = "settings/EDIT_USER_PENDING";
export const EDIT_USER_FAIL = "settings/EDIT_USER_FAIL";
export const EDIT_USER_SUCCESS = "settings/EDIT_USER_SUCCESS";

// FETCH SETTING PROJECTS ACTION TYPES
export const FETCH_SETTING_CLIENTS_PROJECTS_PENDING = "settings/FETCH_SETTING_CLIENTS_PROJECTS_PENDING";
export const FETCH_SETTING_CLIENTS_PROJECTS_FAIL = "settings/FETCH_SETTING_CLIENTS_PROJECTS_FAIL";
export const FETCH_SETTING_CLIENTS_PROJECTS_SUCCESS = "settings/FETCH_SETTING_CLIENTS_PROJECTS_SUCCESS";

// ADD USER TO PROJECT ACTION TYPES
export const ADD_USER_TO_PROJECT_PENDING = "settings/ADD_USER_TO_PROJECT_PENDING";
export const ADD_USER_TO_PROJECT_FAIL = "settings/ADD_USER_TO_PROJECT_FAIL";
export const ADD_USER_TO_PROJECT_SUCCESS = "settings/ADD_USER_TO_PROJECT_SUCCESS";

// DELETE USER FROM PROJECT ACTION TYPES
export const DELETE_USER_FROM_PROJECT_PENDING = "settings/DELETE_USER_FROM_PROJECT_PENDING";
export const DELETE_USER_FROM_PROJECT_FAIL = "settings/DELETE_USER_FROM_PROJECT_FAIL";
export const DELETE_USER_FROM_PROJECT_SUCCESS = "settings/DELETE_USER_FROM_PROJECT_SUCCESS";

// FETCH SETTING PROJECTS ACTION TYPES
export const FETCH_SETTING_CLIENT_PROJECT_SITES_PENDING = "settings/FETCH_SETTING_CLIENT_PROJECT_SITES_PENDING";
export const FETCH_SETTING_CLIENT_PROJECT_SITES_FAIL = "settings/FETCH_SETTING_CLIENT_PROJECT_SITES_FAIL";
export const FETCH_SETTING_CLIENT_PROJECT_SITES_SUCCESS = "settings/FETCH_SETTING_CLIENT_PROJECT_SITES_SUCCESS";

// ADD USER TO PROJECT ACTION TYPES
export const ADD_USER_TO_SITE_PENDING = "settings/ADD_USER_TO_SITE_PENDING";
export const ADD_USER_TO_SITE_FAIL = "settings/ADD_USER_TO_SITE_FAIL";
export const ADD_USER_TO_SITE_SUCCESS = "settings/ADD_USER_TO_SITE_SUCCESS";

// DELETE USER FROM PROJECT ACTION TYPES
export const DELETE_USER_FROM_SITE_PENDING = "settings/DELETE_USER_FROM_SITE_PENDING";
export const DELETE_USER_FROM_SITE_FAIL = "settings/DELETE_USER_FROM_SITE_FAIL";
export const DELETE_USER_FROM_SITE_SUCCESS = "settings/DELETE_USER_FROM_SITE_SUCCESS";

// FETCH SETTING USERS ACTION TYPES
export const FETCH_USER_ROLES_PENDING = "settings/FETCH_USER_ROLES_PENDING";
export const FETCH_USER_ROLES_FAIL = "settings/FETCH_USER_ROLES_FAIL";
export const FETCH_USER_ROLES_SUCCESS = "settings/FETCH_USER_ROLES_SUCCESS";

// INTERFACES FOR THE FETCH SETTINGS AND UNITS
export interface FetchSettingsAndUnitsResultI {
    message: string,
    settings_data: SettingAndUnitsI[]
}

export interface SettingAndUnitsI {
    name: string,
    selected_unit: string,
    units: string[],
}

// FETCH SETTINGS UNITS DISPATCH TYPE
export interface FetchSettingsAndUnitsPending {
    type: typeof FETCH_SETTINGS_AND_UNITS_PENDING
}

export interface FetchSettingsAndUnitsFail {
    type: typeof FETCH_SETTINGS_AND_UNITS_FAIL
    payload: FetchSettingsAndUnitsResultI
}

export interface FetchSettingsAndUnitsSuccess {
    type: typeof FETCH_SETTINGS_AND_UNITS_SUCCESS,
    payload: FetchSettingsAndUnitsResultI
}

// INTERFACES FOR THE SAVE SETTING UNIT
export interface SaveSettingUnitResultI {
    message: string,
    user_id: string,
}

// INTERFACES FOR THE REQUEST FOR SAVE SETTING UNIT
export interface SaveSettingUnitRequestI {
    name: string,
    selected_unit: string,
}

// SAVE SETTING UNIT DISPATCH TYPE
export interface SaveSettingUnitPending {
    type: typeof SAVE_SETTINGS_AND_UNITS_PENDING
}

export interface SaveSettingUnitFail {
    type: typeof SAVE_SETTINGS_AND_UNITS_FAIL
    payload: SaveSettingUnitResultI
}

export interface SaveSettingUnitSuccess {
    type: typeof SAVE_SETTINGS_AND_UNITS_SUCCESS,
    payload: SaveSettingUnitResultI
}
/////////////////////////////////////////////////////////////////////////////
// INTERFACES FOR THE ROLE 
export interface UserRoleI {
    role_id: number,
    name: string,
}
// INTERFACES FOR THE USER ROLES
export interface FetchUserRolesResultI {
    message: "",
    roles: UserRoleI[]
}

// FETCH SETTING USERS DISPATCH TYPE
export interface FetchUserRolesPending {
    type: typeof FETCH_USER_ROLES_PENDING
}

export interface FetchUserRolesFail {
    type: typeof FETCH_USER_ROLES_FAIL
    payload: FetchUserRolesResultI
}

export interface FetchUserRolesSuccess {
    type: typeof FETCH_USER_ROLES_SUCCESS,
    payload: FetchUserRolesResultI
}
/////////////////////////////////////////////////////////////////////////////

// INTERFACES FOR THE SETTING USER
export interface SettingUserI {
    created_by: string,
    email: string,
    forename: string,
    is_admin: boolean,
    surname: string,
    user_id: string,
    image_name: string
}

// INTERFACES FOR THE SETTING USERS
export interface FetchSettingUsersResultI {
    message: "",
    user_data: SettingUserI[]
}

// FETCH SETTING USERS DISPATCH TYPE
export interface FetchSettingUsersPending {
    type: typeof FETCH_SETTING_USERS_PENDING
}

export interface FetchSettingUsersFail {
    type: typeof FETCH_SETTING_USERS_FAIL
    payload: FetchSettingUsersResultI
}

export interface FetchSettingUsersSuccess {
    type: typeof FETCH_SETTING_USERS_SUCCESS,
    payload: FetchSettingUsersResultI
}

// INTERFACES FOR THE SETTING USER
export interface FetchUserI {
    created_by: string,
    email: string,
    forename: string,
    is_admin: boolean,
    surname: string,
    user_id: string,
    telephone: string,
    image_name: string,
    role_id: number,
    role_name: string, 
}

// INTERFACES FOR THE SETTING USER
export interface FetchUserResultI {
    message: "",
    user_data: FetchUserI[],
}

export interface FetchUserRequestI {
    user_id: string,
}

// FETCH SETTING USER DISPATCH TYPE
export interface FetchUserPending {
    type: typeof FETCH_USER_PENDING
}

export interface FetchUserFail {
    type: typeof FETCH_USER_FAIL
    payload: FetchUserResultI
}

export interface FetchUserSuccess {
    type: typeof FETCH_USER_SUCCESS,
    payload: FetchUserResultI
}

// INTERFACES FOR THE CREATE USER RESULT
export interface CreateUserResultI {
    message: string,
    user_id: string,
}

// INTERFACES FOR THE REQUEST FOR CREATE USER
export interface CreateUserRequestI {
    firstname: string,
    surname: string,
    email: string,
    role_id: number,
}

// CREATE USER DISPATCH TYPE
export interface CreateUserPending {
    type: typeof CREATE_USER_PENDING
}

export interface CreateUserFail {
    type: typeof CREATE_USER_FAIL
    payload: CreateUserResultI
}

export interface CreateUserSuccess {
    type: typeof CREATE_USER_SUCCESS,
    payload: CreateUserResultI
}

// INTERFACES FOR THE CREATE USER RESULT
export interface EditUserResultI {
    message: string,
    user_id: string,
}

// INTERFACES FOR THE REQUEST FOR EDIT USER
export interface EditUserRequestI {
    firstname: string,
    surname: string,
    image_name: string,
    telephone: string,
    user_id: string,
    role_id: number,
}

// CREATE USER DISPATCH TYPE
export interface EditUserPending {
    type: typeof EDIT_USER_PENDING
}

export interface EditUserFail {
    type: typeof EDIT_USER_FAIL
    payload: EditUserResultI
}

export interface EditUserSuccess {
    type: typeof EDIT_USER_SUCCESS,
    payload: EditUserResultI
}


// INTERFACES FOR THE SETTING PROJECTS
export interface clientProjectLinkedMemberI {
    user_id: string,
}

export interface ClientProjectI {
    project_id: string,
    project_name: string,
    linked_members: clientProjectLinkedMemberI[]
}

export interface SettingClientProjectsI {
    client_id: string,
    client_name: string,
    projects: ClientProjectI[]
}

export interface FetchSettingClientsProjectsResultI {
    message: "",
    user_projects: SettingClientProjectsI[]
}

// FETCH SETTING PROJECTS DISPATCH TYPE
export interface FetchSettingClientsProjectsPending {
    type: typeof FETCH_SETTING_CLIENTS_PROJECTS_PENDING
}

export interface FetchSettingClientsProjectsFail {
    type: typeof FETCH_SETTING_CLIENTS_PROJECTS_FAIL
    payload: FetchSettingClientsProjectsResultI
}

export interface FetchSettingClientsProjectsSuccess {
    type: typeof FETCH_SETTING_CLIENTS_PROJECTS_SUCCESS,
    payload: FetchSettingClientsProjectsResultI
}

// INTERFACES FOR THE REQUEST FOR ADD USER TO PROJECT
export interface AddUserToProjectRequestI {
    user_id: string,
    project_id: string,
}

// INTERFACES FOR THE ADD USER TO PROJECT
export interface AddUserToProjectResultI {
    message: string,
    user_project: string,
    willBeAddedOrDeletedMember: clientProjectLinkedMemberI,
    requestDetail: AddUserToProjectRequestI
}
// ADD USER TO PROJECT DISPATCH TYPE
export interface AddUserToProjectPending {
    type: typeof ADD_USER_TO_PROJECT_PENDING
}

export interface AddUserToProjectFail {
    type: typeof ADD_USER_TO_PROJECT_FAIL
    payload: AddUserToProjectResultI
}

export interface AddUserToProjectSuccess {
    type: typeof ADD_USER_TO_PROJECT_SUCCESS,
    payload: AddUserToProjectResultI
}

// INTERFACES FOR THE REQUEST FOR DELETE USER FROM PROJECT
export interface DeleteUserFromProjectRequestI {
    user_id: string,
    project_id: string,
}

// DELETE USER FROM PROJECT DISPATCH TYPE
export interface DeleteUserFromProjectPending {
    type: typeof DELETE_USER_FROM_PROJECT_PENDING
}

export interface DeleteUserFromProjectFail {
    type: typeof DELETE_USER_FROM_PROJECT_FAIL
    payload: AddUserToProjectResultI
}

export interface DeleteUserFromProjectSuccess {
    type: typeof DELETE_USER_FROM_PROJECT_SUCCESS,
    payload: AddUserToProjectResultI
}


// INTERFACES FOR THE SETTING PROJECT SITES
export interface clientProjectSiteLinkedMemberI {
    user_id: string,
}

export interface SettingClientProjectSiteI {
    client_id: string,
    client_name: string,
    linked_members: clientProjectSiteLinkedMemberI[]
    project_id: string,
    project_name: string,
    site_id: string,
    site_name: string
}

export interface FetchSettingClientProjectSitesResultI {
    message: "",
    user_site: SettingClientProjectSiteI[]
}

// FETCH SETTING SITES DISPATCH TYPE
export interface FetchSettingClientProjectSitesPending {
    type: typeof FETCH_SETTING_CLIENT_PROJECT_SITES_PENDING
}

export interface FetchSettingClientProjectSitesFail {
    type: typeof FETCH_SETTING_CLIENT_PROJECT_SITES_FAIL
    payload: FetchSettingClientProjectSitesResultI
}

export interface FetchSettingClientProjectSitesSuccess {
    type: typeof FETCH_SETTING_CLIENT_PROJECT_SITES_SUCCESS,
    payload: FetchSettingClientProjectSitesResultI
}

// INTERFACES FOR THE REQUEST FOR ADD USER TO SITE
export interface AddUserToSiteRequestI {
    user_id: string,
    site_id: string,
}

// INTERFACES FOR THE ADD USER TO SITE
export interface AddUserToSiteResultI {
    message: string,
    user_site: string,
    willBeAddedOrDeletedMember: clientProjectLinkedMemberI,
    requestDetail: AddUserToSiteRequestI
}
// ADD USER TO SITE DISPATCH TYPE
export interface AddUserToSitePending {
    type: typeof ADD_USER_TO_SITE_PENDING
}

export interface AddUserToSiteFail {
    type: typeof ADD_USER_TO_SITE_FAIL
    payload: AddUserToSiteResultI
}

export interface AddUserToSiteSuccess {
    type: typeof ADD_USER_TO_SITE_SUCCESS,
    payload: AddUserToSiteResultI
}

// INTERFACES FOR THE REQUEST FOR DELETE USER FROM SITE
export interface DeleteUserFromSiteRequestI {
    user_id: string,
    site_id: string,
}

// DELETE USER FROM PROJECT DISPATCH TYPE
export interface DeleteUserFromSitePending {
    type: typeof DELETE_USER_FROM_SITE_PENDING
}

export interface DeleteUserFromSiteFail {
    type: typeof DELETE_USER_FROM_SITE_FAIL
    payload: AddUserToSiteResultI
}

export interface DeleteUserFromSiteSuccess {
    type: typeof DELETE_USER_FROM_SITE_SUCCESS,
    payload: AddUserToSiteResultI
}


export type SettingsDispatchTypes = 
    FetchSettingsAndUnitsPending | 
    FetchSettingsAndUnitsFail | 
    FetchSettingsAndUnitsSuccess |
    SaveSettingUnitPending |
    SaveSettingUnitFail |
    SaveSettingUnitSuccess |
    FetchUserRolesPending |
    FetchUserRolesFail |
    FetchUserRolesSuccess |
    FetchSettingUsersPending |
    FetchSettingUsersFail |
    FetchSettingUsersSuccess |
    FetchUserPending |
    FetchUserFail |
    FetchUserSuccess |
    CreateUserPending |
    CreateUserFail |
    CreateUserSuccess |
    EditUserPending |
    EditUserFail |
    EditUserSuccess |
    FetchSettingClientsProjectsPending |
    FetchSettingClientsProjectsFail |
    FetchSettingClientsProjectsSuccess |
    AddUserToProjectPending |
    AddUserToProjectFail |
    AddUserToProjectSuccess |
    DeleteUserFromProjectPending |
    DeleteUserFromProjectFail |
    DeleteUserFromProjectSuccess |
    FetchSettingClientProjectSitesPending |
    FetchSettingClientProjectSitesFail |
    FetchSettingClientProjectSitesSuccess |
    AddUserToSitePending |
    AddUserToSiteFail |
    AddUserToSiteSuccess |
    DeleteUserFromSitePending |
    DeleteUserFromSiteFail |
    DeleteUserFromSiteSuccess



export const fetchSettingsAndUnits = (history: History) => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
    try {
        dispatch({
            type: FETCH_SETTINGS_AND_UNITS_PENDING
        })
        let token: string | null = localStorage.getItem('token');

        if (token) {
            return fetch(`${BaseUrl}/settings_and_units`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token
                },
                method: 'GET',
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.message === "token is invalid!") {
                    history.push('/');
                    localStorage.setItem('token', '');
                }   
                if (json.message) {
                    dispatch({
                        type: FETCH_SETTINGS_AND_UNITS_FAIL,
                        payload: json,
                    })
                } else {
                    dispatch({
                        type: FETCH_SETTINGS_AND_UNITS_SUCCESS,
                        payload: json
                    })    
                }
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_SETTINGS_AND_UNITS_FAIL,
                    payload: error
                })
            });    
        }

    } catch(e) {
        dispatch({
            type: FETCH_SETTINGS_AND_UNITS_FAIL,
            payload: {
                message: "",
                settings_data: [],
            },
        })
    }
};

export const saveSettingUnit = (requestDetail: SaveSettingUnitRequestI, history: History) => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
    try {
        dispatch({
            type: SAVE_SETTINGS_AND_UNITS_PENDING
        })
        let token: string | null = localStorage.getItem('token');
        if (token) {
            return fetch(`${BaseUrl}/settings_and_units`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token
                },
                method: 'POST',
                body: JSON.stringify(requestDetail)
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.message === "token is invalid!") {
                    history.push('/');
                    localStorage.setItem('token', '');
                }   
                if (!json.message) {
                    dispatch({
                        type: SAVE_SETTINGS_AND_UNITS_SUCCESS,
                        payload: json
                    })
                } else {
                    dispatch({
                        type: SAVE_SETTINGS_AND_UNITS_FAIL,
                        payload: json
                    })    
                }
            })
            .catch((error) => {
                dispatch({
                    type: SAVE_SETTINGS_AND_UNITS_FAIL,
                    payload: error
                })
            });    
        }

    } catch(e) {
        dispatch({
            type: SAVE_SETTINGS_AND_UNITS_FAIL,
            payload: {
                message: "",
                user_id: "",
            }
        })
    }
};

export const fetchUserRoles = (history: History) => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
    try {
        dispatch({
            type: FETCH_USER_ROLES_PENDING
        })
        let token: string | null = localStorage.getItem('token');
        if (token) {
            return fetch(`${BaseUrl}/roles`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token
                },
                method: 'GET',
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.message === "token is invalid!") {
                    history.push('/');
                    localStorage.setItem('token', '');
                }   
                if (json.message) {
                    dispatch({
                        type: FETCH_USER_ROLES_FAIL,
                        payload: json,
                    })
                } else {
                    dispatch({
                        type: FETCH_USER_ROLES_SUCCESS,
                        payload: json
                    })    
                }
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_USER_ROLES_FAIL,
                    payload: error
                })
            });    
        }

    } catch(e) {
        dispatch({
            type: FETCH_USER_ROLES_FAIL,
            payload: {
                message: "",
                roles: [],
            },
        })
    }
};

export const fetchSettingUsers = (history: History) => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
    try {
        dispatch({
            type: FETCH_SETTING_USERS_PENDING
        })
        let token: string | null = localStorage.getItem('token');

        if (token) {
            return fetch(`${BaseUrl}/users`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token
                },
                method: 'GET',
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.message === "token is invalid!") {
                    history.push('/');
                    localStorage.setItem('token', '');
                }   
                if (json.message) {
                    dispatch({
                        type: FETCH_SETTING_USERS_FAIL,
                        payload: json,
                    })
                } else {
                    dispatch({
                        type: FETCH_SETTING_USERS_SUCCESS,
                        payload: json
                    })    
                }
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_SETTING_USERS_FAIL,
                    payload: error
                })
            });    
        }

    } catch(e) {
        dispatch({
            type: FETCH_SETTING_USERS_FAIL,
            payload: {
                message: "",
                user_data: [],
            },
        })
    }
};

export const fetchSettingClientsProjects = (history: History) => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
    try {
        dispatch({
            type: FETCH_SETTING_CLIENTS_PROJECTS_PENDING
        })
        let token: string | null = localStorage.getItem('token');

        if (token) {
            return fetch(`${BaseUrl}/client_projects`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token
                },
                method: 'GET',
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.message === "token is invalid!") {
                    history.push('/');
                    localStorage.setItem('token', '');
                }   
                if (json.message) {
                    dispatch({
                        type: FETCH_SETTING_CLIENTS_PROJECTS_FAIL,
                        payload: json,
                    })
                } else {
                    dispatch({
                        type: FETCH_SETTING_CLIENTS_PROJECTS_SUCCESS,
                        payload: json
                    })    
                }
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_SETTING_CLIENTS_PROJECTS_FAIL,
                    payload: error
                })
            });    
        }

    } catch(e) {
        dispatch({
            type: FETCH_SETTING_CLIENTS_PROJECTS_FAIL,
            payload: {
                message: "",
                user_projects: [],
            },
        })
    }
};

export const fetchUser = (requestDetail: FetchUserRequestI, history: History) => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
    try {
        dispatch({
            type: FETCH_USER_PENDING
        })
        let token: string | null = localStorage.getItem('token');
        if (token) {
            let url = `${BaseUrl}/user/${requestDetail.user_id}`;
            return fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token
                },
                method: 'GET',
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.message === "token is invalid!") {
                    history.push('/');
                    localStorage.setItem('token', '');
                }   
                if (json.message) {
                    dispatch({
                        type: FETCH_USER_FAIL,
                        payload: json,
                    })
                } else {
                    dispatch({
                        type: FETCH_USER_SUCCESS,
                        payload: json
                    })    
                }
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_USER_FAIL,
                    payload: error
                })
            });    
        }

    } catch(e) {
        dispatch({
            type: FETCH_USER_FAIL,
            payload: {
                message: "",
                user_data: [],
            },
        })
    }
};

export const createUser = (requestDetail: CreateUserRequestI, history: History) => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
    try {
        dispatch({
            type: CREATE_USER_PENDING
        })
        let token: string | null = localStorage.getItem('token');

        if (token) {
            return fetch(`${BaseUrl}/user`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token
                },
                method: 'POST',
                body: JSON.stringify(requestDetail)
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.message === "token is invalid!") {
                    history.push('/');
                    localStorage.setItem('token', '');
                }                   
                if (json.message) {
                    dispatch({
                        type: CREATE_USER_FAIL,
                        payload: json
                    })
                } else {
                    dispatch({
                        type: CREATE_USER_SUCCESS,
                        payload: json
                    })
                }
            })
            .catch((error) => {
                dispatch({
                    type: CREATE_USER_FAIL,
                    payload: {
                        message: "",
                        user_id: "",                        
                    }
                })
            });    
        }

    } catch(e) {
        dispatch({
            type: CREATE_USER_FAIL,
            payload: {
                message: "",
                user_id: "",
            }
        })
    }
};

export const editUser = (requestDetail: EditUserRequestI, history: History) => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
    try {
        dispatch({
            type: EDIT_USER_PENDING
        })
        let token: string | null = localStorage.getItem('token');

        if (token) {
            return fetch(`${BaseUrl}/user`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token
                },
                method: 'PUT',
                body: JSON.stringify(requestDetail)
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.message === "token is invalid!") {
                    history.push('/');
                    localStorage.setItem('token', '');
                }                   
                if (json.message) {
                    dispatch({
                        type: EDIT_USER_FAIL,
                        payload: json
                    })    
                } else {
                    dispatch({
                        type: EDIT_USER_SUCCESS,
                        payload: json
                    })    
                }
            })
            .catch((error) => {
                dispatch({
                    type: EDIT_USER_FAIL,
                    payload: error
                })
            });    
        }

    } catch(e) {
        dispatch({
            type: EDIT_USER_FAIL,
            payload: e,
        })
    }
};

export const addUserToProject = (requestDetail: AddUserToProjectRequestI, user: clientProjectLinkedMemberI, history: History) => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
    try {
        dispatch({
            type: ADD_USER_TO_PROJECT_PENDING,
            payload: requestDetail
        })
        let token: string | null = localStorage.getItem('token');
        if (token) {
            return fetch(`${BaseUrl}/client_projects`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token
                },
                method: 'POST',
                body: JSON.stringify(requestDetail)
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.message === "token is invalid!") {
                    history.push('/');
                    localStorage.setItem('token', '');
                }                   
                if (json.message) {
                    dispatch({
                        type: ADD_USER_TO_PROJECT_FAIL,
                        payload: json
                    })    
                } else {
                    dispatch({
                        type: ADD_USER_TO_PROJECT_SUCCESS,
                        payload: {...json, willBeAddedOrDeletedMember: user, requestDetail}
                    })    
                }
            })
            .catch((error) => {
                dispatch({
                    type: ADD_USER_TO_PROJECT_FAIL,
                    payload: error
                })
            });    
        }

    } catch(e) {
        dispatch({
            type: ADD_USER_TO_PROJECT_FAIL,
            payload: e,
        })
    }
};

export const deleteUserFromProject = (requestDetail: AddUserToProjectRequestI, user: clientProjectLinkedMemberI, history: History) => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
    try {
        dispatch({
            type: DELETE_USER_FROM_PROJECT_PENDING
        })
        let token: string | null = localStorage.getItem('token');

        if (token) {
            return fetch(`${BaseUrl}/client_projects`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token
                },
                method: 'DELETE',
                body: JSON.stringify(requestDetail)
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.message === "token is invalid!") {
                    history.push('/');
                    localStorage.setItem('token', '');
                }                   
                if (json.message) {
                    dispatch({
                        type: DELETE_USER_FROM_PROJECT_FAIL,
                        payload: json
                    })    
                } else {
                    dispatch({
                        type: DELETE_USER_FROM_PROJECT_SUCCESS,
                        payload: {...json, willBeAddedOrDeletedMember: user, requestDetail}
                    })    
                }
            })
            .catch((error) => {
                dispatch({
                    type: DELETE_USER_FROM_PROJECT_FAIL,
                    payload: error
                })
            });    
        }

    } catch(e) {
        dispatch({
            type: DELETE_USER_FROM_PROJECT_FAIL,
            payload: e,
        })
    }
};

export const fetchSettingClientProjectSites = (history: History) => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
    try {
        dispatch({
            type: FETCH_SETTING_CLIENT_PROJECT_SITES_PENDING
        })
        let token: string | null = localStorage.getItem('token');

        if (token) {
            return fetch(`${BaseUrl}/client_project_sites`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token
                },
                method: 'GET',
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.message === "token is invalid!") {
                    history.push('/');
                    localStorage.setItem('token', '');
                }   
                if (json.message) {
                    dispatch({
                        type: FETCH_SETTING_CLIENT_PROJECT_SITES_FAIL,
                        payload: json,
                    })
                } else {
                    dispatch({
                        type: FETCH_SETTING_CLIENT_PROJECT_SITES_SUCCESS,
                        payload: json
                    })    
                }
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_SETTING_CLIENT_PROJECT_SITES_FAIL,
                    payload: error
                })
            });    
        }

    } catch(e) {
        dispatch({
            type: FETCH_SETTING_CLIENT_PROJECT_SITES_FAIL,
            payload: e,
        })
    }
};

export const addUserToSite = (requestDetail: AddUserToSiteRequestI, user: clientProjectSiteLinkedMemberI, history: History) => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
    try {
        dispatch({
            type: ADD_USER_TO_SITE_PENDING,
            payload: requestDetail
        })
        let token: string | null = localStorage.getItem('token');
        if (token) {
            return fetch(`${BaseUrl}/client_project_sites`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token
                },
                method: 'POST',
                body: JSON.stringify(requestDetail)
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.message === "token is invalid!") {
                    history.push('/');
                    localStorage.setItem('token', '');
                }                   
                if (json.message) {
                    dispatch({
                        type: ADD_USER_TO_SITE_FAIL,
                        payload: json
                    })    
                } else {
                    dispatch({
                        type: ADD_USER_TO_SITE_SUCCESS,
                        payload: {...json, willBeAddedOrDeletedMember: user, requestDetail}
                    })    
                }
            })
            .catch((error) => {
                dispatch({
                    type: ADD_USER_TO_SITE_FAIL,
                    payload: error
                })
            });    
        }

    } catch(e) {
        dispatch({
            type: ADD_USER_TO_SITE_FAIL,
            payload: e,
        })
    }
};

export const deleteUserFromSite = (requestDetail: AddUserToSiteRequestI, user: clientProjectSiteLinkedMemberI, history: History) => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
    try {
        dispatch({
            type: DELETE_USER_FROM_SITE_PENDING
        })
        let token: string | null = localStorage.getItem('token');

        if (token) {
            return fetch(`${BaseUrl}/client_project_sites`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token
                },
                method: 'DELETE',
                body: JSON.stringify(requestDetail)
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.message === "token is invalid!") {
                    history.push('/');
                    localStorage.setItem('token', '');
                }                   
                if (json.message) {
                    dispatch({
                        type: DELETE_USER_FROM_SITE_FAIL,
                        payload: json
                    })    
                } else {
                    dispatch({
                        type: DELETE_USER_FROM_SITE_SUCCESS,
                        payload: {...json, willBeAddedOrDeletedMember: user, requestDetail}
                    })    
                }
            })
            .catch((error) => {
                dispatch({
                    type: DELETE_USER_FROM_SITE_FAIL,
                    payload: error
                })
            });    
        }

    } catch(e) {
        dispatch({
            type: DELETE_USER_FROM_SITE_FAIL,
            payload: e,
        })
    }
};




export interface SettingsStateI {
    pending: boolean,
    failure: boolean,
    success: boolean,
    message: string, 
    settings_units: SettingAndUnitsI[] | null, 
    user_roles: UserRoleI[] | null,
    setting_users: SettingUserI[] | null,
    setting_user: FetchUserI | null,
    setting_clients_projects: SettingClientProjectsI[] | null,
    user_id: string,
    user_project: string,
    mode: string,
    setting_client_project_sites: SettingClientProjectSiteI[] | null,
    user_site: string,
}

const defaultState: SettingsStateI = {
    pending: false,
    failure: false,
    success: true,
    message: "",
    settings_units: null,
    user_roles: null,
    setting_users: null,
    setting_user: null,
    setting_clients_projects: null,
    user_id: "",
    user_project: "",
    mode: "",
    setting_client_project_sites: null,
    user_site: "",
};
  
const accountSettingsReducer = (state: SettingsStateI = defaultState, action: SettingsDispatchTypes) : SettingsStateI => {
    switch (action.type) {
        case FETCH_SETTINGS_AND_UNITS_PENDING:
            return {
                ...state,
                pending: true,
                failure: false,
                success: false,
                message: "",
                user_id: "",
            }
        case FETCH_SETTINGS_AND_UNITS_FAIL:
            return {
                ...state,
                pending: false,
                failure: true,
                message: action.payload.message,
            }
        case FETCH_SETTINGS_AND_UNITS_SUCCESS:
            if (action.payload.settings_data) { 
                return {
                    ...state,
                    pending: false,
                    success: true,
                    settings_units: action.payload.settings_data,
                }    
            } else {
                return {
                    ...state,
                    pending: false,
                    failure: true,
                    message: action.payload.message,
                }                    
            }
        case SAVE_SETTINGS_AND_UNITS_PENDING:
            return {
                ...state,
                pending: true,
                failure: false,
                success: false,
                message: "",
                user_id: "",
                mode: "SAVE_SETTINGS_AND_UNITS"
            }
        case SAVE_SETTINGS_AND_UNITS_FAIL:
            return {
                ...state,
                pending: false,
                failure: true,
                message: "",
            }
        case SAVE_SETTINGS_AND_UNITS_SUCCESS:
            return {
                ...state,
                pending: false,
                success: true,
                user_id: action.payload.user_id
            }        
        case FETCH_SETTING_USERS_PENDING:
            return {
                ...state,
                pending: true,
                failure: false,
                success: false,
                message: "",
                user_id: "",
            }
        case FETCH_SETTING_USERS_SUCCESS:
            if (action.payload.user_data) { 
                return {
                    ...state,
                    pending: false,
                    success: true,
                    setting_users: action.payload.user_data,
                }    
            } else {
                return {
                    ...state,
                    pending: false,
                    failure: true,
                    message: action.payload.message,
                }                    
            }             
        case FETCH_SETTING_USERS_FAIL:
            return {
                ...state,
                pending: false,
                failure: true,
                message: action.payload.message,
            }
        case FETCH_USER_ROLES_PENDING:
            return {
                ...state,
                pending: true,
                failure: false,
                success: false,
                message: "",
                user_id: "",
            }
        case FETCH_USER_ROLES_SUCCESS:
            if (action.payload.roles) { 
                return {
                    ...state,
                    pending: false,
                    success: true,
                    user_roles: action.payload.roles,
                }    
            } else {
                return {
                    ...state,
                    pending: false,
                    failure: true,
                    message: action.payload.message,
                }                    
            }             
        case FETCH_USER_ROLES_FAIL:
            return {
                ...state,
                pending: false,
                failure: true,
                message: action.payload.message,
            }
        case FETCH_USER_PENDING:
            return {
                ...state,
                pending: true,
                failure: false,
                success: false,
                message: "",
                user_id: "",
            }
        case FETCH_USER_FAIL:
            return {
                ...state,
                pending: false,
                failure: true,
            }
        case FETCH_USER_SUCCESS:
            return {
                ...state,
                pending: false,
                success: true,
                setting_user: action.payload.user_data[0] ? {
                    created_by: action.payload.user_data[0].created_by,
                    email: action.payload.user_data[0].email,
                    forename: action.payload.user_data[0].forename,
                    is_admin: action.payload.user_data[0].is_admin,
                    surname: action.payload.user_data[0].surname,
                    user_id: action.payload.user_data[0].user_id,
                    image_name: action.payload.user_data[0].image_name,
                    telephone: action.payload.user_data[0].telephone,
                    role_id: action.payload.user_data[0].role_id,
                    role_name: action.payload.user_data[0].role_name,
                }: null
            }                      
        case CREATE_USER_PENDING:
            return {
                ...state,
                pending: true,
                failure: false,
                success: false,
                message: "",
                user_id: "",
                mode: "CREATE_USER"
            }
        case CREATE_USER_FAIL:
            return {
                ...state,
                pending: false,
                failure: true,
            }
        case CREATE_USER_SUCCESS:
            return {
                ...state,
                pending: false,
                success: true,
                user_id: action.payload.user_id
            }      
        case EDIT_USER_PENDING:
            return {
                ...state,
                pending: true,
                failure: false,
                success: false,
                message: "",
                user_id: "",
                mode: "EDIT_USER"
            }
        case EDIT_USER_FAIL:
            return {
                ...state,
                pending: false,
                failure: true,
            }
        case EDIT_USER_SUCCESS:
            return {
                ...state,
                pending: false,
                success: true,
                user_id: action.payload.user_id,
                setting_user: null
            }                    
         
        case FETCH_SETTING_CLIENTS_PROJECTS_PENDING:
            return {
                ...state,
                pending: true,
                failure: false,
                success: false,
                message: "",
                user_id: "",
            }
        case FETCH_SETTING_CLIENTS_PROJECTS_FAIL:
            return {
                ...state,
                pending: false,
                failure: true,
                message: action.payload.message,
            }
        case FETCH_SETTING_CLIENTS_PROJECTS_SUCCESS:
            if (action.payload.user_projects) { 
                return {
                    ...state,
                    pending: false,
                    success: true,
                    setting_clients_projects: action.payload.user_projects,
                }    
            } else {
                return {
                    ...state,
                    pending: false,
                    failure: true,
                    message: action.payload.message,
                }                    
            }       
        case ADD_USER_TO_PROJECT_PENDING:
            return {
                ...state,
                pending: true,
                failure: false,
                success: false,
                message: "",
                user_id: "",
                mode: "ADD_USER_TO_PROJECT"
            }
        case ADD_USER_TO_PROJECT_FAIL:
            return {
                ...state,
                pending: false,
                failure: true,
            }
        case ADD_USER_TO_PROJECT_SUCCESS:
            if (action.payload.user_project === "Done") {
                if (state.setting_clients_projects) {
                    return {
                        ...state,
                        setting_clients_projects: state.setting_clients_projects.map((client) => {
                            return {
                                ...client,
                                projects:client.projects.map((project) => {
                                    if (project.project_id === action.payload.requestDetail.project_id) {
                                        let newLinekedMemberArray = [action.payload.willBeAddedOrDeletedMember];
                                        return {
                                            ...project,
                                            linked_members: project.linked_members.concat(newLinekedMemberArray)
                                        }                                
                                    } else {
                                        return project
                                    }
                                })
                            }
                        }),
                        pending: false,
                        success: true,
                        user_project: action.payload.user_project,
                    }     
    
                } else {
                    return {
                        ...state,
                        pending: false,
                        success: true,
                        user_project: action.payload.user_project,
                    }                      
                }
            } else {
                return {
                    ...state,
                    pending: false,
                    success: true,
                    user_project: action.payload.user_project,
                }                   
            }
            
        case DELETE_USER_FROM_PROJECT_PENDING:
            return {
                ...state,
                pending: true,
                failure: false,
                success: false,
                message: "",
                user_id: "",
                mode: "DELETE_USER_FROM_PROJECT"
            }
        case DELETE_USER_FROM_PROJECT_FAIL:
            return {
                ...state,
                pending: false,
                failure: true,
            }
        case DELETE_USER_FROM_PROJECT_SUCCESS:
            if (action.payload.user_project === "Done") {
                if (state.setting_clients_projects) {
                    return {
                        ...state,
                        setting_clients_projects: state.setting_clients_projects.map((client) => {
                            return {
                                ...client,
                                projects:client.projects.map((project) => {
                                    if (project.project_id === action.payload.requestDetail.project_id) {
                                        return {
                                            ...project,
                                            linked_members: project.linked_members.filter((member) => {
                                                return  member.user_id !== action.payload.requestDetail.user_id
                                            })
                                        }                                
                                    } else {
                                        return project
                                    }
                                })
                            }
                        }),
                        pending: false,
                        success: true,
                        user_project: action.payload.user_project,
                    }     
    
                } else {
                    return {
                        ...state,
                        pending: false,
                        success: true,
                        user_project: action.payload.user_project,
                    }                      
                }
            } else {
                return {
                    ...state,
                    pending: false,
                    success: true,
                    user_project: action.payload.user_project,
                }                   
            }             
        case ADD_USER_TO_SITE_PENDING:
            return {
                ...state,
                pending: true,
                failure: false,
                success: false,
                message: "",
                user_id: "",
                mode: "ADD_USER_TO_SITE"
            }
        case ADD_USER_TO_SITE_FAIL:
            return {
                ...state,
                pending: false,
                failure: true,
            }
        case ADD_USER_TO_SITE_SUCCESS:
            if (action.payload.user_site === "Done") {
                if (state.setting_client_project_sites) {
                    return {
                        ...state,
                        setting_client_project_sites: state.setting_client_project_sites.map((site) => {
                            if (site.site_id === action.payload.requestDetail.site_id) {
                                return {
                                    ...site,
                                    linked_members: site.linked_members.concat([action.payload.willBeAddedOrDeletedMember])
                                }
                            } else {
                                return site
                            }
                        }),
                        pending: false,
                        success: true,
                        user_site: action.payload.user_site,
                    }     
    
                } else {
                    return {
                        ...state,
                        pending: false,
                        success: true,
                        user_site: action.payload.user_site,
                    }                      
                }
            } else {
                return {
                    ...state,
                    pending: false,
                    success: true,
                    user_site: action.payload.user_site,
                }                   
            }
            
        case DELETE_USER_FROM_SITE_PENDING:
            return {
                ...state,
                pending: true,
                failure: false,
                success: false,
                message: "",
                user_id: "",
                mode: "DELETE_USER_FROM_SITE"
            }
        case DELETE_USER_FROM_SITE_FAIL:
            return {
                ...state,
                pending: false,
                failure: true,
            }
        case DELETE_USER_FROM_SITE_SUCCESS:
            if (action.payload.user_site === "Done") {
                if (state.setting_client_project_sites) {
                    return {
                        ...state,
                        setting_client_project_sites: state.setting_client_project_sites.map((site) => {
                            if (site.site_id === action.payload.requestDetail.site_id) {
                                return {
                                    ...site,
                                    linked_members: site.linked_members.filter((member) => {
                                        return  member.user_id !== action.payload.requestDetail.user_id
                                    })
                                }
                            } else {
                                return site;
                            }
                        }),
                        pending: false,
                        success: true,
                        user_site: action.payload.user_site,
                    }     
    
                } else {
                    return {
                        ...state,
                        pending: false,
                        success: true,
                        user_site: action.payload.user_site,
                    }                      
                }
            } else {
                return {
                    ...state,
                    pending: false,
                    success: true,
                    user_site: action.payload.user_site,
                }                   
            }                                     
        case FETCH_SETTING_CLIENT_PROJECT_SITES_PENDING:
            return {
                ...state,
                pending: true,
                failure: false,
                success: false,
                message: "",
                user_id: "",
            }
        case FETCH_SETTING_CLIENT_PROJECT_SITES_FAIL:
            return {
                ...state,
                pending: false,
                failure: true,
                message: action.payload.message,
            }
        case FETCH_SETTING_CLIENT_PROJECT_SITES_SUCCESS:
            if (action.payload.user_site) { 
                return {
                    ...state,
                    pending: false,
                    success: true,
                    setting_client_project_sites: action.payload.user_site,
                }    
            } else {
                return {
                    ...state,
                    pending: false,
                    failure: true,
                    message: action.payload.message,
                }                    
            }                  
        default:
            return state
    }
};
  
export default accountSettingsReducer