import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router";
import {
    Button,
    FormGroup, Form, Input, FormText, Label,
    Row, Col,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Card, CardImg, CardBody, CardFooter
} from "reactstrap";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import DefaultAnimalImage from "../../assets/images/default-animal-image.jpg";
import Select from 'react-select';
import { ValueType } from "react-select/src/types";
import { RootState } from '../../modules';
import { ProjectsManagementStateI, fetchAnimalList, SiteI } from '../../modules/projectsManagementModule';
import { Animal } from '../animals/animalModal';
import { BaseUrl } from "../../constant/constant";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';


type ErrorProps = {
    isError: boolean | null,
    message: string,
}

export type ExtendedAnimalProps = Animal & {site_id: string}

type Props = {
    onToggle: () => void,
    isOpen: boolean,
    animalDetails: null | ExtendedAnimalProps
}

type SelectSiteOptionProps = {
    site_id: string,
    site_name: string,
}

type SelectAnimalTypeOptionProps = {
    animal_type_id: number,
    animal_type: string,
    animal_breed: string,
}

const EditAnimalModal = ({ onToggle, isOpen, animalDetails }: Props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { animalTypes, sites }: ProjectsManagementStateI = useSelector((state: RootState) => state.projectsManagementReducer);

    useEffect(() => {
        setAnimalTag(animalDetails? animalDetails.animal_tag: "");
        setAnimalDob(animalDetails? new Date(moment(animalDetails.animal_dob, "DD-MM-YYYY HH-mm-ss").format()): new Date());
        setAnimalID(animalDetails? animalDetails.animal_id: "");
        setAnimalImage(animalDetails? animalDetails.image_name? animalDetails.image_name: DefaultAnimalImage: DefaultAnimalImage);
        if (animalDetails) {
            sites?.find((site) => {
                if (site.site_id === animalDetails.site_id) {
                    setSiteOption({site_id: site.site_id, site_name: site.site_name});
                    return true
                }
                return false
            })
        }
        if (animalDetails) {
            animalTypes?.find((type) => {
                if (type.animal_type_id === animalDetails.animal_type_id) {
                    setAnimalTypeOption({animal_type_id: type.animal_type_id, animal_type: type.animal_type, animal_breed: type.animal_breed});
                    return true
                }
                return false
            })
        }      
        setAnimalLost(animalDetails? animalDetails.animal_lost: false);
        setAnimalDeceased(animalDetails? animalDetails.animal_deceased: false);
        setAnimalSold(animalDetails? animalDetails.animal_sold: false);
        setAnimalAbattoir(animalDetails? animalDetails.animal_abattoir: false);
    }, [animalDetails, animalTypes, sites])

    // input field variable 
    const [animalID, setAnimalID] = useState("");
    const [animalTag, setAnimalTag] = useState("");
    const [animalDob, setAnimalDob] = useState(new Date());
    const [animalImage, setAnimalImage] = useState(DefaultAnimalImage);
    const [selectedSiteOption, setSiteOption] = useState<ValueType<SelectSiteOptionProps, false> | null>(null);
    const [selectedAnimalTypeOption, setAnimalTypeOption] = useState<ValueType<SelectAnimalTypeOptionProps, false> | null>(null);
    const [cropper, setCropper] = useState<any>();

    const [animalLost, setAnimalLost] = useState(false);
    const [animalDeceased, setAnimalDeceased] = useState(false);
    const [animalSold, setAnimalSold] = useState(false);
    const [animalAbattoir, setAnimalAbattoir] = useState(false);
    // set error 
    const [animalTagError, setAnimalTagError] = useState<ErrorProps>({isError: null, message: ""});
    const [selectedSiteOptionError, setSelectSiteOptionError] = useState<ErrorProps>({isError: null, message: ""});
    const [selectedAnimalTypeOptionError, setSelectAnimalTypeOptionError] = useState<ErrorProps>({isError: null, message: ""});
    
    const [isOpenImageCropper, setOpenImageCropper] = useState(false);

    const handleChangeAnimalTag = (value: string) => {
        setAnimalTag(value);
        if (animalTagError.isError) {
            setAnimalTagError({isError: false, message: ""});
        }
    }
    const handleChangeCheckFields = (value: boolean, name: string) => {
        switch (name) {
            case "animalDeceased":
                setAnimalDeceased(value);
                break;
            case "animalSold":
                setAnimalSold(value);
                break;
            case "animalAbattoir":
                setAnimalAbattoir(value);
                break;
            case "animalLost":
                setAnimalLost(value);
                break;
                                
            default:
                break;
        }
    }  

    const handleChangeSiteOption = (newOption: ValueType<SelectSiteOptionProps, false> | null) => {
        setSiteOption(newOption);
        if (selectedSiteOptionError.isError) {
            setSelectSiteOptionError({isError: false, message: ""});
        }
    }

    const handleChangeAnimalTypeOption = (newOption: ValueType<SelectAnimalTypeOptionProps, false> | null) => {
        setAnimalTypeOption(newOption);
        if (selectedAnimalTypeOptionError.isError) {
            setSelectAnimalTypeOptionError({isError: false, message: ""});
        }
    }

    const handleOpenImageCropper = (e: any) => {
        e.preventDefault();
        setOpenImageCropper(true);

        let files;
        if (e.dataTransfer) {
              files = e.dataTransfer.files;
        } else if (e.target) {
              files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setAnimalImage(reader.result as any);
        };
        reader.readAsDataURL(files[0]);
      };
    
    const handleGetCropData = () => {
        if (typeof cropper !== "undefined") {
            setAnimalImage(cropper.getCroppedCanvas().toDataURL());
        }
        setOpenImageCropper(false);
    };    

    const handleChangeDob = (value: Date) => {
        setAnimalDob(value);
    }

    const handleSaveAnimal = async() => {
        let returnFlag = false;
        if (!animalDetails && animalTag === "") {
            setAnimalTagError({isError: true, message: "Please enter this field."});
            returnFlag = true;
        }

        if (!animalDetails && !selectedAnimalTypeOption) {
            setSelectAnimalTypeOptionError({isError: true, message: "Please enter this field."});
            returnFlag = true;
        }

        if (!animalDetails && !selectedSiteOption) {
            setSelectSiteOptionError({isError: true, message: "Please enter this field."});
            returnFlag = true;
        }
        


        if (returnFlag) {
            return 
        }

        onToggle();
        
        if (animalDetails) {
            let requestDetail = {
                animal_id: animalID,
                animal_tag: animalTag,
                dob: moment(animalDob.toString()).format("DD-MM-YYYY HH-mm-ss"),
                site_id: selectedSiteOption?.site_id,
                image_name: animalImage,
                lost: animalLost,
                deceased: animalDeceased,
                sold: animalSold,
                abattoir: animalAbattoir,                
                // animal_type_id: selectedAnimalTypeOption?.animal_type_id
            };
    
            let token: string | null = localStorage.getItem('token');
            if (token) {
                let res = await fetch(`${BaseUrl}/animal`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token
                    },
                    method: 'PUT',
                    body: JSON.stringify(requestDetail)
                })
                let json = await res.json();
                if (json.message === "token is invalid!") {
                    history.push("/");
                    localStorage.setItem('token', '');
                } 
                if (!json.message) {
                    dispatch(fetchAnimalList(history));                
                }
            }
        } else {
            
            let requestDetail = [{
                site_id: selectedSiteOption?.site_id,
                animal_tag: animalTag,
                dob: moment().format("DD-MM-YYYY HH-mm-ss"),
                animal_type_id: selectedAnimalTypeOption?.animal_type_id,
                image_name: animalImage
            }];
            let token: string | null = localStorage.getItem('token');
            if (token) {
                let res = await fetch(`${BaseUrl}/animal`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token
                    },
                    method: 'POST',
                    body: JSON.stringify(requestDetail)
                })
                let json = await res.json();
                if (json.message === "token is invalid!") {
                    history.push("/");
                    localStorage.setItem('token', '');
                } 
                
                if (!json.message) {
                    dispatch(fetchAnimalList(history));                
                }
            }
        }

        initState();
    }

    const handleToggleModal = () => {
        onToggle();
        initState();
    }


    const initState = () => {
        setAnimalID('');
        setAnimalTag('');
        setAnimalDob(new Date());
        setAnimalImage(DefaultAnimalImage);
        setCropper(undefined);
        setSiteOption(null);
        setAnimalTypeOption(null);
        setAnimalLost(false);
        setAnimalDeceased(false);
        setAnimalSold(false);
        setAnimalAbattoir(false);

        setAnimalTagError({isError: null, message: ""});
        setSelectSiteOptionError({isError: null, message: ""});
        setSelectAnimalTypeOptionError({isError: null, message: ""});
        setOpenImageCropper(false);
    }

    const getStyleForReactSelect = (value: ErrorProps) => {
        const style = {
            control: (base: any, state: any) => ({
              ...base,
                // state.isFocused can display different borderColor if you need it
                borderColor: state.isFocused ?
                    '#ddd' : value.isError ?
                    'red' : '#ddd',
            })
        }        
        return style
    }

    return (
        <Modal isOpen={isOpen} toggle={handleToggleModal} >
        <ModalHeader toggle={handleToggleModal}>{animalDetails ? "Edit A Livestock" : "Create A Livestock"}</ModalHeader>
        <ModalBody>
            <Form autoComplete="off">
                <Row>
                    <Col sm="12">
                        <Card>
                            {
                                !isOpenImageCropper?                            
                                <CardImg top width="100%" src={animalImage? animalImage : DefaultAnimalImage} alt="Animal Image" />:
                                <CardBody>
                                    <Cropper
                                        style={{ height: 400, width: "100%" }}
                                        zoomTo={0.5}
                                        initialAspectRatio={2}
                                        src={animalImage}
                                        viewMode={1}
                                        minCropBoxHeight={10}
                                        minCropBoxWidth={10}
                                        background={false}
                                        responsive={true}
                                        autoCropArea={1}
                                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                        onInitialized={(instance: any) => {
                                            setCropper(instance);
                                        }}
                                        guides={true}  
                                    />                                                                  
                                </CardBody>
}
                            <CardFooter>
                                {
                                    isOpenImageCropper?
                                    <Button color="primary" className="float-right" onClick={handleGetCropData}>Save</Button>:
                                    <Input type="file" onChange={handleOpenImageCropper} accept="image/*" />                                
                                }
                            </CardFooter>
                        </Card>
                    </Col>      
                    <Col sm="6">
                        <FormGroup >
                            <label
                                className="form-control-label"
                                htmlFor="selectSiteOption"
                            >
                                Site
                            </label>
                            <Select
                                id="selectSiteOption"
                                value={selectedSiteOption}
                                options={sites? sites.filter((site: SiteI) => {return site.site_type_id.toString() === "2"}): []}
                                getOptionLabel ={(option)=>option.site_name}
                                getOptionValue ={(option)=>option.site_id}
                                onChange={(e) => handleChangeSiteOption(e)}
                                placeholder="Select a Site"
                                styles={getStyleForReactSelect(selectedSiteOptionError)}
                            />         
                        </FormGroup>                           
                    </Col>   
                    {
                        !animalDetails && 
                        <Col sm="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="selectAnimalTypeOption"
                                >
                                    Animal Type
                                </label>                                    
                                <Select
                                    id="selectAnimalTypeOption"
                                    value={selectedAnimalTypeOption}
                                    options={animalTypes? animalTypes: []}
                                    getOptionLabel ={(option)=>{return (option.animal_type + "-" +option.animal_breed)}}
                                    getOptionValue ={(option)=>option.animal_type_id.toString()}
                                    onChange={(e) => handleChangeAnimalTypeOption(e)}
                                    placeholder="Select a Animal Type"
                                    styles={getStyleForReactSelect(selectedAnimalTypeOptionError)}
                                />                                    
                            </FormGroup> 
                        </Col>                                       
                    }   
                    <Col sm="6">
                        <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-animal-tag"
                            >
                                Tag
                            </label>
                            <Input
                                className="form-control-alternative"
                                id="input-animal-tag"
                                type="text"
                                value={animalTag}
                                onChange={(e)=>{handleChangeAnimalTag(e.target.value)}}
                                invalid={(animalTagError.isError === true)}
                            />
                        </FormGroup>
                        <FormText>{animalTagError.isError? animalTagError.message: ""}</FormText>
                    </Col>
                    { animalDetails &&         
                        <>                                 
                            <Col sm="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label input-animal-dob"
                                        htmlFor="input-animal-dob"
                                    >
                                        Dob
                                    </label>    
                                                                
                                    <DatePicker
                                        id="input-animal-dob"
                                        onChange={handleChangeDob}
                                        selected={animalDob}
                                        dateFormat="MMMM d, yyyy h:mm aa"    
                                        showTimeSelect                                    
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="6">
                                <FormGroup>
                                </FormGroup>
                            </Col>                                                            
                            <Col sm="6">
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" id="input-site-lost" name="animalLost" checked={animalLost} onChange={(e)=>{handleChangeCheckFields(e.target.checked, e.target.name)}}/> Lost
                                    </Label>                                    
                                </FormGroup>
                            </Col>
                            <Col sm="6">
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" id="input-site-deceased" name="animalDeceased" checked={animalDeceased} onChange={(e)=>{handleChangeCheckFields(e.target.checked, e.target.name)}}/> Deceased
                                    </Label> 
                                </FormGroup>
                            </Col>       
                            <Col sm="6">
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" id="input-site-sold" name="animalSold" checked={animalSold} onChange={(e)=>{handleChangeCheckFields(e.target.checked, e.target.name)}}/> Sold
                                    </Label> 
                                </FormGroup>
                            </Col>
                            <Col sm="6">
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" id="input-site-abattoir" name="animalAbattoir" checked={animalAbattoir} onChange={(e)=>{handleChangeCheckFields(e.target.checked, e.target.name)}}/> Abattoir
                                    </Label>
                                </FormGroup>
                            </Col>                                                 
                        </>
                    }
                </Row>
            </Form>
        </ModalBody>
        <ModalFooter>
            <Button color="primary" onClick={handleSaveAnimal}>Save</Button>
            <Button color="secondary" onClick={handleToggleModal}>Cancel</Button>
        </ModalFooter>
    </Modal> 
)
}

export default EditAnimalModal;