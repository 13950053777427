import { useEffect, useState, useCallback } from "react";
import { useRouteMatch, useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { 
  Card, CardHeader, CardBody, CardTitle, CardSubtitle, CardText, 
  Table, 
  Button, 
  Form, FormGroup, 
  Col } from 'reactstrap';

import GoogleMapReact from 'google-map-react';
import apiKeys from "../../constant/apiKeys";
import Select from 'react-select';
import { ValueType } from "react-select/src/types";

import NodeMarker from "../../components/markers/nodeMarker";
import ArableModal, { Arable } from "../../components/arables/arableModal";
import VideoPlayerModal from "../../components/modal/videoPlayerModal";
import LoadingGif from "../../assets/images/loading.gif";
import { BaseUrl, NodeMarkerBGColor, NodeMarkerBDColor } from "../../constant/constant";
import googleMaps from "../../assets/images/google-map.jpg";


const functionStore = new Set();

interface ArableProjectI {
    project_id: string,
    project_name: string,
}
interface ProjectSiteI {
    site_id: string,
    site_name: string,
    polygon: {
        lats: number[],
        longs: number[]
    }
}
interface SiteNodesI {
    site_id: string,
    project_id: string,
    site_name: string,
    nodes: {
        lat: number,
        lon: number,
        node_id: string,
        node_type_id: number,
        node_type_name: string,
        datetime: string,
        humidity: string,
        light: string,
        ph: string,
        soil_temperature: string,       
        node_feed_url: string | null, 
    }[],
    arables: ArableI[],
    weather: {
      city_name: string,
      description: string,
      humidity: number,
      temp: number,
      weather_icon: string,
      wmu: string,
    },
}
type selectOption = {
    label: string,
    value: string,
}

export interface ArableI {
    crop_id: string,
    crop_type: string,
    crop_type_id: number,
    crop_variety: string,
    ended: string,
    image_name: string,
    site_id: string,
    started: string,
}

const Arables = () => {
    const history = useHistory();
    const projectMatch = useRouteMatch("/arable/project/:id");
    const siteMatch = useRouteMatch("/arable/site/:id");
    const eachArable = useRouteMatch("arable/:id");
    let params: {id: string} = useParams();

    const [arableProjects, setArableProjects] = useState<ArableProjectI[] | []>([]);
    const [projectSites, setProjectSites] = useState<ProjectSiteI[] | null>(null);
    const [selectedProjectID, setProject] = useState<string | null>(null);
    const [siteNodes, setSiteNodes] = useState<SiteNodesI | null>(null);
    const [selectedSiteID, setSite] = useState<string | null>(null);
    const [loadingSiteNodesStatus, setLoadingSiteNodesStatus] = useState(0); // 1: pending, 2: success, 3: failure, 0: initial

    const [selectedProjectOption, setProjectOption] = useState<ValueType<selectOption, false> | null>(null);
    const [selectedSiteOption, setSiteOption] = useState<ValueType<selectOption, false> | null>(null);
    const [isOpenArableModal, setOpenArableModal] = useState(false);
    const [arableForModal, setArableForModal] = useState<Arable | null>(null);
    const [isOpenVideoPlayerModal, setOpenVideoPlayerModal] = useState(false);
    const [selectedNodeFeedUrl, setNodeVideoUrl] = useState<string>("");

    const fetchProjects = useCallback(
        async () => {
            let token: string | null = localStorage.getItem('token');
            if (token) {
                fetch(`${BaseUrl}/arables`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token
                    },
                    method: 'GET',
                })
                .then((res) => res.json())
                .then((json) => {
                    if (json.message === "token is invalid!") {
                        history.push('/');
                        localStorage.setItem('token', '');
                    }   
                    if (json.message) {
                    } else {
                        setArableProjects(json.projects);
                    }
                })
                .catch((error) => {
                });    
            }
        },
        [history] 
    );  
    const fetchArableProjectSitesCB = useCallback(
        async (projectID) => {
            let token: string | null = localStorage.getItem('token');
            if (token) {
                let res = await fetch(`${BaseUrl}/arable/project/${projectID}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token
                    },
                    method: 'GET',
                })
                let json = await res.json();
                if (json.message === "token is invalid!") {
                    history.push("/");
                    localStorage.setItem('token', '');
                }  
                if (json.sites && arableProjects) {
                    setProjectSites(json.sites);
                    // in case url is /arable/project/id, select project option and load it's sites
                    if (projectMatch?.isExact) {
                        // select project option
                        arableProjects.filter((project) => {
                            if (project.project_id === params.id) {
                                setProjectOption({ value: project.project_id, label: project.project_name});
                                handleReDrawMapWithProject(params.id);
                                return false;
                            }
                            return true;
                        })
                    }                
                    return {
                        sites: json.sites,
                        success: true,
                    }
                }
            }
        },
        [params?.id, history, projectMatch?.isExact, arableProjects]
    );
    const fetchSiteNodesCB = useCallback(        
        async () => {
            let token: string | null = localStorage.getItem('token');
            if (token) {
                setLoadingSiteNodesStatus(1);
                let res = await fetch(`${BaseUrl}/arable/site/${params.id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token
                    },
                    method: 'GET',
                })
                let json = await res.json();
                if (json.message) {
                    setLoadingSiteNodesStatus(3);
                }
                if (json.message === "token is invalid!") {
                    history.push("/");
                    localStorage.setItem('token', '');
                }  
                if (json.arables) { 
                    setLoadingSiteNodesStatus(2);
                    // in case url is /arable/site/id, load it's project
                    if (siteMatch?.isExact && json.arables[0] && (json.arables[0].site_id === params.id) && arableProjects) {
                        handleReDrawMapWithSite(params.id);
                        arableProjects.filter((project) => {
                            if (project.project_id === json.arables[0].project_id) {
                                setProjectOption({ value: project.project_id, label: project.project_name });
                                fetchArableProjectSitesCB(project.project_id); 
                                return true
                            }
                            return false
                        })
                        setSiteOption({ value: json.arables[0].site_id, label: json.arables[0].site_name });
                    }
    
                    setSiteNodes(json.arables[0]);
                    return {
                        nodes: json.arables,
                        success: true,
                    }
                }
            }
    
        },
        [params?.id, history, siteMatch?.isExact, arableProjects, fetchArableProjectSitesCB]
    );    
    functionStore.add(fetchArableProjectSitesCB);
    functionStore.add(fetchSiteNodesCB);
    functionStore.add(fetchProjects);

    const fetchArable = async (arableID: string) => {
        let token: string | null = localStorage.getItem('token');
        if (token) {
            let res = await fetch(`${BaseUrl}/arable/${arableID}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token
                },
                method: 'GET',
            })
            let json = await res.json();
            if (json.message === "token is invalid!") {
                history.push("/");
                localStorage.setItem('token', '');
            }  
            if (json.arable) {
                setArableForModal(json.arable[0]);
            }
        }
    }  
    // fetch projects and nodes and arables of site according to history
    useEffect(() => {
        // fetch projects 
        fetchProjects();
    }, [fetchProjects]);

    useEffect(() => {
        // in case url is /arable/project/id, fetch select project option and load it's sites
        if (projectMatch?.isExact) {
            fetchArableProjectSitesCB(params.id);
        }        
    }, [projectMatch?.isExact, params.id, fetchArableProjectSitesCB]);

    useEffect(() => {
        // in case url is /arable/site/id, fetch select site option and load it's arables and nodes 
        if (siteMatch?.isExact) {
            fetchSiteNodesCB();
        }       
    }, [siteMatch?.isExact, params.id, fetchSiteNodesCB])

    // when change project option, redraw map with project
    const handleReDrawMapWithProject = (projectID: string) => {
        setProject(null);
        let setProjectTimeOut = setTimeout(
            function(){ 
                clearTimeout(setProjectTimeOut);
                setProject(projectID); 
            }, 
            1
        );
    }

    // when change site option, redraw map with site
    const handleReDrawMapWithSite = (siteID: string) => {
        setSite(null);
        setProject(null);
        let setSiteTimeOut = setTimeout(
            function(){ 
                clearTimeout(setSiteTimeOut);
                setSite(siteID); 
            }, 
            1
        );
    }

    const createPolygonOnMap = (polygon: any, maps: any, map: any) => {
        const arableSitePolygonCoords = polygon.lats.map((lat: string, index: number) => {
            return {lat: parseFloat(lat), lng: parseFloat(polygon.longs[index])}
        });
        const arableCropSitePolygon = new maps.Polygon({
            paths: arableSitePolygonCoords,
            strokeColor: "#0000FF",
            strokeOpacity: 0.8,
            strokeWeight: 3,
            fillColor: "#0000FF",
            fillOpacity: 0.35,                                           
        })
        arableCropSitePolygon.setMap(map);
    }
    // when change projectID, Google Map act api function, 
    const handleApiLoaded = async (map: any, maps: any) => {
        map.mapTypeId = "terrain";
        if (selectedProjectID && projectSites) {
            projectSites.forEach((site) => {
                createPolygonOnMap(site.polygon, maps, map); 
            })
        }

        if (selectedSiteID && projectSites) {
            projectSites.forEach((site) => {
                if (selectedSiteID === site.site_id) {
                    createPolygonOnMap(site.polygon, maps, map); 
                    return true;
                }
                return false;
            })
        }
    }    

    const handleChangeProjectOption = (newOption: ValueType<selectOption, false> | null) => {
        setProjectOption(newOption);
        if (newOption) {
            history.push(`/arable/project/${newOption.value}`);
        }
    }

    const handleChangeSiteOption = (newOption: ValueType<selectOption, false> | null) => {
        setSiteOption(newOption);
        if (newOption) {
            history.push(`/arable/site/${newOption.value}`);
        }
    }    

    const handleToggleArableDetailModal = (isOpenAddNodeModal: boolean, selectedArable: ArableI | null) => {
        setOpenArableModal(!isOpenArableModal);
        if (selectedArable) {
            fetchArable(selectedArable.crop_id);
        }
    }

    const handleChangeVisibleVideoPlayerModal = () => {
        setOpenVideoPlayerModal(!isOpenVideoPlayerModal);
    }

    const handleShowNodeVideo = (url: string | null) => {
        if (url !== null) {
            setNodeVideoUrl(url);
        }
        setOpenVideoPlayerModal(true);
    }

    let projectOptions:selectOption[] = [];
    if (arableProjects) {
        projectOptions = arableProjects.map((project: ArableProjectI) => {
            return {
                value: project.project_id,
                label: project.project_name
            }
        });
    }
    let siteOptions:selectOption[] = [];
    if (projectSites) {
        siteOptions = projectSites.map((site: ProjectSiteI) => {
            return {
                value: site.site_id,
                label: site.site_name
            }
        });
    }
    return (
        <>
          <p className="h1">Arable</p>
          <hr />
            {/*--------------------------------------------------------------------------------*/
            /* Google map                                                  */
            /*--------------------------------------------------------------------------------*/}
            <Card>
                {/*<CardHeader className="d-flex justify-content-between align-items-center">
                    Google Map
                </CardHeader>*/}
                <CardBody>
                    <Form>
                        <FormGroup row>
                            <Col sm={6}>
                                <Select
                                    value={selectedProjectOption}
                                    onChange={(e) => handleChangeProjectOption(e)}
                                    options={projectOptions}
                                    placeholder="Select a Project"
                                />                               
                            </Col>      
                            <Col sm={6}>
                                <Select
                                    value={selectedSiteOption}
                                    onChange={(e) => handleChangeSiteOption(e)}
                                    options={siteOptions}
                                    placeholder="Select a Site"
                                />                               
                            </Col>      
                        </FormGroup> 
                    </Form>                                                         
                    <div style={{height: '50vh'}} className="tooltipBoundary">
                        { ((selectedProjectID !== null && projectSites !== null) || (selectedSiteID !== null && projectSites !== null)) ?
                            <>                            
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: apiKeys.googleMapApiKey }}
                                    defaultZoom={5}
                                    defaultCenter={{lat: 7.395985, lng: 3.824255}} // Nigeria
                                    yesIWantToUseGoogleMapApiInternals
                                    onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}  
                                >
                                    { siteNodes && 
                                        siteNodes.nodes.map((node, index) => {
                                            return (
                                                <NodeMarker
                                                    key={"arableNodeMarker"+index}
                                                    lat={node.lat}
                                                    lng={node.lon}
                                                    text={node.node_type_name}
                                                    borderColor={NodeMarkerBDColor}
                                                    backgroundColor={NodeMarkerBGColor}                                                
                                                />  
                                            )
                                        })
                                    }                                          
                                </GoogleMapReact>     
                                { siteNodes &&
                                    <Card inverse className="weather-card">
                                    <CardBody>
                                        <CardTitle tag="h4">{siteNodes.weather.city_name}</CardTitle>
                                        <CardSubtitle tag="h6" className="mb-2 text-muted text-capitalize">{siteNodes.weather.description}</CardSubtitle>
                                        <CardText className="small">Humidity {siteNodes.weather.humidity}%</CardText>
                                        <img src={siteNodes.weather.weather_icon} alt="Weather" />
                                        <b>{siteNodes.weather.temp}{siteNodes.weather.wmu}</b>
                                    </CardBody>
                                    </Card>
                                }
                            </>                            
                            :
                            <img src={googleMaps} alt="Google Map" width="100%">
                            </img>
                        }                           
                    </div>
                </CardBody>
            </Card >  
            {/*--------------------------------------------------------------------------------*/
            /* Table for Nodes                                                  */
            /*--------------------------------------------------------------------------------*/}
            <Card className="table-y-scrollable">
                <CardHeader className="d-flex justify-content-between align-items-center">
                    Nodes
                </CardHeader>
                <CardBody>      
                {                   
                    loadingSiteNodesStatus === 1 ?
                        <div className="d-flex justify-content-center">
                            <img src={LoadingGif} alt="loading" width="20px" /> 
                        </div> 
                    :          
                        <Table className="no-wrap v-middle" responsive hover size="sm">
                            <thead>
                                <tr className="border-0">
                                    <th className="border-0">
                                        Date
                                    </th>
                                    <th className="border-0">
                                        Node ID
                                    </th>
                                    <th className="border-0">
                                        Humidity
                                    </th>
                                    <th className="border-0">
                                        Light
                                    </th>
                                    <th className="border-0">
                                        Soil Temperature
                                    </th>
                                    <th className="border-0">
                                        PH
                                    </th>
                                    <th className="border-0">
                                        Type
                                    </th>
                                    <th className="border-0">
                                        Latitude
                                    </th>
                                    <th className="border-0">
                                        Longitude
                                    </th>                                    
                                    <th className="border-0">
                                    </th>
                                </tr>
                            </thead>
                            <tbody style={{maxHeight: '100px'}}>
                                {
                                    (siteNodes && siteNodes.nodes.map((node, itemIndex: number) => {
                                        return (<tr key={"arableProject"+itemIndex}>
                                            <td>
                                                {node.datetime}
                                            </td>
                                            <td>
                                                {node.node_id}
                                            </td>
                                            <td className="pt-2">
                                                {node.humidity}
                                            </td>
                                            <td>
                                                {node.light}
                                            </td>
                                            <td>
                                                {node.soil_temperature}
                                            </td>
                                            <td>
                                                {node.ph}
                                            </td>
                                            <td>
                                                {node.node_type_name}
                                            </td>
                                            <td>
                                                {node.lat}
                                            </td>    
                                            <td>
                                                {node.lon}
                                            </td>                                              
                                            <td>
                                                <Button onClick={() => handleShowNodeVideo(node.node_feed_url)} disabled={node.node_feed_url === null}>
                                                    <i className="fas fa-video"></i>
                                                </Button>                                                        
                                            </td>

                                        </tr>)
                                    }))                             
                                }                            
                            </tbody>
                        </Table>
                }

                </CardBody>
            </Card >      

            {/*--------------------------------------------------------------------------------*/
            /* Table for arables                                                  */
            /*--------------------------------------------------------------------------------*/}
            <Card className="table-y-scrollable">
                <CardHeader className="d-flex justify-content-between align-items-center">
                    Arables
                </CardHeader>
                <CardBody>     
                {                   
                    loadingSiteNodesStatus === 1 ?
                        <div className="d-flex justify-content-center">
                            <img src={LoadingGif} alt="loading" width="20px" /> 
                        </div> 
                    :          
                        <Table className="no-wrap v-middle" responsive hover size="sm">
                            <thead>
                                <tr className="border-0">
                                    <th className="border-0">
                                        Variaty
                                    </th>
                                    <th className="border-0">
                                        Type
                                    </th>
                                    <th className="border-0">
                                        Start
                                    </th>
                                    <th className="border-0">
                                        End
                                    </th>
                                    <th className="border-0">
                                        
                                    </th>
                                    <th className="border-0">

                                    </th>
                                </tr>
                            </thead>
                            <tbody style={{maxHeight: '100px'}}>
                                {
                                    siteNodes && siteNodes.arables.map((arable, itemIndex: number) => {
                                        return (<tr key={"arable"+itemIndex}>
                                            <td className="pt-2">
                                                {arable.crop_variety}
                                            </td>    
                                            <td className="pt-2">
                                                {arable.crop_type}
                                            </td>    
                                            <td className="pt-2">
                                                {arable.started}
                                            </td>    
                                            <td className="pt-2">
                                                {arable.ended}
                                            </td>      
                                            <td>
                                                <img src={arable.image_name} alt="Crop" width="20px" height="20px"/>
                                            </td>  
                                            <td className="pt-2">
                                                <Button onClick={() => handleToggleArableDetailModal(!isOpenArableModal, arable)}>
                                                    <i className="fa fa-search"></i> View Detail
                                                </Button>                
                                            </td>    
                                        </tr>)
                                    })                             
                                }                            
                            </tbody>
                        </Table>
                    }

                </CardBody>
            </Card >  
            {
                arableForModal && 
                <ArableModal isOpen={isOpenArableModal} onToggle={() => handleToggleArableDetailModal(!isOpenArableModal, null)} arable={arableForModal}/>                            
            }
            <VideoPlayerModal isOpen={isOpenVideoPlayerModal} onToggle={handleChangeVisibleVideoPlayerModal} url={selectedNodeFeedUrl}/>
        </>
    )
}    

export default Arables;
