import { useState } from 'react';
import Popup from 'reactjs-popup';
type NodeMarkerProps = {
    text: string,
    lat: number,
    lng: number,
    backgroundColor: string,
    borderColor: string,
}
const NodeMarker = ({text, lat, lng, backgroundColor, borderColor}: NodeMarkerProps) => {
    const [isOpen, setOpen] = useState(false);

    const handleShowInfoWithPopup = () => {
        setOpen(!isOpen);
    }

    const handleMouseLeave = () => {
        setOpen(false);
    }

    return (
      <Popup
        trigger={
          <div
            className="marker marker-node"
            onMouseLeave={()=>handleMouseLeave()}            
          >
            <div 
                className="marker-content"
                style={{borderColor, backgroundColor}}
                onClick={handleShowInfoWithPopup}
            >
                <span className="">{text}</span>                
            </div>
            <div className="marker-arrow">            
                <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none" className="arrow">
                    <path d="M1 51L51 1H22L1 51Z" fill={borderColor} stroke={borderColor} strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
          </div>
        }
        open={isOpen}
        position="top left"
        keepTooltipInside=".tooltipBoundary"
      >      
        <div>
            <div>
              Node Type: {text}
            </div>
            <div>
              Latitude: {lat}
            </div>
            <div>
              Longitude: {lng}
            </div>
        </div>
      </Popup>
    )
}
export default NodeMarker;