import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardHeader, CardBody, Table} from 'reactstrap';
import { fetchSettingClientsProjects, SettingsStateI, ClientProjectI, addUserToProject, deleteUserFromProject, SettingUserI, SettingClientProjectSiteI } from '../../modules/accountSettingsModule';
import { RootState } from '../../modules';
import EditUserProjectLinkedMemberModal from './editUserProjectLinkedMemberModal';
import { useHistory } from 'react-router';

const SettingsClientsProjects = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { setting_clients_projects, setting_users }: SettingsStateI = useSelector((state: RootState) => state.accountSettingsReducer);
    const[selectedProject, selectProject] = useState<null | ClientProjectI>(null);

    useEffect(() => {
        dispatch(fetchSettingClientsProjects(history));
    }, [dispatch, history])

    useEffect(() => {
        if (selectedProject && setting_clients_projects) {
            let tempProject = null;
            let isMatched = false;
            setting_clients_projects.find((client) => {
                client.projects.find((project) => {
                    if (project.project_id === selectedProject.project_id ) {
                        tempProject = JSON.parse(JSON.stringify(project));
                        isMatched = true;
                        return isMatched
                    }
                    return isMatched
                });
                return isMatched
            })
            if (tempProject) {
                selectProject(tempProject);
            }
        }
    }, [setting_clients_projects])

    const [projectLinkedMemberModal, setProjectLinkedMemberModal] = useState(false);
    const toggleProjectLinkedMemberModal = () => {
        setProjectLinkedMemberModal(!projectLinkedMemberModal);
        return 
    };

    const handleOpenProjectLinkedMemberModal = (project: ClientProjectI) => {
        setProjectLinkedMemberModal(!projectLinkedMemberModal);
        selectProject(project);
        return 
    }

    const handleChangeState = (e: React.ChangeEvent<HTMLInputElement>, user: SettingUserI, site: SettingClientProjectSiteI | null, project: ClientProjectI | null) => {
        let isMatched = handleCheckExistUserInLinkedMember(user.user_id, null, project);
        if (project) {
            if (isMatched !== -1) {
                dispatch(deleteUserFromProject({user_id: user.user_id, project_id: project.project_id}, {user_id: user.user_id}, history));
            } else {
                dispatch(addUserToProject({user_id: user.user_id, project_id: project.project_id}, {user_id: user.user_id}, history));  
            }        
        }
    }

    const handleCheckExistUserInLinkedMember = (userID: string, site: SettingClientProjectSiteI | null, project: ClientProjectI | null) => {
        let isMatched = -1;
        if (project) {
            isMatched = project?.linked_members.findIndex((linkedMember) => {
                return linkedMember.user_id === userID
            });
        }

        return isMatched
    }   

    return (
        <>
        <Card className="table-y-scrollable">
            <CardHeader>Clients Projects</CardHeader>
            <CardBody>
                <Table className="no-wrap v-middle" responsive hover size="sm">
                    <thead>
                        <tr className="border-0">
                            <th className="border-0">Project Name</th>
                            <th className="border-0">Client Name</th>
                            <th className="border-0">Linked Users</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            setting_clients_projects && setting_clients_projects.map((client) => 
                                {
                                    return client.projects.map((project) => (
                                        <tr key={"project"+project.project_id}>
                                            <td className="pt-2">
                                                {client.client_name}
                                            </td>
                                            <td className="pt-2">
                                                {project.project_name}
                                            </td>
                                            <td className="d-flex">
                                                <div className="flex-grow-1 d-flex">
                                                    {
                                                        project.linked_members.map((member, index) => {
                                                            let memberFullInfo = null;
                                                            if (setting_users) {
                                                                memberFullInfo = setting_users.find((user) => {
                                                                    return user.user_id === member.user_id
                                                                })
                                                            }
                                                            
                                                            if (memberFullInfo) {
                                                                if (memberFullInfo.image_name && memberFullInfo.image_name !== "" && memberFullInfo.image_name !== null) {
                                                                    return (
                                                                        <img
                                                                            src={memberFullInfo.image_name}
                                                                            alt="user"
                                                                            className="rounded-circle"
                                                                            width="31"
                                                                            key={"member"+index}
                                                                            title={memberFullInfo.forename + " " + memberFullInfo.surname}
                                                                        />
                                                                    )       
                                                                } else {
                                                                    return (
                                                                        <div
                                                                            style={{
                                                                                width: "31px", 
                                                                                height: "31px",
                                                                                background: "red",
                                                                                borderRadius: "50%",
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center"                                                                            
                                                                            }}
                                                                            key={"member"+index}
                                                                            title={memberFullInfo.forename + " " + memberFullInfo.surname}
                                                                        >
                                                                            <span style={{color: "white"}}>{memberFullInfo.forename.charAt(0) + " " + memberFullInfo.surname.charAt(0)}</span>
                                                                        </div>
                                                                    )       
                                                                }
    
                                                            } else {
                                                                return <div key={"member"+index}></div>
                                                            }
                                                        })
                                                    }
                                                </div>
                                                <Button color="white" className="btn-sm rounded" onClick={() => handleOpenProjectLinkedMemberModal(project)}><i className="fa fa-edit"></i></Button>
                                            </td>
                                        </tr>    
                                            
                                    ))
                                }
                            )
                        }
                    </tbody>
                </Table>
            </CardBody>
        </Card >
        <EditUserProjectLinkedMemberModal 
            mode="project"
            project={selectedProject} 
            site={null}
            isOpen={projectLinkedMemberModal} 
            onToggle={toggleProjectLinkedMemberModal}
            onChangeCheckBox={handleChangeState}
            onCheckExistUserInLinkedMember={handleCheckExistUserInLinkedMember}
        />
        </>
    );
}

export default SettingsClientsProjects;
