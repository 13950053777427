import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router";
import Select from 'react-select';
import { ValueType } from "react-select/src/types";
import NodesTable from './nodesTable';
import { checkValidation, validationI } from "../../helper/checkValidation";
import { BaseUrl } from "../../constant/constant";

// reactstrap components
import {
    Button,
    FormGroup,
    Form,
    Input,
    FormText,
    Row,
    Col,
    Modal, 
    ModalHeader,
    ModalBody, 
    ModalFooter   
} from "reactstrap";
import LatsLongsErrorModal from './LatsLongsErrorModal';

import { RootState } from '../../modules';
import { fetchSites, SiteI, ProjectsManagementStateI } from '../../modules/projectsManagementModule';
import { v4 as uuidv4 } from 'uuid';

type ErrorProps = {
    isError: boolean | null,
    message: string,
}

type Props = {
    onToggle: () => void,
    isOpen: boolean,
    siteDetail: null | SiteI
}

type SelectProjectOptionProps = {
    project_id: string,
    project_name: string,
}

type SelectSiteTypeOptionProps = {
    site_type_id: string,
    site_type_name: string,
}

export type NodeProps = {
    id: string,
    lat: string,
    long: string,
}

const SiteModal = ({ onToggle, isOpen, siteDetail }: Props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { siteTypes, projects }: ProjectsManagementStateI = useSelector((state: RootState) => state.projectsManagementReducer);

    useEffect(() => {
        setSiteName(siteDetail? siteDetail.site_name: "");
        setSiteAddress(siteDetail? siteDetail.address: "");
        setSiteID(siteDetail? siteDetail.site_id: "");
        // set nodes
        let tempNodes = [];
        if (siteDetail?.polygon) {
            tempNodes = siteDetail?.polygon.lats.map((lat, index) => {
                return {id: uuidv4(), lat, long: siteDetail.polygon.longs[index]}
            })
            setNodes(tempNodes);
        }
        let tempSiteTypeOption = null;
        siteTypes?.forEach((siteType) => {
            if (siteType.site_type_id === siteDetail?.site_type_id) {
                tempSiteTypeOption = siteType;
            }
        })
        setSiteTypeOption(tempSiteTypeOption);
    }, [siteDetail, siteTypes])

    // input field variable 
    const [siteID, setSiteID] = useState("");
    const [siteName, setSiteName] = useState(siteDetail? siteDetail.site_name: "");
    const [siteAddress, setSiteAddress] = useState(siteDetail? siteDetail.address: "");
    const [selectedProjectOption, setProjectOption] = useState<ValueType<SelectProjectOptionProps, false> | null>(null);
    const [selectedSiteTypeOption, setSiteTypeOption] = useState<ValueType<SelectSiteTypeOptionProps, false> | null>(null);
    const [nodes, setNodes] = useState<NodeProps[]>([]);

    // set open latsLongsErrorModal
    const [isOpenLatsLongsErrorModal, setOpenLatsLongsErrorModal] = useState(false);

    // set error 
    const [siteNameError, setSiteNameError] = useState<ErrorProps>({isError: null, message: ""});
    const [siteAddressError, setSiteAddressError] = useState<ErrorProps>({isError: null, message: ""});
    const [selectedProjectOptionError, setSelectProjectOptionError] = useState<ErrorProps>({isError: null, message: ""});
    const [selectedSiteTypeOptionError, setSelectSiteTypeOptionError] = useState<ErrorProps>({isError: null, message: ""});

    const handleChangeSiteName = (value: string) => {
        setSiteName(value);
        if (siteNameError.isError) {
            setSiteNameError({isError: false, message: ""});
        }
    }

    const handleChangeAddress = (value: string) => {
        setSiteAddress(value);
        if (siteAddressError.isError) {
            setSiteAddressError({isError: false, message: ""});
        }
    }

    const handleChangeProjectOption = (newOption: ValueType<SelectProjectOptionProps, false> | null) => {
        setProjectOption(newOption);
        if (selectedProjectOptionError.isError) {
            setSelectProjectOptionError({isError: false, message: ""});
        }
    }

    const handleChangeSiteTypeOption = (newOption: ValueType<SelectSiteTypeOptionProps, false> | null) => {
        setSiteTypeOption(newOption);
        if (selectedSiteTypeOptionError.isError) {
            setSelectSiteTypeOptionError({isError: false, message: ""});
        }
    }

    const handleSaveSite = async() => {
        let returnFlag = false;
        if (!siteDetail && siteName === "") {
            setSiteNameError({isError: true, message: "Please enter this field."});
            returnFlag = true;
        }

        if (!siteDetail && siteAddress === "") {
            setSiteAddressError({isError: true, message: "Please enter this field."});
            returnFlag = true;
        }

        if (!siteDetail && !selectedSiteTypeOption) {
            setSelectSiteTypeOptionError({isError: true, message: "Please enter this field."});
            returnFlag = true;
        }

        if (!siteDetail && !selectedProjectOption) {
            setSelectProjectOptionError({isError: true, message: "Please enter this field."});
        }

        // check if lats and longs have correct value
        let sitePolygon:{lats: string[], longs: string[]} = {lats: [], longs: []};
        nodes.forEach((node) => {
            const latValid:validationI = checkValidation(node.lat, 'floatAndNumber')
            if (!latValid.validation) {
                returnFlag = true;
            }
            const longValid:validationI = checkValidation(node.long, 'floatAndNumber')
            if (!longValid.validation) {
                returnFlag = true;
            }

            sitePolygon.lats.push(node.lat);
            sitePolygon.longs.push(node.long);
            if (returnFlag) {
                setOpenLatsLongsErrorModal(!isOpenLatsLongsErrorModal);
            }
        });


        if (returnFlag) {
            return 
        }

        onToggle();
        
        if (siteDetail) {
            let requestDetail = {
                site_id: siteID,
                site_name: siteName,
                address: siteAddress,
                polygon: sitePolygon,
                site_type_id: selectedSiteTypeOption?.site_type_id
            };
    
            let token: string | null = localStorage.getItem('token');
            if (token) {
                let res = await fetch(`${BaseUrl}/site`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token
                    },
                    method: 'PUT',
                    body: JSON.stringify(requestDetail)
                })
                let json = await res.json();
                if (json.message === "token is invalid!") {
                    history.push("/");
                    localStorage.setItem('token', '');
                } 
                if (!json.message) {
                    dispatch(fetchSites(history));                
                }
            }
        } else {
            let requestDetail = {
                project_id: selectedProjectOption?.project_id,
                site_name: siteName,
                address: siteAddress,
                polygon: sitePolygon,
                site_type_id: selectedSiteTypeOption?.site_type_id
            };

    
            let token: string | null = localStorage.getItem('token');
            if (token) {
                let res = await fetch(`${BaseUrl}/site`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token
                    },
                    method: 'POST',
                    body: JSON.stringify(requestDetail)
                })
                let json = await res.json();
                if (json.message === "token is invalid!") {
                    history.push("/");
                    localStorage.setItem('token', '');
                } 
                
                if (!json.message) {
                    dispatch(fetchSites(history));                
                }
            }
        }

        initState();
    }

    const handleToggleModal = () => {
        onToggle();
        initState();
    }

    const handleToggleLatsLongsErrorModal = () => {
        setOpenLatsLongsErrorModal(!isOpenLatsLongsErrorModal);
        return;
    }

    const initState = () => {
        setSiteID('');
        setSiteName('');
        setSiteAddress('');
        setProjectOption(null);
        setSiteTypeOption(null);
        setNodes([]);
        setSiteNameError({isError: null, message: ""});
        setSiteAddressError({isError: null, message: ""});
        setSelectProjectOptionError({isError: null, message: ""});
        setSelectSiteTypeOptionError({isError: null, message: ""});
    }

    const handleChangeNodes = (nodes: NodeProps[]) => {
        setNodes(nodes);
        return;
    }
    const getStyleForReactSelect = (value: ErrorProps) => {
        const style = {
            control: (base: any, state: any) => ({
              ...base,
                // state.isFocused can display different borderColor if you need it
                borderColor: state.isFocused ?
                    '#ddd' : value.isError ?
                    'red' : '#ddd',
            })
        }        
        return style
    }
    return (
        <>
            <Modal isOpen={isOpen} toggle={handleToggleModal} >
                <ModalHeader toggle={handleToggleModal}>{siteDetail ? "Edit Site" : "Create Site"}</ModalHeader>
                <ModalBody>
                    <Form autoComplete="off">
                        <Row>
                            {!siteDetail &&
                                <Col sm={12}>
                                    <FormGroup >
                                        <label
                                            className="form-control-label"
                                            htmlFor="selectProjectOption"
                                        >
                                            Project
                                        </label>
                                        <Select
                                            id="selectProjectOption"
                                            value={selectedProjectOption}
                                            options={projects? projects: []}
                                            getOptionLabel ={(option)=>option.project_name}
                                            getOptionValue ={(option)=>option.project_id}
                                            onChange={(e) => handleChangeProjectOption(e)}
                                            placeholder="Select a Project"
                                            styles={getStyleForReactSelect(selectedProjectOptionError)}
                                        />         
                                    </FormGroup>                           
                                </Col>      
                            }
                            <Col sm={12}>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="selectSiteTypeOption"
                                    >
                                        Site Type
                                    </label>                                    
                                    <Select
                                        id="selectSiteTypeOption"
                                        value={selectedSiteTypeOption}
                                        options={siteTypes? siteTypes: []}
                                        getOptionLabel ={(option)=>option.site_type_name}
                                        getOptionValue ={(option)=>option.site_type_id}
                                        onChange={(e) => handleChangeSiteTypeOption(e)}
                                        placeholder="Select a Site Type"
                                        styles={getStyleForReactSelect(selectedSiteTypeOptionError)}
                                    />                                    
                                </FormGroup> 
                            </Col>                                
                            <Col sm="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-site-name"
                                    >
                                        Name
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-site-name"
                                        type="text"
                                        value={siteName}
                                        onChange={(e)=>{handleChangeSiteName(e.target.value)}}
                                        invalid={(siteNameError.isError === true)}
                                    />
                                </FormGroup>
                                <FormText>{siteNameError.isError? siteNameError.message: ""}</FormText>
                            </Col>
                            <Col sm="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-site-address"
                                    >
                                        Address
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-site-address"
                                        type="text"
                                        value={siteAddress}
                                        onChange={(e)=>{handleChangeAddress(e.target.value)}}
                                        invalid={(siteAddressError.isError === true)}
                                    />
                                </FormGroup>
                                <FormText>{siteAddressError.isError? siteAddressError.message: ""}</FormText>                      
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <NodesTable nodes={nodes} onChangeNodes={handleChangeNodes} />
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSaveSite}>Save</Button>
                    <Button color="secondary" onClick={handleToggleModal}>Cancel</Button>
                </ModalFooter>
            </Modal> 
            <LatsLongsErrorModal isOpen={isOpenLatsLongsErrorModal} onToggle={handleToggleLatsLongsErrorModal}/>
        </>
    )
};

export default SiteModal;