export interface validationI {
    validation: boolean,
    validationMsg: string,
}

export const checkValidation = (value: string, type: string): validationI => {
  let result = {validation: true, validationMsg: ""};
  
  let phoneRGEX = /^\(?\d{3}\)?-?\s*-?\d{4}$/;   //   /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/    or    /^\(?\d{3}\)?-?\s*-?\d{4}$/;
  let emailRGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  let postalRGEX = /^[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}$/i;
  let zipRGEX = /^\d{5}$/;
  let priceRGEX = /^\d*(?:\.\d{0,2})?$/;
  let numericRGEX = /^[0-9]+$/;
  let floatAndNumberRGEX = /^[+-]?\d+(\.\d+)?$/;  // and for google map longitude and latitude
  let alphaRGEX = /^[a-zA-Z]+$/;
  let alphaNumeraicRGEX = /^[0-9a-zA-Z]+$/;
//  let urlHttpsRGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&=]*)/;
  let base64 = /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/;

  // To check empty form fields.
  if (value.length === 0) {
    result.validationMsg = "Please enter this field."; 
    result.validation = false;
    return result;
  }

  // To check value including space
  if (value.trim() === "") {
    result.validationMsg = "Please enter this field."; 
    result.validation = false;
    return result;  
  }

  switch (type) {
    case "phone number":
      result.validation = phoneRGEX.test(value);
      break;
    case "email":
      result.validation = emailRGEX.test(value);
      break;
    case "postal code":
      result.validation = postalRGEX.test(value);
      break;
    case "zip code":
      result.validation = zipRGEX.test(value);
      break;
    case "price":
      result.validation = priceRGEX.test(value);
      break;
    case "number":
      result.validation = numericRGEX.test(value);
      break;
    case "floatAndNumber":  
      result.validation = floatAndNumberRGEX.test(value);
      break;
    case "first name":
    case "last name":
      result.validation = alphaRGEX.test(value);
      break;
    case "alphaNumeric":
      result.validation = alphaNumeraicRGEX.test(value);
      break;
    case "base64":
      result.validation = base64.test(value);
      break;
    // case "url":
    //   result.validation = urlHttpsRGEX.test(value);
    //   break;
    default:
      break;
  }

  if (!result.validation) {
    result.validationMsg = "This " + type + " format is invalid";
  }

  return result; 
}
