import { useState } from "react";
import { useHistory } from "react-router";
import { BaseUrl } from "../../constant/constant";

// reactstrap components
import {
    Form, FormGroup, FormText, Button, Input, InputGroup, InputGroupAddon, InputGroupText,
    Row, Col,
    Modal, ModalHeader, ModalBody, ModalFooter   
} from "reactstrap";
import { passwordStrength } from 'check-password-strength'

type Props = {
    onToggle: () => void,
    isOpen: boolean,
}

type PasswordStrengthResultProps = {
    id: number,
    value: string,
    length: number
}

const ChangePasswordModal = ({ onToggle, isOpen }: Props) => {
    const history = useHistory();

    const [currPassword, setCurrPW] = useState("");
    const [newPassword, setNewPW] = useState("");
    const [newPasswordStrength, setNewPasswordStrength] = useState<null | PasswordStrengthResultProps>(null);
    const [confirmPassword, setConfirmPW] = useState("");

    const [currPWErr, setCurrPWErr] = useState({ isErr: false, errTxt: ""});
    const [newPWErr, setNewPWErr] = useState({ isErr: false, errTxt: ""});
    const [confirmPWErr, setConfirmPWErr] = useState({ isErr: false, errTxt: ""});

    // when input currentPW
    const handleChangeCurrentPW = (value: string) => {
        setCurrPW(value);
        setCurrPWErr({ isErr: false, errTxt: "" });
    }
    
    // when input newPW
    const handleChangeNewPW = (value: string) => {
        setNewPW(value);
        let pwStrength = passwordStrength(value);
        setNewPasswordStrength({
            id: pwStrength.id,
            value: pwStrength.value,
            length: pwStrength.length,
        });
    }

    // when input confirmPW
    const handleChangeConfirmPW = (value: string) => {
        setConfirmPW(value);
        setConfirmPWErr({ isErr: false, errTxt: "" });
    }

    // when click ok btn
    const handleSaveChangedPWBtn = async() => {
        let returnFlag = false;
        if (currPassword === "") {
            returnFlag = true;
            setCurrPWErr({ isErr: true, errTxt: "" });
        }

        if (!newPasswordStrength) {
            returnFlag = true;
            setNewPWErr({ isErr: true, errTxt: "" });
        }

        if ( newPasswordStrength && (newPasswordStrength.id === 0 || newPasswordStrength.id === 1)) {
            returnFlag = true;
            setNewPWErr({ isErr: true, errTxt: "" });
        }

        if (newPassword !== confirmPassword) {
            returnFlag = true;
            setConfirmPWErr({ isErr: true, errTxt: "" });
        }

        if (returnFlag) {
            return 
        }

        // initialization
        setCurrPW("");
        setNewPW("");
        setNewPasswordStrength(null);
        setConfirmPW("");
        setCurrPWErr({ isErr: false, errTxt: ""});
        setNewPWErr({ isErr: false, errTxt: ""});
        setConfirmPWErr({ isErr: false, errTxt: ""});
        onToggle();

        let requestDetail = {
            password: currPassword,
            new_password: newPassword
        };
        let token: string | null = localStorage.getItem('token');
        if (token) {
            let res = await fetch(`${BaseUrl}/change_password`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token
                },
                method: 'PUT',
                body: JSON.stringify(requestDetail)
            })
            let json = await res.json();
            if (json.message === "token is invalid!") {
                history.push("/");
                localStorage.setItem('token', '');
            }  
        }
    }

    // when click cancel btn
    const handleCancelChangedPWBtn = () => {
        setCurrPW("");
        setNewPW("");
        setNewPasswordStrength(null);
        setConfirmPW("");
        setCurrPWErr({ isErr: false, errTxt: ""});
        setNewPWErr({ isErr: false, errTxt: ""});
        setConfirmPWErr({ isErr: false, errTxt: ""});
        onToggle();
    }
    
    return (
        <Modal isOpen={isOpen} toggle={handleCancelChangedPWBtn} >
            <ModalHeader toggle={handleCancelChangedPWBtn}>Change Password</ModalHeader>
                <ModalBody className="avatarCropModal">
                <Form autoComplete="off">
                    <div className="pl-lg-4">
                        <Row>
                            <Col sm="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-first-name"
                                    >
                                        Current Password
                                    </label>
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fas fa-lock"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>                                        
                                        <Input
                                            className="form-control-alternative"
                                            id="input-first-name"
                                            type="password"
                                            value={currPassword}
                                            invalid={(currPWErr.isErr)}
                                            autoComplete="new-password"
                                            onChange={(e) => handleChangeCurrentPW(e.target.value)}
                                        />
                                    </InputGroup> 
                                {/* <FormText>{firstnameError.isError? firstnameError.message: ""}</FormText> */}
                                </FormGroup>
                            </Col>
                            <Col sm="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-last-name"
                                    >
                                        New Password
                                    </label>
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fas fa-lock"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>

                                        <Input
                                            className="form-control-alternative"
                                            id="input-last-name"
                                            type="password"
                                            value={newPassword}
                                            invalid={(newPWErr.isErr)}
                                            autoComplete="new-password"
                                            onChange={(e)=>{handleChangeNewPW(e.target.value)}}
                                        />
                                    </InputGroup> 
                                    {
                                        newPasswordStrength && 
                                        <FormText 
                                            color={
                                                newPasswordStrength.id === 0? "danger": 
                                                newPasswordStrength.id === 1? "warning": 
                                                newPasswordStrength.id === 2? "info": "success"
                                            }>
                                                {newPasswordStrength? newPasswordStrength.value: ""}
                                        </FormText>     
                                    }                           
                                    {/* <FormText>{surnameError.isError? surnameError.message: ""}</FormText>                       */}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-phonenumber"
                                    >
                                        Confirm Password
                                    </label>
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fas fa-lock"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            className="form-control-alternative"
                                            id="input-phonenumber"
                                            type="password"
                                            value={confirmPassword}
                                            invalid={(confirmPWErr.isErr)}
                                            autoComplete="new-password"
                                            onChange={(e)=>{handleChangeConfirmPW(e.target.value)}}
                                        />
                                    </InputGroup> 
                                {/* <FormText>{telephoneError.isError? telephoneError.message: ""}</FormText>                                        */}
                                </FormGroup>
                            </Col>                
                        </Row>
                    </div>
                </Form>
                </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleSaveChangedPWBtn}>Ok</Button>
                <Button color="secondary" onClick={handleCancelChangedPWBtn}>Cancel</Button>
            </ModalFooter>
        </Modal> 
    )
};

export default ChangePasswordModal;

