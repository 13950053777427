import { useSelector } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Table, Form, FormGroup, Input } from 'reactstrap';
import { SettingsStateI, ClientProjectI, SettingUserI, SettingClientProjectSiteI } from '../../modules/accountSettingsModule';
import { RootState } from '../../modules';

type Props = {
    project: ClientProjectI | null,
    site: SettingClientProjectSiteI | null,
    mode: string,
    isOpen: boolean,
    onToggle: () => void, 
    onChangeCheckBox: (e: React.ChangeEvent<HTMLInputElement>, user: SettingUserI, site: null | SettingClientProjectSiteI, project: ClientProjectI | null) => void,
    onCheckExistUserInLinkedMember: (userID: string, site: SettingClientProjectSiteI | null, project: ClientProjectI | null ) => number,
}
const EditUserProjectLinkedMemberModal = ({ project, site, mode, isOpen, onToggle, onChangeCheckBox, onCheckExistUserInLinkedMember }: Props) => {
    const { setting_users }: SettingsStateI = useSelector((state: RootState) => state.accountSettingsReducer);

    return (
        <Modal isOpen={isOpen} toggle={onToggle} >
            <ModalHeader toggle={onToggle}>Add or Remove Users</ModalHeader>
            <ModalBody>
                <Table className="no-wrap v-middle" responsive>
                    <thead>
                        <tr className="border-0">
                            <th className="border-0">Name</th>
                            <th className="border-0">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            setting_users && setting_users.map((user) => {
                                return (
                                    <tr key={"linkedMmeber"+user.user_id}>
                                        <td>
                                            <div className="d-flex no-block align-items-center">
                                                <div className="mr-2">
                                                    {
                                                        (user.image_name && user.image_name !== "" && user.image_name !== null) ?
                                                        <img
                                                            src={user.image_name}
                                                            alt="user"
                                                            className="rounded-circle"
                                                            width="31"
                                                            title={user.forename + " " + user.surname}
                                                        />
                                                        :
                                                        <div
                                                            style={{
                                                                width: "31px", 
                                                                height: "31px",
                                                                background: "red",
                                                                borderRadius: "50%",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center"                                                                            
                                                            }}
                                                            title={user.forename + " " + user.surname}
                                                        >
                                                            <span style={{color: "white"}}>{user.forename.charAt(0) + " " + user.surname.charAt(0)}</span>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="">
                                                    <h5 className="mb-0 font-16 font-medium">{user.forename + " " + user.surname}</h5>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <Form>
                                                <FormGroup check>
                                                    {
                                                        (mode === "project")? 
                                                        <Input 
                                                            type="checkbox" 
                                                            checked={(project !== null) && (onCheckExistUserInLinkedMember(user.user_id, null, project) !== -1)} 
                                                            onChange={(e)=>onChangeCheckBox(e, user, null, project)}/>
                                                        :
                                                        <Input 
                                                            type="checkbox" 
                                                            checked={(site !== null) && (onCheckExistUserInLinkedMember(user.user_id, site, null) !== -1)} 
                                                            onChange={(e)=>onChangeCheckBox(e, user, site, null)}/>
                                                    }
                                                </FormGroup>                                            
                                            </Form>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </ModalBody>
            <ModalFooter>
            <Button color="secondary" onClick={onToggle}>Close</Button>
            </ModalFooter>
        </Modal>
    );
}

export default EditUserProjectLinkedMemberModal;