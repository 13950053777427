import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, CardHeader, CardBody, Form, FormGroup, Input, Label} from 'reactstrap';
import { fetchSettingsAndUnits, saveSettingUnit, SettingsStateI } from '../../modules/accountSettingsModule';
import { RootState } from '../../modules';
import { useHistory } from 'react-router';

const SettingUnits = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { settings_units }: SettingsStateI = useSelector((state: RootState) => state.accountSettingsReducer);

    useEffect(() => {
        dispatch(fetchSettingsAndUnits(history));
    }, [dispatch, history])

    const [selectedTemperatureUnit, setTemperatureUnit] = useState("");
    const [selectedDistanceUnit, setDistanceUnit] = useState("");

    const handleChangeSelectField = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === "temperature") {
            setTemperatureUnit(e.target.value);
        } else {
            setDistanceUnit(e.target.value);
        }
        dispatch(saveSettingUnit({name: e.target.name, selected_unit: e.target.value}, history));
    }

    return (
        <Card>
            <CardHeader>Units Setup</CardHeader>
            <CardBody>
                <Form autoComplete="off">
                    <Row>
                        {settings_units && settings_units.map((setting, index) => {
                            if (setting.name === "distance" || setting.name === "temperature") {
                                return (
                                    <Col sm="6" key={"settingFormGroup" + index}>
                                        <FormGroup>
                                            <Label for={setting.name}>{setting.name.replace(/^\w/, (c) => c.toUpperCase())}</Label>
                                            <Input
                                                bsSize="lg"
                                                id={setting.name}  
                                                value={
                                                    (setting.name === "distance") ?
                                                        (selectedDistanceUnit !== "" ? selectedDistanceUnit : setting.selected_unit)
                                                    :
                                                        (selectedTemperatureUnit !== "" ? selectedTemperatureUnit : setting.selected_unit)
                                                } 
                                                type="select" 
                                                name={setting.name} 
                                                onChange={handleChangeSelectField}>
                                                {
                                                    setting.units.map((unit, unitIndex) => {
                                                        return <option key={setting.name + "option" + unitIndex}>{unit}</option>
                                                    })
                                                }
                                            </Input>
                                        </FormGroup>
                                    </Col>                                        
                                )
                            } else {
                                return null
                            }
                        })}
                    </Row>
                </Form>
            </CardBody>
        </Card>
    );
}

export default SettingUnits;
