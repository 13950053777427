import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SettingsStateI, SettingUserI, fetchSettingUsers, fetchUser, fetchUserRoles } from '../../modules/accountSettingsModule';
import { RootState } from '../../modules';

// reactstrap components
import {
    Button, 
    Card, CardHeader, CardBody, 
    Form, FormGroup, FormText, Input, InputGroup, InputGroupAddon, 
    Row, Col, Table,
    Modal, 
    ModalHeader,
    ModalBody, 
    ModalFooter   
} from "reactstrap";
import Select from 'react-select';
import { ValueType } from "react-select/src/types";

// core components
import EditUserProfileModal from './editUserProfileModal';

import { checkValidation, validationI } from "../../helper/checkValidation";
import { createUser, editUser, EditUserRequestI, UserRoleI } from '../../modules/accountSettingsModule';
import { useHistory } from 'react-router';
type ErrorProps = {
    isError: boolean | null,
    message: string,
}

const SettingsUsers = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { setting_users, user_roles, mode, user_id }: SettingsStateI = useSelector((state: RootState) => state.accountSettingsReducer);
    useEffect(() => {
        dispatch(fetchSettingUsers(history));
        dispatch(fetchUserRoles(history));
    }, [dispatch, history])

    useEffect(() => {
        if ((mode === "CREATE_USER" && user_id === "Done") || (mode === "EDIT_USER" && user_id === "Done")) {
            dispatch(fetchSettingUsers(history));
        }
    }, [mode, user_id, dispatch, history])

    useEffect(() => {
        let tempSelectedUserRole = null;
        if (user_roles) {
            tempSelectedUserRole = user_roles[0];
        }
        setUserRoleOption(tempSelectedUserRole);
    }, [user_roles])

    // input field variable 
    const [firstname, setFirstName] = useState("");
    const [surname, setSurName] = useState("");
    const [email, setEmail] = useState("");
    const [searchStr, setSearch] = useState("");
    const [selectedUserRoleOption, setUserRoleOption] = useState<ValueType<UserRoleI, false> | null>(null);
    const [selectedUserRoleOptionError, setSelectUserRoleOptionError] = useState<ErrorProps>({isError: null, message: ""});
    
    // set error 
    const [emailError, setEmailError] = useState<ErrorProps>({isError: null, message: ""});
    const [firstnameError, setFirstnameError] = useState<ErrorProps>({isError: null, message: ""});
    const [surnameError, setSurnameError] = useState<ErrorProps>({isError: null, message: ""});


    const handleChangeFirstName = (value: string) => {
        setFirstName(value);
        setFirstnameError({isError: false, message: ""});
    }

    const handleChangeSurName = (value: string) => {
        setSurName(value);
        setSurnameError({isError: false, message: ""});
    }

    const handleChangeEamil = (value: string) => {
        setEmail(value);
        setEmailError({isError: false, message: ""});
    } 

    const handleChangeSearchField = (value: string) => {
        setSearch(value);
    }

    const handleChangeUserRoleOption = (newOption: ValueType<UserRoleI, false> | null) => {
        setUserRoleOption(newOption);
        if (selectedUserRoleOptionError.isError) {
            setSelectUserRoleOptionError({isError: false, message: ""});
        }
    }

    // open create user modal
    const [createUserModal, setCreateUserModal] = useState(false);
    const handleToggleCreateUserModal = () => {
        setCreateUserModal(!createUserModal)
        setFirstName('');
        setSurName('');
        setEmail('');
    }

    const handleCreateUser = () => {
        const emailValid:validationI = checkValidation(email, 'email');

        if (firstname === "") {
            setFirstnameError({isError: true, message: "Please enter this field."});
            return;
        }

        if (surname === "") {
            setSurnameError({isError: true, message: "Please enter this field."});
            return;
        }

        if (email === "") {
            setEmailError({isError: true, message: "Please enter this field."});
            return;
        }

        if (!emailValid.validation) {
            setEmailError({isError: true, message: emailValid.validationMsg});
            return;
        } else {
            setEmailError({isError: false, message: ""});
        }

        dispatch(
            createUser({
                firstname,
                surname,
                email,
                role_id: selectedUserRoleOption? selectedUserRoleOption.role_id: 0
            }, history)
        );

        setCreateUserModal(!createUserModal)
        setFirstName('');
        setSurName('');
        setEmail('');
        setUserRoleOption(null);
    }

    const handleEditUser = (userInfo: EditUserRequestI) => {
        dispatch(editUser(userInfo, history));
        return
    }

    const handleOpenEditUserModal = (user: SettingUserI) => {
        setEditUserModal(!editUserModal);
        dispatch(fetchUser({user_id: user.user_id}, history));
    }

    const  funcMatchUsers = () => {
        let matchedUsers = setting_users;
        let matched = false;

        if (setting_users && searchStr) {
            matchedUsers = setting_users.filter((user) => {
                matched = false;
                if (user.forename.indexOf(searchStr) !== -1) {
                    matched = true;
                } else if (user.surname.indexOf(searchStr) !== -1) {
                    matched = true;
                } else if (user.email.indexOf(searchStr) !== -1) {
                    matched = true;
                }
                return matched;
            });
        }

        return matchedUsers;
    }

    // open edit user modal
    const [editUserModal, setEditUserModal] = useState(false);
    const handleToggleEditUserModal = () => {
        setEditUserModal(!editUserModal)
    }

    const neededFields: string[] = ["ForeName", "SurName", "Email"];
    let matchedUsers = funcMatchUsers();

    return (
        <>
            <Card className="table-y-scrollable">
                <CardHeader className="d-flex justify-content-between align-items-center">
                    Users
                    <div className="d-flex">
                        <InputGroup className="mr-2">
                            <Input onChange={(e) => handleChangeSearchField(e.target.value)}/>
                            <InputGroupAddon addonType="append"><Button color="white"><i className="ti-search"></i></Button></InputGroupAddon>
                        </InputGroup>
                        <Button color="primary" onClick={handleToggleCreateUserModal}>
                            <i className="fa fa-plus"></i> Create User
                        </Button>
                    </div>
                </CardHeader>
                <CardBody>
                    <Table className="no-wrap v-middle" responsive hover size="sm">
                        <thead>
                            <tr className="border-0">
                                {
                                    neededFields.map((field, index) => {
                                        return <th key={"userField"+index} className="border-0">{field}</th>
                                    })
                                }
                                <th className="border-0">
                                    
                                </th>
                            </tr>
                        </thead>
                        <tbody style={{maxHeight: '100px'}}>
                            {
                                matchedUsers && matchedUsers.map((user) => (
                                    <tr key={"user"+user.user_id}>
                                        <td className="pt-2">
                                            {user.forename}
                                        </td>            
                                        <td className="pt-2">
                                            {user.surname}
                                        </td>            
                                        <td className="pt-2">
                                            {user.email}
                                        </td>
                                        <td>
                                            <Button color="white" onClick={() => handleOpenEditUserModal(user)} className="btn-sm rounded"><i className="fa fa-edit"></i></Button>
                                        </td>            
                                    </tr>
                                ))
                            }                            
                        </tbody>
                    </Table>
                </CardBody>
            </Card >
            <Modal isOpen={createUserModal} toggle={handleToggleCreateUserModal} >
                <ModalHeader toggle={handleToggleCreateUserModal}>Create User</ModalHeader>
                    <ModalBody>
                        <Form autoComplete="off">
                            <div className="pl-lg-4">
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-first-name"
                                            >
                                                First name
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                id="input-first-name"
                                                type="text"
                                                value={firstname}
                                                onChange={(e)=>{handleChangeFirstName(e.target.value)}}
                                                invalid={(firstnameError.isError === true)}
                                            />
                                        </FormGroup>
                                        <FormText>{firstnameError.isError? firstnameError.message: ""}</FormText>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-last-name"
                                            >
                                                Last name
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                id="input-last-name"
                                                type="text"
                                                value={surname}
                                                onChange={(e)=>{handleChangeSurName(e.target.value)}}
                                                invalid={(surnameError.isError === true)}
                                            />
                                        </FormGroup>
                                        <FormText>{surnameError.isError? surnameError.message: ""}</FormText>                      
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-email"
                                            >
                                                Email address
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                id="input-email"
                                                placeholder="jesse@example.com"
                                                type="email"
                                                autoComplete="nope"
                                                value={email}
                                                onChange={(e)=>{handleChangeEamil(e.target.value)}}
                                                invalid={(emailError.isError === true)}
                                            />
                                        </FormGroup>
                                        <FormText>{surnameError.isError? surnameError.message: ""}</FormText>                                       
                                    </Col> 
                                    <Col sm="6">
                                        <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="selectProjectOption"
                                                >
                                                    User Role
                                                </label>

                                                <Select
                                                    id="selectProjectOption"
                                                    value={selectedUserRoleOption}
                                                    options={user_roles? user_roles: []}
                                                    getOptionLabel ={(option)=>option.name}
                                                    getOptionValue ={(option)=>(option.role_id).toString()}
                                                    onChange={(e) => handleChangeUserRoleOption(e)}
                                                    placeholder="Select a user role"
                                                    // styles={getStyleForReactSelect(selectedUserRoleOptionError)}
                                                />     
                                        </FormGroup>                                                                  
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleCreateUser}>Ok</Button>{' '}
                    <Button color="secondary" onClick={handleToggleCreateUserModal}>Cancel</Button>
                </ModalFooter>
            </Modal> 
            <EditUserProfileModal 
                isOpen={editUserModal}
                onToggle={handleToggleEditUserModal}
                onSaveProfile={handleEditUser}
            />

        </>        
    );
}

export default SettingsUsers;
