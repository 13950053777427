import { useCallback, useEffect, useState } from "react";
import { useRouteMatch, useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { Card, CardHeader, CardBody, CardTitle, CardSubtitle, CardText,
    Table, Button, Form, FormGroup, Col } from 'reactstrap';

import GoogleMapReact from 'google-map-react';
import apiKeys from "../../constant/apiKeys";
import { BaseUrl, NodeMarkerBGColor, NodeMarkerBDColor, LivestockColor } from "../../constant/constant";

import Select from 'react-select';
import { ValueType } from "react-select/src/types";

import NodeMarker from "../../components/markers/nodeMarker";
import AnimalModal, { Animal } from "../../components/animals/animalModal";
import VideoPlayerModal from "../../components/modal/videoPlayerModal";
import LoadingGif from "../../assets/images/loading.gif";
import googleMaps from "../../assets/images/google-map.jpg";

const functionStore = new Set();

interface AnimalProjectI {
    project_id: string,
    project_name: string,
}
interface ProjectSiteI {
    site_id: string,
    site_name: string,
    polygon: {
        lats: number[],
        longs: number[]
    }
}
interface SiteNodesI {
    site_id: string,
    site_name: string,
    nodes: {
        lat: number,
        lon: number,
        node_id: string,
        node_type_id: number,
        node_type_name: string,
        cattle: string | undefined,
        datetime: string | undefined,
        people: string | undefined,
        node_feed_url: string | null,
    }[],
    animals: AnimalI[],
    weather: {
      city_name: string,
      description: string,
      humidity: number,
      temp: number,
      weather_icon: string,
      wmu: string,
    },
}
type selectOption = {
    label: string,
    value: string,
}

export interface AnimalI {
    animal_breed: string,
    animal_dob: string,
    animal_id: string,
    animal_tag: string,
    animal_type: string,
    animal_type_id: number,
    image_name: string,
    weight: string,
}

const Animals = () => {
    const history = useHistory();
    const projectMatch = useRouteMatch("/livestock/project/:id");
    const siteMatch = useRouteMatch("/livestock/site/:id");
    const eachAnimal = useRouteMatch("livestock/:id");
    let params: {id: string} = useParams();

    const [animalProjects, setAnimalProjects] = useState<AnimalProjectI[] | []>([]);
    const [projectSites, setProjectSites] = useState<ProjectSiteI[] | null>(null);
    const [selectedProjectID, setProject] = useState<string | null>(null);
    const [siteNodes, setSiteNodes] = useState<SiteNodesI | null>(null);
    const [selectedSiteID, setSite] = useState<string | null>(null);
    const [loadingSiteNodesStatus, setLoadingSiteNodesStatus] = useState(0); // 1: pending, 2: success, 3: failure, 0: initial

    const [selectedProjectOption, setProjectOption] = useState<ValueType<selectOption, false> | null>(null);
    const [selectedSiteOption, setSiteOption] = useState<ValueType<selectOption, false> | null>(null);
    const [isOpenAnimalModal, setOpenAnimalModal] = useState(false);
    const [animalForModal, setAnimalForModal] = useState<Animal | null>(null);
    const [isOpenVideoPlayerModal, setOpenVideoPlayerModal] = useState(false);
    const [selectedNodeFeedUrl, setNodeVideoUrl] = useState<string>("");


    // fetch animals project
    const fetchProjects = useCallback(
        async () => {
            let token: string | null = localStorage.getItem('token');
            if (token) {
                fetch(`${BaseUrl}/animals`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token
                    },
                    method: 'GET',
                })
                .then((res) => res.json())
                .then((json) => {
                    if (json.message === "token is invalid!") {
                        history.push('/');
                        localStorage.setItem('token', '');
                    }   
                    if (json.message) {
                    } else {
                        setAnimalProjects(json.projects);
                    }
                })
                .catch((error) => {
                });    
  
            }
        },
    [history]);

    const fetchAnimalProjectSitesCB = useCallback(
        async (projectID: string) => {
            let token: string | null = localStorage.getItem('token');
            if (token) {
                let res = await fetch(`${BaseUrl}/animal/project/${projectID}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token
                    },
                    method: 'GET',
                })
                let json = await res.json();
                if (json.message === "token is invalid!") {
                    history.push("/");
                    localStorage.setItem('token', '');
                }  
                if (json.sites) {
                    setProjectSites(json.sites);
                    if (projectMatch?.isExact) {
                        // select project option
                        animalProjects.filter((project) => {
                            if (project.project_id === params.id) {
                                setProjectOption({ value: project.project_id, label: project.project_name});
                                handleReDrawMapWithProject(params.id);
                                return false;
                            }
                            return true;
                        })
                    }                

                    return {
                        sites: json.sites,
                        success: true,
                    }
                }
            }
        },
        [params?.id, history, projectMatch?.isExact, animalProjects]
    )

    const fetchSiteNodesCB = useCallback(
        async () => {
            let token: string | null = localStorage.getItem('token');
            if (token) {
                setLoadingSiteNodesStatus(1);
                let res = await fetch(`${BaseUrl}/animal/site/${params.id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token
                    },
                    method: 'GET',
                })
                let json = await res.json();
    
                if (json.message) {
                    setLoadingSiteNodesStatus(3);
                }
                if (json.message === "token is invalid!") {
                    history.push("/");
                    localStorage.setItem('token', '');
                }  
                if (json.animals) {
                    setLoadingSiteNodesStatus(2);
                    // in case url is /animal/site/id, load it's project
                    if (siteMatch?.isExact && json.animals[0] && (json.animals[0].site_id === params.id) && animalProjects) {
                        handleReDrawMapWithSite(params.id);
                        animalProjects.filter((project) => {
                            if (project.project_id === json.animals[0].project_id) {
                                setProjectOption({ value: project.project_id, label: project.project_name });
                                fetchAnimalProjectSitesCB(project.project_id); 
                                return true
                            }
                            return false
                        })
                        setSiteOption({ value: json.animals[0].site_id, label: json.animals[0].site_name });
                    }

                    setSiteNodes(json.animals[0]);
                    return {
                        nodes: json.animals,
                        success: true,
                    }
                }
            }
        },
        [params?.id, history, siteMatch?.isExact, animalProjects, fetchAnimalProjectSitesCB]        
    ) 
    functionStore.add(fetchAnimalProjectSitesCB);
    functionStore.add(fetchSiteNodesCB);
    functionStore.add(fetchProjects);
    const fetchAnimal = async (animalID: string) => {
        let token: string | null = localStorage.getItem('token');
        if (token) {
            let res = await fetch(`${BaseUrl}/animal/${animalID}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token
                },
                method: 'GET',
            })
            let json = await res.json();
            if (json.message === "token is invalid!") {
                history.push("/");
                localStorage.setItem('token', '');
            }  
            if (json.animal) {
                setAnimalForModal(json.animal[0]);
            }
        }
    }    

    // fetch projects and nodes and animals of site according to history
    useEffect(() => {
        // fetch projects 
        fetchProjects();
    }, [fetchProjects]);
    useEffect(() => {
        // in case url is /arable/project/id, fetch select project option and load it's sites
        if (projectMatch?.isExact) {
            fetchAnimalProjectSitesCB(params.id);
        }        
    }, [projectMatch?.isExact, params.id, fetchAnimalProjectSitesCB]);
    useEffect(() => {
        // in case url is /arable/site/id, fetch select site option and load it's animals and nodes 
        if (siteMatch?.isExact) {
            fetchSiteNodesCB();
        }       
    }, [siteMatch?.isExact, params.id, fetchSiteNodesCB])

    // when change project option, redraw map with project
    const handleReDrawMapWithProject = (projectID: string) => {
        setProject(null);
        let setProjectTimeOut = setTimeout(
            function(){ 
                clearTimeout(setProjectTimeOut);
                setProject(projectID); 
            }, 
            1
        );
    }

    // when change site option, redraw map with site
    const handleReDrawMapWithSite = (siteID: string) => {
        setSite(null);
        setProject(null);
        let setSiteTimeOut = setTimeout(
            function(){ 
                clearTimeout(setSiteTimeOut);
                setSite(siteID); 
            }, 
            1
        );
    }
    const createPolygonOnMap = (polygon: any, maps: any, map: any) => {
        const sitePolygonCoords = polygon.lats.map((lat: string, index: number) => {
            return {lat: parseFloat(lat), lng: parseFloat(polygon.longs[index])}
        });
        const arableCropSitePolygon = new maps.Polygon({
            paths: sitePolygonCoords,
            strokeColor: "#0000FF",
            strokeOpacity: 0.8,
            strokeWeight: 3,
            fillColor: "#0000FF",
            fillOpacity: 0.35,                                           
        })
        arableCropSitePolygon.setMap(map);
    }
    // when change projectID, Google Map act api function, 
    const handleApiLoaded = async (map: any, maps: any) => {
        map.mapTypeId = "terrain";
        if (selectedProjectID && projectSites) {
            projectSites.forEach((site) => {
                createPolygonOnMap(site.polygon, maps, map); 
            })
        }

        if (selectedSiteID && projectSites) {
            projectSites.filter((site) => {
                if (selectedSiteID === site.site_id) {
                    createPolygonOnMap(site.polygon, maps, map);
                    return true; 
                }
                return false;
            })
        }
    }    

    const handleChangeProjectOption = (newOption: ValueType<selectOption, false> | null) => {
        setProjectOption(newOption);
        if (newOption) {
            history.push(`/livestock/project/${newOption.value}`);
        }
    }

    const handleChangeSiteOption = (newOption: ValueType<selectOption, false> | null) => {
        setSiteOption(newOption);
        if (newOption) {
            history.push(`/livestock/site/${newOption.value}`);
        }
    }    

    const handleToggleAnimalDetailModal = (isOpenAddNodeModal: boolean, selectedAnimal: AnimalI | null) => {
        setOpenAnimalModal(!isOpenAnimalModal);
        if (selectedAnimal) {
            fetchAnimal(selectedAnimal.animal_id);
        }
    }

    let projectOptions:selectOption[] = [];
    if (animalProjects) {
        projectOptions = animalProjects.map((project: AnimalProjectI) => {
            return {
                value: project.project_id,
                label: project.project_name
            }
        });
    }
    let siteOptions:selectOption[] = [];
    if (projectSites) {
        siteOptions = projectSites.map((site: ProjectSiteI) => {
            return {
                value: site.site_id,
                label: site.site_name
            }
        });
    }
    const handleChangeVisibleVideoPlayerModal = () => {
        setOpenVideoPlayerModal(!isOpenVideoPlayerModal);
    }
    const handleShowNodeVideo = async (node_feed_url: string | null, node_id: string) => {
        if (node_feed_url !== null) {
            setNodeVideoUrl(`${node_feed_url}`);
        }
        setOpenVideoPlayerModal(true);
    }

    return (
        <>
          <p className="h1">Livestock</p>
          <hr />
            {/*--------------------------------------------------------------------------------*/
            /* Google map                                                  */
            /*--------------------------------------------------------------------------------*/}
            <Card>
                {/*<CardHeader className="d-flex justify-content-between align-items-center">
                    Google Map
                </CardHeader>*/}
                <CardBody>
                    <Form>
                        <FormGroup row>
                            <Col sm={6}>
                                <Select
                                    value={selectedProjectOption}
                                    onChange={(e) => handleChangeProjectOption(e)}
                                    options={projectOptions}
                                    placeholder="Select a Project"
                                />                               
                            </Col>      
                            <Col sm={6}>
                                <Select
                                    value={selectedSiteOption}
                                    onChange={(e) => handleChangeSiteOption(e)}
                                    options={siteOptions}
                                    placeholder="Select a Site"
                                />                               
                            </Col>      
                        </FormGroup> 
                    </Form>                                                         
                    <div style={{height: '50vh'}} className="tooltipBoundary">
                        { ((selectedProjectID !== null && projectSites !== null) || (selectedSiteID !== null && projectSites !== null)) ?
                            <>
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: apiKeys.googleMapApiKey }}
                                    defaultZoom={6}
                                    defaultCenter={apiKeys.NigeriaLatLng} // Nigeria
                                    yesIWantToUseGoogleMapApiInternals
                                    onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}  
                                >
                                    {/* { projectSites && 
                                        projectSites.map((site:ProjectSiteI, index: number) => {
                                            return (
                                                <SiteMarker
                                                    lat={site.polygon.lats[0]}
                                                    lng={site.polygon.longs[0]}
                                                    text={site.site_name}
                                                    backgroundColor={SiteMarkerBGColor}
                                                    borderColor={LivestockColor}
                                                    onClick={() => { fetchSiteNodes(site.site_id) }}
                                                    key={"listStockSiteMaker"+index}
                                                />        
                                            )
                                        })
                                    }      */}
                                    { siteNodes && 
                                        siteNodes.nodes.map((node, index) => {
                                            return (
                                                <NodeMarker
                                                    key={"liveStockNodeMarker"+index}
                                                    lat={node.lat}
                                                    lng={node.lon}
                                                    text={node.node_type_name}
                                                    borderColor={NodeMarkerBDColor}
                                                    backgroundColor={NodeMarkerBGColor} 
                                                />  
                                            )
                                        })
                                    }                                 
                                    
                                                            
                                </GoogleMapReact>
                                { siteNodes &&
                                    <Card inverse className="weather-card">
                                    <CardBody>
                                        <CardTitle tag="h4">{siteNodes.weather.city_name}</CardTitle>
                                        <CardSubtitle tag="h6" className="mb-2 text-muted text-capitalize">{siteNodes.weather.description}</CardSubtitle>
                                        <CardText className="small">Humidity {siteNodes.weather.humidity}%</CardText>
                                        <img src={siteNodes.weather.weather_icon} alt="Weather" />
                                        <b>{siteNodes.weather.temp}{siteNodes.weather.wmu}</b>
                                    </CardBody>
                                    </Card>
                                }
                            </>  
                            :
                            <img src={googleMaps} alt="Google Map" width="100%">
                            </img>
                        }                           
                    </div>
                </CardBody>
            </Card >  
            {/*--------------------------------------------------------------------------------*/
            /* Table for Nodes                                                  */
            /*--------------------------------------------------------------------------------*/}
            <Card className="table-y-scrollable">
                <CardHeader className="d-flex justify-content-between align-items-center">
                    Nodes
                </CardHeader>
                <CardBody>      
                {                   
                    loadingSiteNodesStatus === 1 ?
                        <div className="d-flex justify-content-center">
                            <img src={LoadingGif} alt="loading" width="20px" /> 
                        </div> 
                    :          
                        <Table className="no-wrap v-middle" responsive hover size="sm">
                            <thead>
                                <tr className="border-0">
                                    <th className="border-0">
                                        Date
                                    </th>
                                    <th className="border-0">
                                        Node ID
                                    </th>
                                    <th className="border-0">
                                        People
                                    </th>
                                    <th className="border-0">
                                        Cattle
                                    </th>
                                    <th className="border-0">
                                        Type
                                    </th>
                                    <th className="border-0">
                                        Latitude
                                    </th>
                                    <th className="border-0">
                                        Longitude
                                    </th>
                                    <th className="border-0">
                                        Type
                                    </th>
                                    <th className="border-0">
                                    </th>
                                </tr>
                            </thead>
                            <tbody style={{maxHeight: '100px'}}>
                                {
                                    (siteNodes && siteNodes.nodes.map((node, itemIndex: number) => {
                                        return (<tr key={"animalProject"+itemIndex}>
                                            <td>
                                                {node.datetime}
                                            </td>  
                                            <td>
                                                {node.node_id}
                                            </td>
                                            <td>
                                                {node.people}
                                            </td>
                                            <td>
                                                {node.cattle}
                                            </td>    
                                            <td>
                                                {node.node_type_name}
                                            </td>    
                                            <td>
                                                {node.lat}
                                            </td>    
                                            <td>
                                                {node.lon}
                                            </td>    
                                            <td>
                                                {node.node_type_name}
                                            </td>    
                                            <td>
                                                <Button onClick={() => handleShowNodeVideo(node.node_feed_url, node.node_id)} disabled={node.node_feed_url === null}>
                                                    <i className="fas fa-video"></i>
                                                </Button>                                                        
                                            </td>
                                        </tr>)
                                    }))                             
                                }                            
                            </tbody>
                        </Table>
                    }

                </CardBody>
            </Card >      

            {/*--------------------------------------------------------------------------------*/
            /* Table for animals                                                  */
            /*--------------------------------------------------------------------------------*/}
            <Card className="table-y-scrollable">
                <CardHeader className="d-flex justify-content-between align-items-center">
                    Animals
                </CardHeader>
                <CardBody>     
                {                   
                    loadingSiteNodesStatus === 1 ?
                        <div className="d-flex justify-content-center">
                            <img src={LoadingGif} alt="loading" width="20px" /> 
                        </div> 
                    :          
                        <Table className="no-wrap v-middle" responsive hover size="sm">
                            <thead>
                                <tr className="border-0">
                                    <th className="border-0">
                                        Dob
                                    </th>
                                    <th className="border-0">
                                        Type
                                    </th>
                                    <th className="border-0">
                                        Tag
                                    </th>
                                    <th className="border-0">
                                        Breed
                                    </th>
                                    <th className="border-0">
                                        Weight
                                    </th>
                                    <th className="border-0">
                                        
                                    </th>
                                    <th className="border-0">

                                    </th>
                                </tr>
                            </thead>
                            <tbody style={{maxHeight: '100px'}}>
                                {
                                    siteNodes && siteNodes.animals.map((animal, itemIndex: number) => {
                                        return (<tr key={"animal"+itemIndex}>
                                            <td className="pt-2">
                                                {animal.animal_dob}
                                            </td>
                                            <td className="pt-2">
                                                {animal.animal_type}
                                            </td>
                                            <td className="pt-2">
                                                {animal.animal_tag}
                                            </td>
                                            <td className="pt-2">
                                                {animal.animal_breed}
                                            </td>
                                            <td className="pt-2">
                                                {animal.weight}
                                            </td>    
                                            <td>
                                                <img src={animal.image_name} alt="animal" width="20px" height="20px"/>
                                            </td>  
                                            <td className="pt-2">
                                                <Button onClick={() => handleToggleAnimalDetailModal(!isOpenAnimalModal, animal)}>
                                                    <i className="fa fa-search"></i> View Detail
                                                </Button>                
                                            </td>    
                                        </tr>)
                                    })                             
                                }                            
                            </tbody>
                        </Table>
                    }

                </CardBody>
            </Card > 
            {
                animalForModal && 
                <AnimalModal isOpen={isOpenAnimalModal} onToggle={() => handleToggleAnimalDetailModal(!isOpenAnimalModal, null)} animal={animalForModal}/>                            
            }
            <VideoPlayerModal isOpen={isOpenVideoPlayerModal} onToggle={handleChangeVisibleVideoPlayerModal} url={selectedNodeFeedUrl} />
        </>
    )
}    

export default Animals;
